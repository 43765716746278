import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { findAllForms } from '../../models/forms/forms.repository';
import DataTable from '../../components/DataTable';
import { AnswerColumns, emptyForm, Form } from '../../models/forms/forms.module';
import '../../styles/_nestedNav.scss';
import { getDataFormFields, getDataForms } from '../../models/data/data.repository';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../layout/SubHeader/SubHeader';
import Icon from '../../components/icon/Icon';
import Input from '../../components/bootstrap/forms/Input';
import Dropdown, { DropdownMenu, DropdownToggle } from '../../components/bootstrap/Dropdown';
import Button from '../../components/bootstrap/Button';
import Popovers from '../../components/bootstrap/Popovers';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Checks, { ChecksGroup } from '../../components/bootstrap/forms/Checks';
import { Columns } from '../../components/Table';
import Modal, { ModalBody, ModalHeader } from '../../components/bootstrap/Modal';
import SingleUserAnswer from './SingleUserAnswer';
import { CancelRounded } from '@mui/icons-material';
import { useParams } from 'react-router-dom';

interface BaseDataTableProps {
	name: string;
}

type ColumnVisibility = {
	[key: string]: boolean;
};

const AnswersForm: FC<BaseDataTableProps> = ({ name }) => {
	const params = useParams();
	const { t } = useTranslation(['translation']);
	const [search, setSearch] = useState('');
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [columns, setColumns] = useState<ColumnVisibility>({
		ID: true,
		UUID: true,
		Name: true,
		Description: true,
	});
	const [dynamicColumns, setDynamicColumns] = useState<Columns[]>([]);
	const [allForms, setAllForms] = useState<Array<Form> | null>(null);
	const [filteredForms, setFilteredForms] = useState<Array<Form>>([]);
	const [fields, setFields] = useState([]);
	const [selectedUser, setSelectedUser] = useState<string>();
	const [formName, setFormName] = useState('');

	useEffect(() => {
		const fetchForms = async () => {
			try {
				const res = await getDataForms();
				if (res) {
					const formsWithAdditionalData = res.map((form: Form) => {
						form.form_link = `/answers/${form.uuid}`;
						if (form.data !== undefined) {
							const counterList = form.data.map(
								(itemData) => itemData.user_identifier,
							);
							form.totalCounter = Array.from(new Set(counterList)).length;
						}
						return form;
					});
					setAllForms(formsWithAdditionalData);
					setFilteredForms(formsWithAdditionalData);
				}
			} catch (error) {
				console.error(error);
			}
		};

		fetchForms();
	}, []);

	useEffect(() => {
		const fetchFormFields = async () => {
			try {
				if (params.form_uuid && params.user_identifier) {
					const res = await getDataFormFields(params.form_uuid);
					if (res) {
						setFields(res);
						setSelectedUser(params.user_identifier);
						setFormName(allForms?.find((o) => o.uuid === params.form_uuid)?.name ?? '');
						setIsModalOpen(true);
					}
				}
			} catch (error) {
				console.error(error);
			}
		};

		fetchFormFields();
	}, [params]);

	useEffect(() => {
		const filterForms = () => {
			if (allForms && search.length) {
				const lowerCaseSearch = search.toLowerCase();
				const filtered = allForms.filter((form) => {
					const uuidMatch = form.uuid
						? form.uuid.toLowerCase().includes(lowerCaseSearch)
						: false;
					const nameMatch = form.name
						? form.name.toLowerCase().includes(lowerCaseSearch)
						: false;
					const descriptionMatch = form.description
						? form.description.toLowerCase().includes(lowerCaseSearch)
						: false;
					const idMatch = form.id ? form.id.toString().includes(lowerCaseSearch) : false;

					return uuidMatch || nameMatch || descriptionMatch || idMatch;
				});
				setFilteredForms(filtered);
			} else {
				setFilteredForms(allForms ?? []);
			}
		};

		filterForms();
	}, [search, allForms]);

	useEffect(() => {
		const updatedColumns = AnswerColumns.map((column) => ({
			...column,
			displayed: columns[column.name] ?? false,
		}));
		setDynamicColumns(updatedColumns);
	}, [columns]);

	const handleColumnToggle = (columnName: string) => {
		setColumns((prevColumns) => ({
			...prevColumns,
			[columnName]: !prevColumns[columnName],
		}));
	};

	const showActions = columns.Actions;

	return (
		<PageWrapper>
			<SubHeader>
				<SubHeaderLeft>
					<label
						className='border-0 bg-transparent cursor-pointer me-0'
						htmlFor='searchInput'>
						<Icon icon='Search' size='2x' color='primary' />
					</label>
					<Input
						id='searchInput'
						type='search'
						className='border-0 shadow-none bg-transparent'
						placeholder={t('answersPage.search_answers_text')}
						onChange={(event: ChangeEvent<HTMLInputElement>) =>
							setSearch(event.target.value)
						}
						value={search}
					/>
				</SubHeaderLeft>
				<SubHeaderRight>
					<Dropdown>
						<DropdownToggle hasIcon={false}>
							<Button
								icon='FilterAlt'
								color='dark'
								isLight
								className='btn-only-icon position-relative'>
								{Object.values(columns).includes(false) && (
									<Popovers desc='Filtering applied' trigger='hover'>
										<span className='position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-2'>
											<span className='visually-hidden'>
												{t('answersPage.filtered_text')}
											</span>
										</span>
									</Popovers>
								)}
							</Button>
						</DropdownToggle>
						<DropdownMenu isAlignmentEnd size='lg' isOpen={true}>
							<div className='container py-2'>
								<div className='row g-3'>
									<FormGroup label='Columns' className='col-12'>
										<ChecksGroup>
											{Object.keys(columns).map((columnName) => (
												<Checks
													key={columnName}
													id={columnName}
													label={columnName}
													name={columnName}
													value={columnName}
													onChange={() => handleColumnToggle(columnName)}
													checked={columns[columnName]}
												/>
											))}
										</ChecksGroup>
									</FormGroup>
								</div>
							</div>
						</DropdownMenu>
					</Dropdown>
				</SubHeaderRight>
			</SubHeader>
			<Page container='fluid'>
				<DataTable<Form>
					showActions={showActions}
					columns={dynamicColumns}
					emptyValue={emptyForm}
					getAll={findAllForms}
					post={undefined}
					isFluid={true}
					name={name}
					openWindows={undefined}
					editItem={undefined}
					update={undefined}
					delete={undefined}
					data={filteredForms}
					setData={setAllForms}
					linkIcon='Timeline'
				/>
			</Page>

			<Modal
				size={'xl'}
				fullScreen={true}
				isOpen={isModalOpen}
				setIsOpen={(status: boolean) => {
					setIsModalOpen(false);
					setSelectedUser(undefined);
				}}
				isStaticBackdrop={true}
				isCentered={true}>
				<ModalHeader>
					<Button
						style={{
							alignItems: 'end',
							display: 'flex',
							justifyContent: 'flex-end',
							flexDirection: 'row',
							alignSelf: 'flex-end',
						}}
						onClick={() => {
							setIsModalOpen(false);
							setSelectedUser(undefined);
						}}>
						<CancelRounded />
					</Button>
				</ModalHeader>
				<ModalBody>
					<SingleUserAnswer
						form_uuid={params.form_uuid}
						user_identifier={selectedUser}
						formName={formName}
						fields={fields}
					/>
				</ModalBody>
			</Modal>
		</PageWrapper>
	);
};

export default AnswersForm;
