import React, { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button, { IButtonProps } from '../../../components/bootstrap/Button';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import Popovers from '../../../components/bootstrap/Popovers';
import Spinner from '../../../components/bootstrap/Spinner';
import Icon from '../../../components/icon/Icon';
import useDarkMode from '../../../hooks/useDarkMode';
import useDeviceScreen from '../../../hooks/useDeviceScreen';
import LANG, { getLangWithKey, ILang } from '../../../lang';
import Header, { HeaderLeft, HeaderRight } from '../../../layout/Header/Header';
import toast from 'react-hot-toast';
import { Breadcrumbs, Chip, emphasize, styled } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

interface BreadcrumbItem {
	label: string;
	icon: string;
	link: string;
}

interface DashboardHeaderProps {
	breadcrumbs?: BreadcrumbItem[];
}

const DashboardHeader: React.FC<DashboardHeaderProps> = ({ breadcrumbs }) => {
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();

	const { width } = useDeviceScreen();
	const styledBtn: IButtonProps = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};

	const { i18n, t } = useTranslation(['translation', 'menu']);

	const changeLanguage = (lng: ILang['key']['lng']) => {
		i18n.changeLanguage(lng).then(() =>
			toast.success(
				<span className='d-flex align-items-center'>
					<Icon icon={getLangWithKey(lng)?.icon} size='lg' className='me-1' />
					<span>{`Language changed to ${getLangWithKey(lng)?.text}`}</span>
				</span>,
			),
		);
	};

	/**
	 * Language attribute
	 */
	useLayoutEffect(() => {
		document.documentElement.setAttribute('lang', i18n.language);
	});

	const StyledBreadcrumb = styled(Chip)(({ theme }) => {
		const backgroundColor = darkModeStatus ? '#ffffff0a' : '#0000000a';
		return {
			backgroundColor,
			height: theme.spacing(3),
			color: darkModeStatus ? 'white' : 'black',
			fontWeight: theme.typography.fontWeightRegular,
			'&:hover, &:focus': {
				backgroundColor: emphasize(backgroundColor, 0.06),
			},
			'&:active': {
				boxShadow: theme.shadows[1],
				backgroundColor: emphasize(backgroundColor, 0.12),
			},
		};
	}) as typeof Chip;

	return (
		<Header>
			<HeaderLeft>
				<Breadcrumbs
					aria-label='breadcrumb'
					separator={<NavigateNextIcon fontSize='small' />}
					sx={{
						color: darkModeStatus ? '#f5f5f5' : '#323232',
						fontSize: 10,
					}}>
					<StyledBreadcrumb
						component='a'
						href='/'
						label={t('Dashboard')}
						icon={
							<Icon
								style={{ color: darkModeStatus ? '#f5f5f5' : '#323232' }}
								icon='Dashboard'
							/>
						}
					/>
					{breadcrumbs &&
						breadcrumbs.map((breadcrumb, index) => (
							<StyledBreadcrumb
								key={index}
								component='a'
								href={breadcrumb.link}
								label={breadcrumb.label}
								icon={
									<Icon
										style={{ color: darkModeStatus ? '#f5f5f5' : '#323232' }}
										icon={breadcrumb.icon}
									/>
								}
							/>
						))}
				</Breadcrumbs>
			</HeaderLeft>

			<HeaderRight>
				<div className='row g-3 align-items-center'>
					{/* Dark Mode */}
					<div className='col-auto'>
						<Popovers trigger='hover' desc='Dark / Light mode'>
							<Button
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...styledBtn}
								icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
								onClick={() => setDarkModeStatus(!darkModeStatus)}
								aria-label='Toggle fullscreen'
								data-tour='dark-mode'
							/>
						</Popovers>
					</div>
					{/* Lang Selector */}
					<div className='col-auto'>
						<Dropdown>
							<DropdownToggle hasIcon={false}>
								{typeof getLangWithKey(i18n.language as ILang['key']['lng'])
									?.icon === 'undefined' ? (
									<Button
										// eslint-disable-next-line react/jsx-props-no-spreading
										{...styledBtn}
										className='btn-only-icon'
										aria-label='Change language'
										data-tour='lang-selector'>
										<Spinner isSmall inButton='onlyIcon' isGrow />
									</Button>
								) : (
									<Button
										// eslint-disable-next-line react/jsx-props-no-spreading
										{...styledBtn}
										icon={
											getLangWithKey(i18n.language as ILang['key']['lng'])
												?.icon
										}
										aria-label='Change language'
										data-tour='lang-selector'
									/>
								)}
							</DropdownToggle>
							<DropdownMenu isAlignmentEnd data-tour='lang-selector-menu'>
								{Object.keys(LANG).map((i) => (
									<DropdownItem key={LANG[i].lng}>
										<Button
											icon={LANG[i].icon}
											onClick={() => changeLanguage(LANG[i].lng)}>
											{LANG[i].text}
										</Button>
									</DropdownItem>
								))}
							</DropdownMenu>
						</Dropdown>
					</div>
				</div>
			</HeaderRight>
		</Header>
	);
};

export default DashboardHeader;
