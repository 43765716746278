import React, { FC, useState } from 'react';
import { ApexOptions } from 'apexcharts';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Chart from '../../../components/extras/Chart';

interface IBarChartProps {
	series: any[];
	title: string;
	xAxis: string[]
}

const BarChart: FC<IBarChartProps> = (props: IBarChartProps) => {
	const {series, title,xAxis} = props;
	const chartOptions: ApexOptions = {
		chart: {
			type: 'bar',
			height: 307,
		},
		stroke: {
			width: 0,
		},
		series: series,
		xaxis: {
			position: 'bottom',
			categories: xAxis
		},
		dataLabels: {
			enabled: false,
		},
		legend: {
			show: true,
			position: 'bottom',
		},
		yaxis: {
			labels: {
				formatter: function (value) {
					return Math.round(value).toString();
				}
			}
		},
		tooltip: {
			cssClass: "text-dark"
		}
	};

	return (
		<Card stretch>

			{title && (
				<CardHeader>

					<CardLabel>
						<CardTitle>{title}</CardTitle>
					</CardLabel>

			</CardHeader>
			)}
			<CardBody>
				<Chart
					series={series}
					options={chartOptions}
					type={"bar"}
					height={chartOptions.chart?.height}
				/>
			</CardBody>
		</Card>
	);
};

export default BarChart;
