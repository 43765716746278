import React, {SetStateAction, useEffect, useState} from 'react';
import {IFields} from "../../../Interfaces/IGeneral";
import {IDataCreate} from "../../../../../models/data/data.module";
import {FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";

interface IMultiselectFormProps {
    field: IFields,
    fieldIndex: number,
    data?: Array<IDataCreate>,
    setData?: React.Dispatch<SetStateAction<Array<IDataCreate[]> | null>>;
    pageNumber: number
}

const EMultiselectForm = (props: IMultiselectFormProps) => {

    const [text, setText] = useState<string[]>([])

    useEffect(() => {
        if (props.data && props.data[props.fieldIndex] && props.data[props.fieldIndex].value !== undefined) {
            setText(JSON.parse(props.data[props.fieldIndex].value))
        }
    }, [])

    const textChangeHandler = (e: any) => {
        if (props.data && props.setData) {
            let changedValues = [...props.data]
            changedValues[props.fieldIndex].value = JSON.stringify(e.target.value)
            setText(e.target.value)
            props.setData((prevState: any) => {
                let newState = [...prevState];
                newState[props.pageNumber] = changedValues;
                return newState;
            });
        }
    }

    const InputLabelPropsStyles: React.CSSProperties = {
        color: getComputedStyle(document.documentElement).getPropertyValue('--formInputColor'),
        fontSize: '18px',
        padding: '12px 0 8px 0',
    };

    const formInputStyles: React.CSSProperties = {
        color: getComputedStyle(document.documentElement).getPropertyValue('--formInputColor'),
        textAlign: 'left',
        padding: '12px 0 8px 0',
        fontSize: '18px',
    };


    return props.field.name.length > 25 ? (
        <FormControl>
            <div className="MPage__drop-target__field-label">
                {props.field.name}{props.field.required ? "*" : ""}
            </div>
            <Select
                label={props.field.name + (props.field.required ? ' *' : '')}
                className={'SelectInput'}
                multiple
                value={text}
                onChange={(e) => {
                    textChangeHandler(e)
                }}
                sx={{ input: formInputStyles }}
                variant={'standard'}
                required={props.field.required}>
                {props.field.valuesToBeSelected &&
                    JSON.parse(props.field.valuesToBeSelected).map(
                        (value: string, valueIndex: number) => {
                            return (
                                <MenuItem
                                    key={valueIndex}
                                    value={value || ''}>
                                    {value}
                                </MenuItem>
                            );
                        },
                    )}
            </Select>
        </FormControl>
    ) : (
        <FormControl>
            <InputLabel
                className='MPage__form__selectLabel'
                style={{ ...InputLabelPropsStyles }}>
                {props.field.name + (props.field.required ? ' *' : '')}
            </InputLabel>
            <Select
                label={props.field.name + (props.field.required ? ' *' : '')}
                className={'SelectInput'}
                multiple
                value={text}
                onChange={(e) => {
                    textChangeHandler(e)
                }}
                sx={{ input: formInputStyles }}
                variant={'standard'}
                required={props.field.required}>
                {props.field.valuesToBeSelected &&
                    JSON.parse(props.field.valuesToBeSelected).map(
                        (value: string, valueIndex: number) => {
                            return (
                                <MenuItem
                                    key={valueIndex}
                                    value={value || ''}>
                                    {value}
                                </MenuItem>
                            );
                        },
                    )}
            </Select>
        </FormControl>
    );
};

export default EMultiselectForm;