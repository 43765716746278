import { DATA, FORMS, TEMPLATES } from '../../API/API-routes';
import { requests } from '../../API/API-service';
import { DeleteAsTemplateDto, MarkAsTemplateDto } from '../../frontend/src/Interfaces/IForms';
import { CreateFormDto, UpdateFieldPosition } from './forms.module';
import { API_BASE_PATH } from '../../frontend/src/API/API-routes';

export const createForm = async (body: CreateFormDto) => {
	return requests
		.post(FORMS, body)
		.then((data) => data)
		.catch((error) => error);
};

export const findAllForms = async () => {
	return requests
		.get(FORMS)
		.then((data) => data)
		.catch((error) => error);
};

export const getFormTemplatesData = async () => {
	return requests
		.get(TEMPLATES)
		.then((data) => data)
		.catch((err) => err);
};

export const uploadFormLogoImage = (company_id: string, form_uid: string, formData: FormData) => {
	return requests
		.post(FORMS + `/image/${company_id}/forms/${form_uid}/logo`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		.then((data) => data)
		.catch((error) => {
			return Promise.reject(error);
		});
};


export const deleteFormLogoImage = (company_id: string, form_uid: string, imageName: string) => {
	return requests
		.delete(FORMS + `/image/${company_id}/forms/${form_uid}/logo?imageName=${imageName}`)
		.then((data) => data)
		.catch((error) => {
			return Promise.reject(error);
		});
};

export const saveAsTemplate = async (body: Partial<MarkAsTemplateDto>) => {
	return requests
		.post(FORMS + `/mark-as-template`, body)
		.then((data) => data)
		.catch((error) => {
			return Promise.reject(error);
		});
};

export const deleteAsTemplate = async (body: Partial<DeleteAsTemplateDto>) => {
	return requests
		.post(FORMS + `/remove-from-template`, body)
		.then((data) => data)
		.catch((error) => {
			return Promise.reject(error);
		});
};

export const uploadFormBGImage = (company_id: string, form_uuid: string, image: any) => {
	return requests
		.post(FORMS + `/image/${company_id}/forms/${form_uuid}`, image, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		.then((data) => data)
		.catch((error) => error);
};

export const updateFormBGImage = (form_uuid: string, image_id: number) => {
	return requests
		.post(FORMS + `/updateImage/form/${form_uuid}/${image_id}`)
		.then((data) => data)
		.catch((error) => error);
};

export const updateLogo = (form_uuid: string, image_id: number) => {
	return requests
		.post(FORMS + `/updateLogo/form/${form_uuid}/${image_id}`)
		.then((data) => data)
		.catch((error) => error);
};

export const uploadEmailTemplateImage = async (company_id: string, form_uuid: string, formData: any) => {
	try {
		const data = await requests.post(`${FORMS}/image/${company_id}/forms/${form_uuid}/email-template`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
		return data;
	} catch (error) {
		throw error;
	}
};

export const findOneForm = async (id: string) => {
	return requests
		.get(FORMS + '/findOne/' + id)
		.then((data) => data)
		.catch((error) => error);
};

export const updateForm = async (id: string, body: Partial<CreateFormDto>) => {
	return requests
		.post(FORMS + '/' + id, body)
		.then((data) => data)
		.catch((error) => error);
};

export const deleteForm = async (id: string) => {
	try {
			const data = await requests.delete(FORMS + '/' + id);
			return data;
	} catch (error) {
			throw error;
	}
};

export const updateFieldPosition = async (body: Partial<UpdateFieldPosition>) => {
	return requests.post(FORMS + '/update-field-positions', body)
	.then((data) => data)
	.catch((error) => {
		return Promise.reject(error);
	});
};

export const sendEmail = async (form: any, data: any) => {
	return requests
		.post(FORMS + '/mail', { form, data })
		.then((res) => res)
		.catch((error) => error);
};

export const uploadFormDataFile = (form_uuid: string, formData: any) => {
	return requests
		.post(DATA + `/file/${form_uuid}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		.then((data) => data)
		.catch((error) => error);
};

export const removeBackground = (form_uuid: string) => {
	return requests
		.delete(FORMS + '/image/' + form_uuid)
		.then((data) => data)
		.catch((err) => err)
}

export const getImagePath = (formtype: 'Page' | 'Form', company_id: string, uuid: string, img: string) : string => {
	const formPath = formtype === 'Form' ? 'forms' : 'pages'
	return `${API_BASE_PATH}/uploads/${company_id}/${formPath}/${uuid}/images/${img}`
}

export const duplicateForm = (form_uuid: string) => {
	return requests
		.post(FORMS + '/duplicate/' + form_uuid)
		.then((data) => data)
		.catch((err) => err)
}

export const getImageGallery = async () => {
	return requests
		.get(FORMS + '/image_gallery')
		.then((data) => data)
		.catch((error) => error);
};

export const getFlow = async (formUUID: string) => {
	return requests
		.get(FORMS + '/flow/' + formUUID )
		.then((data) => data)
		.catch((error) => error);
}

export const saveFlow = async (formUUID: string, body: {nodes: any[], edges: any[]}) => {
	return requests
		.post(FORMS + '/saveFlow/' + formUUID, body )
		.then((data) => data)
		.catch((error) => error);
}
