// DragDropWrapper.tsx
import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { IFields } from '../../../Interfaces/IGeneral';
import { emptyField } from '../../../../../models/pages/pages.module';
import { IPages } from '../../../Interfaces/IPages';

interface DragDropWrapperProps {
	item: IFields;
	moveItem: (dragIndex: number, hoverIndex: number, updateBackend?: boolean) => void;
	type: string[];
	children: React.ReactElement;
	pageNumber: number;
	onDrop?: (item: IFields, pageIndex: number) => void;
	fields: IPages;
	hoveredDropTarget: number | null;
	className: string;
	onDragOver: () => void;
	onDragLeave: () => void;
	isCreator: boolean;
}

const DragDropWrapper: React.FC<DragDropWrapperProps> = ({
	item,
	isCreator,
	moveItem,
	type,
	pageNumber,
	onDrop,
	onDragLeave,
	onDragOver,
	fields,
	hoveredDropTarget,
	className,
	children,
}) => {
	const ref = useRef<HTMLDivElement>(null);

	const isEmptyItem = (selectedItem: IFields) => !selectedItem.name && !selectedItem.dataType;

	const [{ isOver }, drop] = useDrop({
		accept: isCreator ? type : [],
		drop: (itemPassed: IFields, monitor) => {
			if (monitor.getItemType() === 'DRAGGABLE_ITEM') {
				if (onDrop) {
					onDrop(itemPassed, pageNumber);
				}
			} else if (monitor.getItemType() === 'field') {
				const dragOrder = itemPassed.order;
				if (dragOrder !== undefined) {
					moveItem(dragOrder, dragOrder, true);
				}
			}
		},
		hover: (itemPassed: IFields, monitor) => {
			if (monitor.getItemType() === 'field') {
				const dragOrder = itemPassed.order;
				const hoverOrder = hoveredDropTarget;

				if (!hoverOrder || dragOrder === hoverOrder) {
					return;
				}

				if (fields.page_fields?.find((o) => o.order === hoverOrder) === emptyField) {
					return;
				}

				if (
					dragOrder !== undefined &&
					hoverOrder !== undefined &&
					dragOrder !== hoverOrder
				) {
					moveItem(dragOrder, hoverOrder);
				}
			}
		},
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
		}),
		canDrop: (itemPassed, monitor) => {
			if (monitor.getItemType() === 'DRAGGABLE_ITEM') {
				let isDropTargetEmpty = false;
				if (fields && fields.page_fields) {
					if (fields.page_fields[fields.page_fields.length - 1] === emptyField) {
						isDropTargetEmpty = true;
					}
				}
				return isDropTargetEmpty;
			} else {
				return true;
			}
		},
	});

	const [{ isDragging }, drag] = useDrag({
		type: 'field',
		item,
		canDrag: isCreator && !isEmptyItem(item),
		collect: (monitor) => {
			return {
				isDragging: monitor.isDragging(),
			};
		},
	});

	if (isCreator) {
		drag(drop(ref));
	}

	const combinedClassName = `${className} ${isDragging ? 'dragging' : ''}`;

	return (
		<div
			ref={ref}
			className={combinedClassName}
			style={{ opacity: isDragging ? 0 : 1 }}
			onDragOver={onDragOver}
			onDragLeave={onDragLeave}
			onDrop={onDragLeave}>
			{children}
		</div>
	);
};

export default DragDropWrapper;
