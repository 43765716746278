import classNames from 'classnames';
import { motion, MotionStyle } from 'framer-motion';
import React, { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ThemeContext from '../../contexts/themeContext';
import { adminData, dashboardMenu } from '../../menu';
import Brand from '../Brand/Brand';
import Navigation, { NavigationLine } from '../Navigation/Navigation';
import User from '../User/User';

import useAsideTouch from '../../hooks/useAsideTouch';

const Aside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);

	const { asideStyle, touchStatus, hasTouchButton, asideWidthWithSpace, x } = useAsideTouch();

	const isModernDesign = process.env.REACT_APP_MODERN_DESGIN === 'true';

	const constraintsRef = useRef(null);

	const [doc, setDoc] = useState(false);

	const { t } = useTranslation(['translation', 'menu']);

	return (
		<>
			<motion.aside
				style={asideStyle as MotionStyle}
				className={classNames(
					'aside',
					{ open: asideStatus },
					{
						'aside-touch-bar': hasTouchButton && isModernDesign,
						'aside-touch-bar-close': !touchStatus && hasTouchButton && isModernDesign,
						'aside-touch-bar-open': touchStatus && hasTouchButton && isModernDesign,
					},
				)}>
				<div className='aside-head'>
					<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
				</div>
				<div className='aside-body'>
					<Navigation menu={dashboardMenu} id='menu-dashboard' />
					<NavigationLine />
					{!doc && (
						<>
							<Navigation menu={adminData} id='main-menu-admin' />
							<NavigationLine />
						</>
					)}
				</div>
				<div className='aside-foot'>
					<nav aria-label='aside-bottom-menu'>
						<div className='navigation'></div>
					</nav>
					<User />
				</div>
			</motion.aside>
		</>
	);
};

export default Aside;
