import { CreateFormFields, CreatePage } from './pages.module';
import { requests } from '../../API/API-service';
import { FORMS, PAGES } from '../../API/API-routes';
import { IPages } from '../../frontend/src/Interfaces/IPages';
import { IFields } from '../../frontend/src/Interfaces/IGeneral';

const FORM_FIELDS_PATH = PAGES + '/field';

export const createPage = async (body: CreatePage) => {
	return requests
		.post(PAGES, body)
		.then((data) => data)
		.catch((error) => error);
};

export const removePage = async (id: string) => {
	return requests
		.delete(PAGES + '/delete/' + id)
		.then((data) => data)
		.catch((error) => error);
};

export const uploadPagesBGImage = (id: string, companyId: string, image: any) => {
	return requests
		.post(PAGES + '/image/' + companyId + '/pages/' + id, image, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		.then((data) => data)
		.catch((error) => error);
};

export const updatePageBGImage = (page_uuid: string, image_id: number) => {
	return requests
		.post(PAGES + `/updateImage/page/${page_uuid}/${image_id}`)
		.then((data) => data)
		.catch((error) => error);
};

export const findAllPages = async () => {
	return requests
		.get(PAGES)
		.then((data) => data)
		.catch((error) => error);
};

export const findOnePAge = async (id: string) => {
	return requests
		.get(PAGES, id)
		.then((data) => data)
		.catch((error) => error);
};

export const updatePage = async (id: string, body: Partial<CreatePage>) => {
	return requests
		.put(PAGES + '/' + id, body)
		.then((data) => data)
		.catch((error) => error);
};

export const changeOrder = async (body: IPages) => {
	return requests
		.post(PAGES + '/change-order', body)
		.then((data) => data)
		.catch((error) => error);
};

export const createFormField = async (body: IFields) => {
	return requests
		.post(FORM_FIELDS_PATH, body)
		.then((data) => data)
		.catch((error) => error);
};

export const updateField = async (id: number, body: Partial<CreateFormFields>) => {
	return requests
		.post(FORM_FIELDS_PATH + '/' + id, body)
		.then((data) => data)
		.catch((error) => error);
};

export const getPageFields = async (id: string) => {
	return requests
		.get(FORM_FIELDS_PATH + '/' + id)
		.then((data) => data)
		.catch((error) => error);
};

export const removeField = async (id: number) => {
	return requests
		.delete(FORM_FIELDS_PATH + '/' + id)
		.then((data) => data)
		.catch((error) => error);
};

export const removePageBackground = async (uuid: string) => {
	return requests
		.delete(PAGES + '/image/' + uuid)
		.then((data) => data)
		.catch((error) => error);
};

export const getRememberedFields = async () => {
	return requests
		.get(PAGES + '/rememberedFields')
		.then((data) => data)
		.catch((error) => error);
};

export const changePageDependency = async (uuid: string, body: { dep_page_id: string, dep_value: any}) => {
	return requests
		.post(PAGES + '/dependency/' + uuid, body)
		.then((data) => data)
		.catch((error) => error);
};

export const deletePageDependency = async (uuid: string) => {
	return requests
		.delete(PAGES + '/dependency/' + uuid)
		.then((data) => data)
		.catch((error) => error);
};
