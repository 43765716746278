import React, {SetStateAction, useCallback, useEffect, useMemo, useState} from 'react';
import {
    addEdge,
    Background, BackgroundVariant,
    Controls, Panel,
    ReactFlow,
    ReactFlowProvider,
    useEdgesState,
    useNodesState,
} from "reactflow";
import 'reactflow/dist/style.css';
import './CustomFlow.scss';
import PageNode from "./PageNode";
import EdgeWithButton from "./EdgeWithButton";
import {Form} from "../../models/forms/forms.module";
import {IPages} from "../../frontend/src/Interfaces/IPages";
import Modal, {ModalBody, ModalFooter, ModalHeader} from "../bootstrap/Modal";
import Select from "../bootstrap/forms/Select";
import {Checkbox, FormControlLabel} from "@mui/material";
import {IExpectedValue, IFields} from "../../frontend/src/Interfaces/IGeneral";
import Option from "../bootstrap/Option";
import Label from "../bootstrap/forms/Label";
import Input from "../bootstrap/forms/Input";
import Button from "../bootstrap/Button";
import {ArrowForward, CancelRounded} from "@mui/icons-material";
import {getFlow, saveFlow} from "../../models/forms/forms.repository";
import {AxiosError} from "axios";
import {changePageDependency} from "../../models/pages/pages.repository";
import {toast} from "react-hot-toast";
import {isAxiosError} from "../../helpers/General";
import {Toaster} from "react-hot-toast";
import {useTranslation} from "react-i18next";

interface ICustomFlowProps {
    form: Form
    setFlowOpen: React.Dispatch<SetStateAction<boolean>>
    getAllForms: () => void
}

const CustomFlow = (props: ICustomFlowProps) => {

    const [form, setForm] = useState<Form | null>(null)
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [depPage, setDepPage] = useState<{ source: IPages, target: IPages } | null>(null)
    const [depField, setDepfield] = useState<IFields | null>(null)
    const [targetPage, setTargetPage] = useState<IPages | null>(null)
    const [expectedValue, setExpectedValue] = useState<IExpectedValue>({expected: ''})

    const {t} = useTranslation(['translation'])

    const checkDeps = (connection: any) => {
        for (var edge of edges) {
            if (edge.source === connection.source) {
                let targetElem = nodes.find((node: any) => node.data.page.uuid === connection.target)
                let sourceElem = nodes.find((node: any) => node.data.page.uuid === connection.source)
                if (targetElem !== undefined && sourceElem !== undefined) {
                    setDepPage({source: sourceElem.data.page, target: targetElem.data.page})
                    setTargetPage(targetElem.data.page)
                }
                break;
            }
        }
    }

    const onConnect = useCallback(
        (connection: any) => {
            checkDeps(connection)
            const edge = {...connection, type: 'CustomEdge'};
            setEdges((eds) => addEdge(edge, eds));
        },
        [setEdges, checkDeps],
    );

    useEffect(() => {
        if (props.form) setForm(props.form)
    }, []);

    useEffect(() => {
        if (form && form.uuid) {
            if (nodes && nodes.length === 0 && edges && edges.length === 0) {
                getFlow(form.uuid).then((res) => {
                    if (!(res instanceof AxiosError)) {
                        if (res.nodes.length > 0 && res.edges.length > 0) {
                            sortAndSetNodes(res.nodes)
                            initEdges(res.edges)
                        } else {
                            initFlow()
                        }
                    }
                })
            }
        }
    }, [form])

    const initFlow = () => {

        if (form) {

            let initNodes: any[] = []
            let initEdges: any[] = []
            let siblings: any[] = []
            let siblPairs: any[][] = []
            let lastX = 0

            //init nodes
            form.pages?.forEach((el, index, pages) => {
                const uuid: string = el.uuid + ""

                let data = {page: el, isFirst: el.order === 0}

                // x position calculation for last two elements
                if (index === pages.length - 1 || index === pages.length - 2) {
                    let ink = initNodes.findIndex((nd) => nd.id === uuid)
                    if (ink >= 0) {
                        initNodes[ink] = {...initNodes[ink], position: {x: lastX, y: 0}}
                        lastX = lastX + 300
                        return;
                    } else {
                        initNodes.push({
                            id: uuid,
                            type: 'PageNode',
                            position: {x: lastX, y: 0},
                            data: data,
                            hidden: index === pages.length - 1,
                        })
                        lastX = lastX + 300
                        return;
                    }
                }


                if (edges.length > 0) {
                    // PositionCalculation of each node
                    if (siblings.includes(uuid)) {
                        lastX = lastX + 300
                    } else {
                        // get parents
                        let parent = edges.filter((edge) => edge.target === uuid)

                        if (parent.length === 1) {
                            // get siblings
                            let sibl = edges.filter((edge) => edge.source === parent[0].source)
                            let lastY = 0
                            let pair: any[] = []
                            sibl.forEach((edge, edgeindex) => {
                                let childs = form.pages?.find((tel) => tel.uuid === edge.target)
                                if (childs !== undefined) {
                                    pair.push(childs.uuid)
                                    initNodes.push({
                                        id: edge.target,
                                        type: 'PageNode',
                                        position: {x: lastX, y: lastY},
                                        data: data,
                                    })
                                    siblings.push(edge.target)
                                    lastY = lastY + ((edgeindex + 1) * 100)
                                }
                            })
                            siblPairs.push(pair)
                            lastX = lastX + 300
                        } else {
                            initNodes.push({
                                id: uuid,
                                type: 'PageNode',
                                position: {x: lastX, y: 0},
                                data: data,
                                isFirst: index === 0
                            })
                            lastX = lastX + 300
                        }
                    } // end positioncalculation
                } else {
                    // linear layout if no edges exist
                    initNodes.push({
                        id: uuid,
                        type: 'PageNode',
                        position: {x: lastX, y: 0},
                        data: data,
                    })
                    lastX = lastX + 300
                }
            })

            if (siblings.length) {
                siblings.map((nodeID) => {
                    let siblNode = initNodes.findIndex((el) => el.id === nodeID)
                    initNodes.map((node) => {
                        if (node.id !== nodeID && node.position.y === initNodes[siblNode].position.y) {
                            initNodes[siblNode].position.y = initNodes[siblNode].position.y + 100
                        }
                    })
                })
            }

            if (siblPairs.length) {
                siblPairs.forEach((pair) => {
                    pair.forEach((nodeID) => {
                        let siblNode = initNodes.findIndex((el) => el.id === nodeID)
                        initNodes.map((node) => {
                            if (node.id !== nodeID && node.position.y === initNodes[siblNode].position.y) {
                                initNodes[siblNode].position.y = initNodes[siblNode].position.y + 100
                            }
                        })
                    })
                })
            }

            //position of last two elements at last
            initNodes[initNodes.length - 2].position.y = 0
            initNodes[initNodes.length - 1].position.y = 0

            setNodes(initNodes)

            //init edges
            initNodes.forEach((node, index) => {
                const nextNode = initNodes[index + 1]
                if (index !== initNodes.length - 1) {
                    initEdges.push({
                        id: `reactflow__edge-${node.data.page.uuid}-${nextNode.data.page.uuid}`,
                        type: "CustomEdge",
                        sourceHandle: "a",
                        targetHandle: "a",
                        source: node.data.page.uuid,
                        target: nextNode.data.page.uuid
                    })
                }
            })
            setEdges(initEdges)
        }
    }

    const initEdges = (res: any) => {
        if (form && form.pages) {
            let newEdges = []
            newEdges = res.map((edge: any) => {
                let resEdge: any = {...edge}
                let target = form.pages?.find((page) => edge.target === page.uuid)
                let source = form.pages?.find((page) => edge.source === page.uuid)
                if (target) {
                    resEdge = {...resEdge, data: {targetPage: target}}
                }

                if (source) {
                    resEdge = {...resEdge, data: {...resEdge.data, sourcePage: source}}
                }

                return resEdge
            })
            setEdges(newEdges)
        }
    }

    const sortAndSetNodes = (res: any[]) => {
        let resNodes: any[] = []
        let {position} = res.reduce((prev, curr) => prev.position.y > curr.position.y ? curr : prev);
        if (form && form.pages) {
            let nNewNodes = 0
            form.pages.forEach((page, index) => {
                let node = res.find((resNode) => page.uuid === resNode.id)
                if (node !== undefined) {
                    resNodes.push(node)
                } else {
                    resNodes.push({
                        id: page.uuid,
                        type: 'PageNode',
                        position: {x: nNewNodes * 200, y: position.y - 100},
                        data: {page: page},
                        isFirst: index === 0
                    })
                    nNewNodes++;
                }
            })
        }
        setNodes(resNodes)
    }

    const nodeTypes = useMemo(() => ({PageNode: PageNode}), []);

    const edgeTypes = useMemo(() => ({CustomEdge: EdgeWithButton,}), []);

    const isValidConnection = (connection: any) => {
        let isValid = false;
        let firstPage = nodes[0]
        let lastPage = nodes[nodes.length - 1]
        let terms = nodes[nodes.length - 2]

        if (connection.target === connection.source) return false;

        switch (connection.target) {
            case firstPage.id:
                return false
            case lastPage.id:
                if (connection.source === terms.id) {
                    return true
                } else return false;
            default:
                isValid = true
        }

        switch (connection.source) {
            case terms.id:
                if (connection.target === lastPage.id) return true
                else return false
            case lastPage.id:
                return false
            default:
                isValid = true
        }

        edges.forEach((edg) => {
            if (edg.source === connection.target && connection.source === edg.target) {
                return false;
            }
        })

        return isValid
    }

    const setFieldExpectedValue = (exp: IExpectedValue) => {
        setExpectedValue((prevState: any) => ({...prevState, ...exp}))
    }

    const removeLastEdge = () => {
        let newEdges = edges.slice()
        newEdges.pop()
        setEdges(newEdges)
    }

    const handleMultipleInputChange = (value: string) => {
        let ar: string[] = expectedValue.expected === "" ? [] : JSON.parse(expectedValue.expected)
        let elIndex = ar.indexOf(value)
        if (elIndex >= 0) {
            ar = ar.filter((el, index) => index !== elIndex)
        } else {
            ar.push(value)
        }
        setFieldExpectedValue({...expectedValue, expected: JSON.stringify(ar.sort())})
    }

    const saveCurrentFlow = () => {
        if (form && form.uuid) {
            saveFlow(form.uuid, {nodes: nodes, edges: edges}).then((res: { nodes: any[], edges: any[] }) => {
                if (!isAxiosError(res)) {
                    sortAndSetNodes(res.nodes)
                    initEdges(res.edges)
                    toast.success(t('successSavePageFlow'))
                } else {
                    toast.error(t('failedSavePageFlow'))
                }
            })
        }
    }

    const handleDepInfoChange = () => {
        const toastId = toast.loading(t('updatingImage'))
        if (targetPage && targetPage.uuid) {
            changePageDependency(targetPage?.uuid, {dep_page_id: targetPage.uuid, dep_value: expectedValue})
                .then((res) => {
                    if (!(res instanceof AxiosError)) {
                        setForm(res)
                        initEdges(edges)
                        saveCurrentFlow()
                        setDepPage(null)
                        setDepfield(null)
                        setTargetPage(null)
                        setExpectedValue({expected: ''})
                        props.getAllForms()
                        toast.dismiss(toastId)
                        toast.success(t('successSavePageDependency'))
                    } else {
                        toast.dismiss(toastId)
                        toast.error(t('failedSavePageDependency'))
                    }
                })
                .catch((error) => {
                    toast.dismiss(toastId);
                    toast.error(t('failedSavePageDependency'));
                });
        }
    }

    return (
        <ReactFlowProvider>
            <div
                className="react-flow__CustomFlow"
                style={{
                    width: '80vw',
                    height: '80vh',
                    border: "2px solid black",
                    background: "white",
                    justifyContent: "center",
                    display: "flex",
                    position: "relative"
                }}>
                <Toaster position='top-right'/>
                {
                    form !== null && nodes && edges && (
                        <ReactFlow
                            nodeTypes={nodeTypes}
                            edgeTypes={edgeTypes}
                            nodes={nodes}
                            edges={edges}
                            onNodesChange={onNodesChange}
                            onEdgesChange={onEdgesChange}
                            onConnect={onConnect}
                            isValidConnection={isValidConnection}
                            fitView
                        >
                            <Controls/>
                            <Background id="1" variant={BackgroundVariant.Dots} color={"grey"}/>
                            <Panel position="bottom-center">
                                <Button
                                    className="mx-2"
                                    color='dark'
                                    onClick={() => {
                                        props.setFlowOpen(false)
                                    }}
                                >
                                    {t('close')}
                                </Button>
                                <Button
                                    color='success'
                                    onClick={saveCurrentFlow}
                                >
                                    {t('save')}
                                </Button>
                            </Panel>
                            <Modal isOpen={depPage !== null}
                                   setIsOpen={() => {
                                   }}
                                   isStaticBackdrop={true}
                                   isCentered={true}
                                   size={"lg"}
                            >
                                <ModalHeader style={{display: "flex", justifyContent: "space-between"}}>
                                    {
                                        depPage && (
                                            <div>{depPage.source.title} <ArrowForward height={5}
                                                                                      width={5}/> {depPage.target.title}</div>
                                        )
                                    }
                                    <Button
                                        style={{
                                            alignItems: 'end',
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            flexDirection: 'row',
                                            alignSelf: 'flex-end',
                                        }}
                                        onClick={() => {
                                            removeLastEdge()
                                            setDepfield(null)
                                            setTargetPage(null)
                                            setDepPage(null)
                                        }}>
                                        <CancelRounded/>
                                    </Button>
                                </ModalHeader>
                                <ModalBody style={{height: '50vh'}}>
                                    { /* set depending field */}
                                    <Label>Expected Field:</Label>
                                    <Select ariaLabel="Dependency-field"
                                            placeholder={"Select field"}
                                            onChange={(e: any) => {
                                                let newDepField: IFields = depPage!.source!.page_fields![e.target.value]
                                                setTargetPage((prevState: any) => {
                                                    let newTarget = {...prevState}
                                                    newTarget.dep_page_id = depPage?.source.uuid
                                                    newTarget.dep_field_id = newDepField.id
                                                    return newTarget
                                                })
                                                setDepfield(newDepField)
                                                setExpectedValue((prevState: any) => ({
                                                    ...prevState,
                                                    field_id: newDepField.id
                                                }))
                                            }
                                            }
                                    >
                                        {
                                            depPage && depPage.source && depPage.source!.page_fields!.map((field: IFields, ind) => {
                                                return (
                                                    <Option key={ind} value={ind}>{field.name}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                    { // set expected value depending on fields datatype
                                        depField && (
                                            <>
                                                {depField.dataType === 'TextField' && (
                                                    <>
                                                        <Label>Expected Input:</Label>
                                                        <Input
                                                            value={expectedValue.expected}
                                                            onChange={(e: any) => {
                                                                setFieldExpectedValue({
                                                                    expected: e.target.value,
                                                                });
                                                            }}
                                                        />
                                                    </>
                                                )}
                                                {(depField.dataType === 'Number' ||
                                                    depField.dataType === 'Slider') && (
                                                    <>
                                                        <Label>Expected input:</Label>
                                                        <div>
                                                            <FormControlLabel
                                                                className='MPage__form__formcontrol-label'
                                                                control={
                                                                    <Checkbox
                                                                        onChange={(e) => {
                                                                            setFieldExpectedValue({
                                                                                ...expectedValue,
                                                                                operator: 'LT',
                                                                            });
                                                                        }}
                                                                        value={
                                                                            expectedValue.operator ===
                                                                            'LT'
                                                                        }
                                                                        checked={
                                                                            expectedValue.operator ===
                                                                            'LT'
                                                                        }
                                                                    />
                                                                }
                                                                label='Less than'
                                                            />
                                                            <FormControlLabel
                                                                className='MPage__form__formcontrol-label'
                                                                control={
                                                                    <Checkbox
                                                                        onChange={(e) => {
                                                                            setFieldExpectedValue({
                                                                                ...expectedValue,
                                                                                operator: 'LE',
                                                                            });
                                                                        }}
                                                                        value={
                                                                            expectedValue.operator ===
                                                                            'LE'
                                                                        }
                                                                        checked={
                                                                            expectedValue.operator ===
                                                                            'LE'
                                                                        }
                                                                    />
                                                                }
                                                                label='Less than or Equal'
                                                            />
                                                            <FormControlLabel
                                                                className='MPage__form__formcontrol-label'
                                                                control={
                                                                    <Checkbox
                                                                        onChange={(e) => {
                                                                            setFieldExpectedValue({
                                                                                ...expectedValue,
                                                                                operator: 'GT',
                                                                            });
                                                                        }}
                                                                        value={
                                                                            expectedValue.operator ===
                                                                            'GT'
                                                                        }
                                                                        checked={
                                                                            expectedValue.operator ===
                                                                            'GT'
                                                                        }
                                                                    />
                                                                }
                                                                label='Greater than'
                                                            />
                                                            <FormControlLabel
                                                                className='MPage__form__formcontrol-label'
                                                                control={
                                                                    <Checkbox
                                                                        onChange={(e) => {
                                                                            setFieldExpectedValue({
                                                                                ...expectedValue,
                                                                                operator: 'GE',
                                                                            });
                                                                        }}
                                                                        value={
                                                                            expectedValue.operator ===
                                                                            'GE'
                                                                        }
                                                                        checked={
                                                                            expectedValue.operator ===
                                                                            'GE'
                                                                        }
                                                                    />
                                                                }
                                                                label='Greater than or Equal'
                                                            />
                                                            <FormControlLabel
                                                                className='MPage__form__formcontrol-label'
                                                                control={
                                                                    <Checkbox
                                                                        onChange={(e) => {
                                                                            setFieldExpectedValue({
                                                                                ...expectedValue,
                                                                                operator: 'EQ',
                                                                            });
                                                                        }}
                                                                        value={
                                                                            expectedValue.operator ===
                                                                            'EQ'
                                                                        }
                                                                        checked={
                                                                            expectedValue.operator ===
                                                                            'EQ'
                                                                        }
                                                                    />
                                                                }
                                                                label='Equal'
                                                            />
                                                            <FormControlLabel
                                                                className='MPage__form__formcontrol-label'
                                                                control={
                                                                    <Checkbox
                                                                        onChange={(e) => {
                                                                            setFieldExpectedValue({
                                                                                ...expectedValue,
                                                                                operator: 'R',
                                                                            });
                                                                        }}
                                                                        value={
                                                                            expectedValue.operator ===
                                                                            'R'
                                                                        }
                                                                        checked={
                                                                            expectedValue.operator ===
                                                                            'R'
                                                                        }
                                                                    />
                                                                }
                                                                label='In Range'
                                                            />
                                                        </div>
                                                        {expectedValue.operator &&
                                                            ['LT', 'GT', 'LE', 'GE', 'EQ'].includes(
                                                                expectedValue.operator,
                                                            ) && (
                                                                <Input
                                                                    type='number'
                                                                    onChange={(e: any) => {
                                                                        setFieldExpectedValue({
                                                                            ...expectedValue,
                                                                            expected: e.target.value,
                                                                        });
                                                                    }}
                                                                    value={expectedValue.expected || ''}
                                                                />
                                                            )}
                                                        {expectedValue.operator &&
                                                            expectedValue.operator === 'R' && (
                                                                <>
                                                                    <Input
                                                                        type='number'
                                                                        onChange={(e: any) => {
                                                                            setFieldExpectedValue({
                                                                                ...expectedValue,
                                                                                min: e.target.value,
                                                                            });
                                                                        }}
                                                                        value={expectedValue.min || ''}
                                                                    />
                                                                    <Input
                                                                        type='number'
                                                                        onChange={(e: any) => {
                                                                            setFieldExpectedValue({
                                                                                ...expectedValue,
                                                                                max: e.target.value,
                                                                            });
                                                                        }}
                                                                        value={expectedValue.max || ''}
                                                                    />
                                                                </>
                                                            )}
                                                    </>
                                                )}
                                                {depField.dataType === 'Range' && (
                                                    <>
                                                        <Label>Expected minimum:</Label>
                                                        <Input
                                                            type='number'
                                                            onChange={(e: any) => {
                                                                setFieldExpectedValue({
                                                                    ...expectedValue,
                                                                    min: e.target.value,
                                                                });
                                                            }}
                                                            value={expectedValue.min || ''}
                                                        />
                                                        <Label>Expeted maximum:</Label>
                                                        <Input
                                                            type='number'
                                                            onChange={(e: any) => {
                                                                setFieldExpectedValue({
                                                                    ...expectedValue,
                                                                    max: e.target.value,
                                                                });
                                                            }}
                                                            value={expectedValue.max || ''}
                                                        />
                                                    </>
                                                )}
                                                {(depField.dataType === 'SingleSelect' || depField.dataType === 'SingleSelectBox') && (
                                                    <>
                                                        <Label>Expected Value:</Label>
                                                        <Select
                                                            ariaLabel='expected'
                                                            placeholder={'Expected Value'}
                                                            onChange={(e: any) => {
                                                                setFieldExpectedValue({
                                                                    ...expectedValue,
                                                                    expected: e.target.value,
                                                                });
                                                            }}
                                                            value={expectedValue.expected}>
                                                            {JSON.parse(
                                                                depField.valuesToBeSelected,
                                                            ).map(
                                                                (value: string, valueIndex: number) => {
                                                                    return (
                                                                        <Option
                                                                            key={valueIndex}
                                                                            value={value}>
                                                                            {value}
                                                                        </Option>
                                                                    );
                                                                },
                                                            )}
                                                        </Select>
                                                    </>
                                                )}
                                                {(depField.dataType === 'MultiSelect' || depField.dataType === 'MultiSelectBox') && (
                                                    <div>
                                                        <Label>Expected Value:</Label>
                                                        <Select
                                                            ariaLabel={'expected multi'}
                                                            multiple
                                                            onChange={(e: any) => {
                                                                handleMultipleInputChange(
                                                                    e.target.value,
                                                                );
                                                            }}
                                                            value={
                                                                expectedValue.expected === ''
                                                                    ? []
                                                                    : JSON.parse(expectedValue.expected)
                                                            }>
                                                            {JSON.parse(
                                                                depField.valuesToBeSelected,
                                                            ).map(
                                                                (value: string, valueIndex: number) => {
                                                                    return (
                                                                        <Option
                                                                            key={valueIndex}
                                                                            value={value}>
                                                                            {value}
                                                                        </Option>
                                                                    );
                                                                },
                                                            )}
                                                        </Select>
                                                    </div>
                                                )}
                                                {(depField.dataType === 'Checkbox' ||
                                                    depField.dataType === 'Switch') && (
                                                    <>
                                                        <Label>Expected Value:</Label>
                                                        <Select
                                                            ariaLabel='expected'
                                                            placeholder={
                                                                expectedValue.expected ||
                                                                'ExpectedValue'
                                                            }
                                                            onChange={(e: any) => {
                                                                setFieldExpectedValue({
                                                                    ...expectedValue,
                                                                    expected: e.target.value,
                                                                });
                                                            }}
                                                            value={expectedValue.expected || ''}>
                                                            <Option value={'true'}>Checked</Option>
                                                            <Option value={'false'}>Unchecked</Option>
                                                        </Select>
                                                    </>
                                                )}
                                                {depField.dataType === 'File' && (
                                                    <>
                                                        <Label>Expected Input:</Label>
                                                        <Select
                                                            ariaLabel='expected'
                                                            placeholder={
                                                                expectedValue.expected ||
                                                                'ExpectedValue'
                                                            }
                                                            onChange={(e: any) => {
                                                                setFieldExpectedValue({
                                                                    ...expectedValue,
                                                                    expected: e.target.value,
                                                                });
                                                            }}
                                                            value={expectedValue.expected || ''}>
                                                            <Option value={'uploaded'}>Uploaded</Option>
                                                            <Option value={''}>Not Uploaded</Option>
                                                        </Select>
                                                    </>
                                                )}
                                                {depField.dataType === 'Appointment' && (
                                                    <>
                                                        <Label>Expected Input:</Label>
                                                        <Select
                                                            ariaLabel='expected'
                                                            placeholder={
                                                                expectedValue.expected ||
                                                                'ExpectedValue'
                                                            }
                                                            onChange={(e: any) => {
                                                                setFieldExpectedValue({
                                                                    ...expectedValue,
                                                                    expected: e.target.value,
                                                                });
                                                            }}
                                                            value={expectedValue.expected || ''}>
                                                            <Option value={'booked'}>Booked</Option>
                                                            <Option value={''}>Not booked</Option>
                                                        </Select>
                                                    </>
                                                )}
                                            </>
                                        )
                                    }
                                </ModalBody>
                                <ModalFooter>
                                    <Button
                                        className="mx-2"
                                        color='dark'
                                        onClick={() => {
                                            removeLastEdge()
                                            setDepfield(null)
                                            setTargetPage(null)
                                            setDepPage(null)
                                        }}
                                    >
                                        {t('close')}
                                    </Button>
                                    <Button
                                        color='success'
                                        onClick={() => {
                                            handleDepInfoChange()
                                        }}
                                    >
                                        {t('save')}
                                    </Button>
                                </ModalFooter>
                            </Modal>
                        </ReactFlow>
                    )
                }
            </div>
        </ReactFlowProvider>
    );
};

export default CustomFlow;