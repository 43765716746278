import React, {FC, useEffect} from "react";
import {useTranslation} from "react-i18next";

interface ILoginHeaderProps {
    isNewUser?: boolean;
    isVerification?: boolean;
}

const LoginHeader: FC<ILoginHeaderProps> = ({ isNewUser, isVerification }) => {
    const { i18n } = useTranslation(['translation']);
    const { t } = useTranslation(['translation']);

    useEffect(()=> {
        i18n.changeLanguage('en-En')
    },[])

    if (isNewUser) {
        return (
            <>
                <div className='text-center h1 fw-bold mt-5'>{t('createAccount')}</div>
                <div className='text-center h4 text-muted mb-5'>{t('signUpToGetStarted')}</div>
            </>
        );
    }

    if(isVerification) {
        return (
            <>
                <div className='text-center h1 fw-bold mt-5'>{t('verification')}</div>
                <div className='text-center h4 text-muted mb-5'>{t('verifyToGetStarted')}</div>
            </>
        );
    }

    return (
        <>
            <div className='text-center h1 fw-bold mt-5'>{t('welcome')}</div>
            <div className='text-center h4 text-muted mb-5'>{t('signInToContinue')}</div>
        </>
    );
};

export default LoginHeader;