import React, { useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { Calendar, momentLocalizer, Views, EventProps, SlotInfo } from 'react-big-calendar';
import {
	CalendarTodayButton,
	getLabel,
	getUnitType,
} from '../../../components/extras/calendarHelper';
import useDarkMode from '../../../hooks/useDarkMode';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../../components/bootstrap/Card';

const localizer = momentLocalizer(moment);
const now = new Date();

interface IEvent {
	title: string;
	start: Date;
	end: Date;
	color?: string;
	users: string[];
}

interface AppointmentCalendarProps {
	field: {
		id: number;
		page_id: string;
		name: string;
		saved_by: null | string;
		dataType: string;
		frontend: number;
		valuesToBeSelected: string;
		pageName: string;
		values: string;
		required: boolean;
		field_settings: null | string;
		form_uuid: string;
		order: number;
		created_at: string;
		dep_id: null | number;
		dep_info: string | null;
		updated_at: string;
		deleted_at: null | string;
	};
	selectedFieldData: {
		user_identifier: string;
		created_at: string;
		answer: string;
		field_type: string;
	}[];
}

const MyEvent = ({ event }: EventProps<IEvent>) => {
	return (
		<div className='row g-2'>
			<div className='col text-truncate'>{event?.title}</div>
		</div>
	);
};

const AppointmentCalendar: React.FC<AppointmentCalendarProps> = ({ field, selectedFieldData }) => {
	const { darkModeStatus } = useDarkMode();
	const [date, setDate] = useState(new Date());
	const [selectedUser, setSelectedUser] = useState<string | null>(null);

	const unitType = getUnitType(Views.MONTH);

	const handleDateChange = (newDate: Date) => {
		setDate(newDate);
	};

	const views = {
		month: true,
		week: false,
		day: false,
		agenda: false,
	};

	const eventStyleGetter = (event: IEvent, start: Date, end: Date, isSelected: boolean) => {
		const isActiveEvent = start <= now && end >= now;
		const isPastEvent = end < now;
		const color = isActiveEvent ? 'success' : event.color;

		return {
			className: classNames({
				[`bg-l${darkModeStatus ? 'o25' : '10'}-${color} text-${color}`]: color,
				'border border-success': isActiveEvent,
				'opacity-50': isPastEvent,
			}),
		};
	};

	const handleSelectSlot = (slotInfo: SlotInfo) => {
		setDate(slotInfo.start);
	};

	const handleSelectEvent = (event: IEvent) => {
		console.log('Event clicked:', event);
	};

	const computeEvents = () => {
		const eventMap: { [key: string]: IEvent } = {};

		selectedFieldData.forEach((appointment) => {
			if (appointment.answer !== '') {
				const dateKey = new Date(appointment.answer).toISOString();
				if (!eventMap[dateKey]) {
					eventMap[dateKey] = {
						title: `Appointments`,
						start: new Date(appointment.answer),
						end: new Date(appointment.answer),
						color: 'primary',
						users: [],
					};
				}
				eventMap[dateKey].users.push(appointment.user_identifier);
			}
		});

		return Object.values(eventMap).map((event) => {
			event.title += ` (${event.users.length} users)`;
			return event;
		});
	};

	const events = computeEvents();

	return (
		<div className='row h-100'>
			<div className='col-xl-9'>
				<Card stretch style={{ minHeight: 600 }}>
					<CardHeader>
						<CardActions>
							<CalendarTodayButton
								unitType={unitType}
								date={date}
								setDate={handleDateChange}
								viewMode={Views.MONTH}
							/>
						</CardActions>
					</CardHeader>
					<CardBody isScrollable>
						<Calendar
							selectable
							toolbar={false}
							localizer={localizer}
							events={events}
							defaultView={Views.MONTH}
							views={views}
							view={Views.MONTH}
							date={date}
							onNavigate={handleDateChange}
							scrollToTime={new Date(1970, 1, 1, 6)}
							defaultDate={new Date()}
							onSelectSlot={handleSelectSlot}
							onSelectEvent={handleSelectEvent}
							components={{
								event: MyEvent,
							}}
							eventPropGetter={eventStyleGetter}
							style={{ height: 500 }}
						/>
					</CardBody>
				</Card>
			</div>
			<div className='col-xl-3'>
				<Card stretch style={{ minHeight: 600 }}>
					<CardHeader>
						<CardLabel icon='Today' iconColor='info'>
							<CardTitle>{moment(date).format('MMMM Do YYYY')}</CardTitle>
							<CardSubTitle>{moment(date).fromNow()}</CardSubTitle>
						</CardLabel>
						<CardActions>
							<CalendarTodayButton
								unitType={Views.DAY}
								date={date}
								setDate={handleDateChange}
								viewMode={Views.DAY}
							/>
						</CardActions>
					</CardHeader>
					<CardBody isScrollable>
						<Calendar
							selectable
							toolbar={false}
							localizer={localizer}
							events={events}
							defaultView={Views.DAY}
							views={{ day: true }}
							view={Views.DAY}
							date={date}
							step={JSON.parse(field.valuesToBeSelected).steps}
							scrollToTime={new Date(1970, 1, 1, 6)}
							defaultDate={new Date()}
							onSelectSlot={handleSelectSlot}
							onSelectEvent={handleSelectEvent}
							components={{
								event: MyEvent,
							}}
							eventPropGetter={eventStyleGetter}
							style={{ height: 500 }}
						/>
					</CardBody>
				</Card>
			</div>
		</div>
	);
};

export default AppointmentCalendar;
