import React, { ReactNode } from 'react';
import './EButton.scss';
import { Button } from '@mui/material';

interface IProps {
	theme: 'primary' | 'light' | 'white' | 'text';
	children: ReactNode;
	onClick: (b: any) => any;
	disabled?: boolean;
}

const EButton = (props: IProps) => {
	return (
		<Button
			className={'EButton__' + props.theme}
			variant={props.theme === 'primary' ? 'contained' : 'outlined'}
			onClick={props?.onClick}
			disabled={props.disabled ? props.disabled : false}>
			{props.children}
		</Button>
	);
};

export default EButton;
