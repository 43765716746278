import React, {useState} from 'react';
import { Button } from '@mui/material';
import {IFields} from "../../../Interfaces/IGeneral";

interface CustomFileFieldProps {
	label: string;
	handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	accept?: string;
	className?: any;
	field: IFields
}

const CustomFileField: React.FC<CustomFileFieldProps> = ({
	label,
	handleFileChange,
	accept,
	className,
	field
}) => {

	const [fileName, setFileName] = useState<string>("")

	const handleFileUpload = (e: any) => {
		setFileName(e.target.files[0].name)
		handleFileChange(e)
	}

	return (
		<div className="mt-4">
			<div className="MPage__drop-target__field-label">
				{field.name}{field.required ? "*" : ""}
			</div>
			<Button
				className={className + " mt-2"}
				variant='contained'
				component='label'
				style={{ width: '100%' }}>
				{
					fileName !== "" ? (
						fileName
					) : (
						<>
							{label}
							<input type='file' hidden onChange={handleFileUpload} accept={accept} />
						</>
					)
				}
			</Button>
		</div>
	);
};

export default CustomFileField;
