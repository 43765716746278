import { Columns } from '../../components/Table';
import { FieldType } from '../../components/editWindow';
import { IFields } from '../../frontend/src/Interfaces/IGeneral';

export interface CreatePage {
	title: string;
	description: string;
	form_id: string;
	order: number;
	page_styles?: Array<{name: string, value:string}>;
}

export interface CreateFormFields {
	name: string;
	dataType: string;
	page_id?: string;
	valuesToBeSelected: string;
	pageName: string;
	values: string;
	required: boolean;
	frontend: number;
	order: number;
}

export interface FormField {
	id: number;
	page_id: string;
	name: string;
	page: null | string;
	dataType: string;
	frontend: number;
	valuesToBeSelected: string;
	pageName: string;
	values: string;
	required: boolean;
	order: number;
}

export const PagesColumns: Columns[] = [
	{ dataName: 'id', name: 'ID' },
	{ dataName: 'uuid', name: 'UUID' },
	{ dataName: 'form_id', name: 'Form ID' },
	{ dataName: 'title', name: 'Title', fieldType: FieldType.TextField },
	{ dataName: 'description', name: 'Description', fieldType: FieldType.TextField },
	{ dataName: 'order', name: 'Order', fieldType: FieldType.Number },
];

export interface Page {
	id?: number;
	uuid?: string;
	form_id: string;
	title: string;
	description: string;
	order: number;
	page_styles?: any[];
	bg_image?: string | null;
	isLastPage: boolean;
}

export const emptyPage: Page = {
	form_id: '',
	title: '',
	description: '',
	order: 0,
	isLastPage: false,
};

export const emptyField: IFields = {
	name: '',
	dataType: '',
	frontend: 0,
	valuesToBeSelected: '',
	pageName: '',
	required: false,
	order: -1,
	dep_info: null,
	dep_id: null,
};
