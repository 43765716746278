import React, {useEffect, useState} from 'react';
import {BaseEdge, EdgeLabelRenderer, getStraightPath, useReactFlow} from "reactflow";
import {Button, Tooltip} from "@mui/material";
import {changePageDependency, deletePageDependency} from "../../models/pages/pages.repository";
import {CancelRounded, InfoOutlined} from "@mui/icons-material";
import {IExpectedValue, IFields} from "../../frontend/src/Interfaces/IGeneral";
import "./CustomFlow.scss"
import {useTranslation} from "react-i18next";

const EdgeWithButton = ({ id, sourceX, sourceY, targetX, targetY, target, data } : any) => {

    const { t } = useTranslation(['translation'])

    const [edgeData, setEdgeData] = useState<{ dep_info: IExpectedValue, fieldName: string } | null>(null)

    const { setEdges } = useReactFlow();
    const [edgePath, labelX, labelY] = getStraightPath({
        sourceX,
        sourceY,
        targetX,
        targetY,
    });
    const [infoVisible, setInfoVisible] = useState<boolean>(false)

    useEffect(() => {
        initEdgeData()
    }, [data])

    const initEdgeData = () => {
        if (data) {
            let res : any  = {}
            if (data.targetPage.dep_page_id && typeof data.targetPage.dep_value === "string") {
                res.dep_info = JSON.parse(data.targetPage.dep_value)
                let depField = data.sourcePage.page_fields ? data.sourcePage.page_fields.find((field: IFields) => field.id === res.dep_info.field_id) : undefined
                if(depField) res.fieldName = depField.name
                setEdgeData(res)
            }
        }
    }

    return (
        <>
            <BaseEdge id={id} path={edgePath} />
            <EdgeLabelRenderer>
                <div
                    style={{
                        position: 'absolute',
                        transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                        pointerEvents: 'all',
                        background: 'white',
                        border: "1px solid black",
                        display: "flex",
                        zIndex: infoVisible ? 2 : 0
                }}
                    className="nodrag nopan"
                >
                    <Button
                        style={{zIndex: 2}}
                        onClick={() => {
                        setEdges((es) => {
                            let edge = es.find((eg) => eg.id !== id)
                            if(edge) {
                                deletePageDependency(edge.target)
                            }
                            return es.filter((e) => e.id !== id)
                        });
                    }}>
                        <CancelRounded/>
                    </Button>
                    <div style={{position: "relative"}}>
                        {
                            infoVisible && (
                                <div className="react-flow__info"
                                     onClick={()=>{setInfoVisible(false)}}
                                >
                                    {
                                        edgeData ? (
                                            <p>
                                                Field: { edgeData.fieldName ? edgeData.fieldName : "" }
                                                <br/>
                                                Expected value: {(edgeData.dep_info.expected ? edgeData.dep_info.expected : "")}
                                            </p>
                                        ) : (
                                            <p>
                                                {t('nodata')}
                                            </p>
                                        )
                                    }

                                </div>
                            )
                        }
                        <Button
                            style={{zIndex: 2}}
                            onClick={() => {setInfoVisible(!infoVisible)}}>
                            <InfoOutlined/>
                        </Button>
                    </div>
                </div>
            </EdgeLabelRenderer>
        </>
    );
};

export default EdgeWithButton;