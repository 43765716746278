import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/styles.scss';
import App from './App/App';
import { ThemeContextProvider } from './contexts/themeContext';
import { AuthContextProvider } from './contexts/authContext';
import './i18n';
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import { deDE } from '@mui/x-date-pickers/locales';
import moment from 'moment-timezone';
moment.tz.setDefault("Europe/Berlin");


const children = (
	<LocalizationProvider
		dateAdapter={AdapterMoment}
		localeText={deDE.components.MuiLocalizationProvider.defaultProps.localeText}
	>
		<AuthContextProvider>
			<ThemeContextProvider>
				<Router>
					<App />
				</Router>
			</ThemeContextProvider>
		</AuthContextProvider>
	</LocalizationProvider>
);

const container = document.getElementById('root');

createRoot(container as Element).render(children);
