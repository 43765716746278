import React, {useEffect} from 'react';
import {Handle, NodeProps, Position} from "reactflow";
import {IPages} from "../../frontend/src/Interfaces/IPages";
import "./CustomFlow.scss";

interface IPageNode {
    page?: IPages
    isFirst?: boolean
}

const PageNode = (props: NodeProps<IPageNode>) => {

    return (
        <div
            style={{border: "2px solid black", padding: "1rem", width: "150px", zIndex: 2, background: "white", display: "flex"}}
        >
            {
                props.data && props.data.isFirst !== undefined && props.data.page && (
                    <>
                        {
                            props.data && !props.data.isFirst && (
                                <Handle
                                    type="target"
                                    position={Position.Left}
                                    id="a"
                                    isConnectable={props.isConnectable}
                                />
                            )
                        }
                        <div>{props.data.page.title} <br/> {props.data.page.uuid}</div>
                        {
                            !props.data.page.isLastPage && (
                                <Handle
                                    type="source"
                                    position={Position.Right}
                                    id="a"
                                    isConnectable={props.isConnectable}
                                />
                            )
                        }
                    </>
                )
            }
        </div>
    );
};

export default React.memo(PageNode);