import React, {useCallback} from "react";
import {Box} from "@mui/material";
import Dropdown, {DropdownItem, DropdownMenu, DropdownToggle} from "../components/bootstrap/Dropdown";
import LANG, {getLangWithKey, ILang} from "../lang";
import Button, {IButtonProps} from "../components/bootstrap/Button";
import Spinner from "../components/bootstrap/Spinner";
import toast from "react-hot-toast";
import Icon from "../components/icon/Icon";
import {useTranslation} from "react-i18next";
import useDarkMode from "../hooks/useDarkMode";

const LanguageChanger = () => {

    const { darkModeStatus } = useDarkMode();
    const { i18n } = useTranslation(['translation']);

    const styledBtn: IButtonProps = {
        color: darkModeStatus ? 'dark' : 'light',
        hoverShadow: 'default',
        isLight: !darkModeStatus,
        size: 'lg',
    };

    const changeLanguage = (lng: ILang['key']['lng']) => {
        i18n.changeLanguage(lng).then(() =>
            toast.success(
                <span className='d-flex align-items-center'>
                    <Icon icon={getLangWithKey(lng)?.icon} size='lg' className='me-1'/>
                    <span>{`Language changed to ${getLangWithKey(lng)?.text}`}</span>
                </span>,
            ),
        );
    };

    return (
        <Box
            sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                display: 'flex',
                justifyContent: 'center',
                padding: 1,
            }}>
            <Dropdown>
                <DropdownToggle hasIcon={false}>
                    {typeof getLangWithKey(i18n.language as ILang['key']['lng'])?.icon ===
                    'undefined' ? (
                        <Button
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...styledBtn}
                            className='btn-only-icon'
                            aria-label='Change language'
                            data-tour='lang-selector'>
                            <Spinner isSmall inButton='onlyIcon' isGrow />
                        </Button>
                    ) : (
                        <Button
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...styledBtn}
                            icon={getLangWithKey(i18n.language as ILang['key']['lng'])?.icon}
                            aria-label='Change language'
                            data-tour='lang-selector'
                        />
                    )}
                </DropdownToggle>
                <DropdownMenu isAlignmentEnd data-tour='lang-selector-menu'>
                    {Object.keys(LANG).map((i) => (
                        <DropdownItem key={LANG[i].lng}>
                            <Button
                                icon={LANG[i].icon}
                                onClick={() => changeLanguage(LANG[i].lng)}>
                                {LANG[i].text}
                            </Button>
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </Box>
    )
}

export default LanguageChanger;
