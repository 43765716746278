import React, {SetStateAction, useContext, useEffect, useState} from 'react';
import {DatePicker, MobileDateTimePicker, renderTimeViewClock, TimePicker} from "@mui/x-date-pickers";
import {Checkbox, FormControl, styled} from "@mui/material";
import "./AppointmentForm.scss"
import ThemeContext from "../../contexts/themeContext";
import {IFields} from "../../frontend/src/Interfaces/IGeneral";
import moment from "moment";
import Input from "../bootstrap/forms/Input";
import Label from "../bootstrap/forms/Label";
import {number} from "prop-types";
import Select from "../bootstrap/forms/Select";
import Option from "../bootstrap/Option";
import {useTranslation} from "react-i18next";
import {CheckBox} from "@mui/icons-material";

interface IAppointmentForm {
    field: IFields,
    setField: React.Dispatch<SetStateAction<IFields | null>>;
}

const AppointmentForm = (props: IAppointmentForm) => {


    const [calOptions, setCalOptions] = useState<any>({ schedule: {}, steps: 1 });

	const { t } = useTranslation(['translation', 'menu']);

	const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

	useEffect(() => {
		setCalOptions(JSON.parse(props.field.valuesToBeSelected));
	}, []);

	const handleScheduleChange = (weekday: string, fromOrTo: string, e: any) => {
		let daySchedule =
			calOptions.schedule && calOptions.schedule[weekday]
				? { ...calOptions.schedule[weekday] }
				: {};
		daySchedule = { ...daySchedule, [fromOrTo]: e.target.value };
		let newCaloptiions = {
			...calOptions,
			schedule: { ...calOptions.schedule, [weekday]: daySchedule },
		};

		setCalOptions((prevState: any) => ({
			...prevState,
			schedule: { ...prevState.schedule, [weekday]: daySchedule },
		}));

		props.setField((prevState: any) => ({
			...prevState,
			valuesToBeSelected: JSON.stringify(newCaloptiions),
		}));
	};

	const timespanChangeHandler = (fromTo: string, date: any) => {
		let timespan = { ...calOptions.timespan } || {};
		timespan[fromTo] = date.target.value;
		setCalOptions((prevState: any) => ({ ...prevState, timespan: timespan }));

		let newCaloptiions = { ...calOptions, timespan: timespan };

		props.setField((prevState: any) => ({
			...prevState,
			valuesToBeSelected: JSON.stringify(newCaloptiions),
		}));
	};

	const timespanCheckHandler = () => {
		if (calOptions.timespan === undefined) {
			setCalOptions((prevState: any) => ({ ...prevState, timespan: { from: '', to: '' } }));
		} else {
			const newCalOptions = { ...calOptions };
			delete newCalOptions.timespan;
			setCalOptions(newCalOptions);
		}
	};

	const timestepChangeHandler = (e: any) => {
		if (Number(e.target.value) <= 60 && Number(e.target.value) >= 1) {
			const newCalOptions = { ...calOptions };
			newCalOptions.steps = Number(e.target.value);

			setCalOptions((prevState: any) => ({ ...prevState, steps: Number(e.target.value) }));

			props.setField((prevState: any) => ({
				...prevState,
				valuesToBeSelected: JSON.stringify(newCalOptions),
			}));
		}
	};

	const numberOfAppointmentsHandler = (e: any) => {
		const newCalOptions = { ...calOptions };
		newCalOptions.numberOfAppointments = e.target.value;

		setCalOptions((prevState: any) => ({ ...prevState, numberOfAppointments: e.target.value }));

		props.setField((prevState: any) => ({
			...prevState,
			valuesToBeSelected: JSON.stringify(newCalOptions),
		}));
	};

	const excludedDaysHandler = (weekday: string) => {
		const schedule = calOptions.schedule || {};
		if (schedule[weekday] === undefined) {
			schedule[weekday] = { from: '', to: '' };
			setCalOptions((prevState: any) => ({ ...prevState, schedule: schedule }));
		} else {
			delete schedule[weekday];
			setCalOptions((prevState: any) => ({ ...prevState, schedule: schedule }));
		}
	};

	const CheckBoxStyles = {
		'& .MuiSvgIcon-root': {
			fill: 'white',
			background: "var(--bs-bg)",
		},
	};

	const CheckboxStyled = styled(Checkbox)(({ theme }) => ({ ...CheckBoxStyles }));

	return (
		<div className='AppointmentForm'>
			{
				<div className='AppointmentForm__Schedule-Form'>
					<div
						className={
							'AppointmentForm__Schedule-Form__weekday ' +
							(calOptions.timespan === undefined ? ' disabled' : '')
						}>
						<Checkbox
							className="AppointmentForm__Schedule-Form__weekday__checkbox"
							onChange={(e) => {
								timespanCheckHandler();
							}}
							checked={calOptions.timespan !== undefined}
						/>
						<span>{t('timespan')}</span>
						<Input
							title={t('from')}
							onChange={(date) => {
								if (date) timespanChangeHandler('from', date);
							}}
							disabled={calOptions.timespan === undefined}
							type='date'
							value={calOptions.timespan?.from || ''}
						/>
						<span>-</span>
						<Input
							title={t('fromTo')}
							onChange={(date) => {
								if (date) timespanChangeHandler('to', date);
							}}
							disabled={calOptions.timespan === undefined}
							type={'date'}
							value={calOptions.timespan?.to || ''}
						/>
					</div>
					{weekdays
						//.filter((weekdays) => calOptions.excludeWeekends ? !["Sunday","Saturday"].includes(weekdays) : weekdays)
						.map((weekday, weekdayIndex) => {
							return (
								<div
									key={weekday + weekdayIndex}
									className={
										'AppointmentForm__Schedule-Form__weekday ' +
										(calOptions.schedule[weekday] === undefined
											? ' disabled'
											: '')
									}>
									<Checkbox
										onChange={(e) => {
											excludedDaysHandler(weekday);
										}}
										checked={calOptions.schedule[weekday] !== undefined}
										className="AppointmentForm__Schedule-Form__weekday__checkbox"
									/>
									<span>{t(weekday)}</span>
									<Input
										title={t('from')}
										onChange={(date) => {
											if (date) handleScheduleChange(weekday, 'from', date);
										}}
										disabled={calOptions.schedule[weekday] === undefined}
										type='time'
										value={
											calOptions.schedule[weekday] !== undefined
												? calOptions.schedule[weekday].from
												: ''
										}
									/>
									<span>-</span>
									<Input
										title={t('fromTo')}
										onChange={(date) => {
											if (date) handleScheduleChange(weekday, 'to', date);
										}}
										disabled={calOptions.schedule[weekday] === undefined}
										type='time'
										value={
											calOptions.schedule[weekday] !== undefined
												? calOptions.schedule[weekday].to
												: ''
										}
									/>
								</div>
							);
						})}
					<div className='row w-100 mt-3'>
						<div className='col-12 col-lg-6 mb-sm-3'>
							<Label>{t('steps')}</Label>
							<Select
								ariaLabel='Steps'
								title={t('steps')}
								value={calOptions.steps}
								onChange={timestepChangeHandler}>
								<Option value={1}>{`1 ${t('minute')}`}</Option>
								<Option value={5}>{`5 ${t('minutes')}`}</Option>
								<Option value={10}>{`10 ${t('minutes')}`}</Option>
								<Option value={15}>{`15 ${t('minutes')}`}</Option>
								<Option value={30}>{`30 ${t('minutes')}`}</Option>
								<Option value={60}>{`60 ${t('minutes')}`}</Option>
							</Select>
						</div>
						<div className='col-12 col-lg-6'>
							<Label>{t('numberOfAppointmentsPerStep')}</Label>
							<Input
								title={t('numberOfAppointmentsPerStep')}
								type='number'
								value={calOptions.numberOfAppointments}
								onChange={numberOfAppointmentsHandler}
							/>
						</div>
					</div>
				</div>
			}
		</div>
	);
};

export default AppointmentForm;