import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import React, {SetStateAction, useEffect, useState, useTransition} from 'react';
import {emptyForm, Form} from '../models/forms/forms.module';
import Input from './bootstrap/forms/Input';
import Label from './bootstrap/forms/Label';
import { useTranslation } from 'react-i18next';
import ExplanationComponent from './ExplanationComponent';
import TemplateSelector from './TemplateSelector';

interface IFormPorps {
	setForm: React.Dispatch<SetStateAction<Form | null>>;
	form: Form | null;
	saveForm: () => void;
}

export const FormForm: (props: IFormPorps) => ReactJSXElement = (props: IFormPorps) => {
	const { t } = useTranslation(['translation', 'menu']);

	const [form, setForm] = useState<Form>(emptyForm)

	const [isPending, setTransition] = useTransition()

	useEffect(()=> {
		if(props.form) {
			setForm(props.form)
		}
	},[])

	return (
		<>
			<Label>{t('name')}</Label>
			<Input
				placeholder={t('name')}
				onChange={(e: any) => {
					setForm((prevState: any) => ({...prevState, name: e.target.value}))
					setTransition(()=> {
						props.setForm((prevState: any) => ({
							...prevState,
							name: e.target.value,
						}));
					})
				}}
				value={form.name || ''}
				style={{ marginBottom: '1rem' }}
			/>
			<Label>{t('description')}</Label>
			<Input
				placeholder={t('description')}
				onChange={(e: any) => {
					setForm((prevState: any) => ({...prevState, description: e.target.value}))
					setTransition(()=> {
						props.setForm((prevState: any) => ({
							...prevState,
							description: e.target.value,
						}));
					})
				}}
				value={form.description || ''}
				style={{ marginBottom: '1rem' }}
			/>
{/*
			<Label>{t('successLink')}</Label>
			<Input
				placeholder={t('successLink')}
				onChange={(e: any) => {
					setForm((prevState: any) => ({...prevState, successLink: e.target.value}))
					setTransition(()=> {
						props.setForm((prevState: any) => ({
							...prevState,
							successLink: e.target.value,
						}));
					})
				}}
				value={form.successLink || ''}
				style={{ marginBottom: '1rem' }}
			/>
			<Label>{t('cancelLink')}</Label>
			<Input
				placeholder={t('cancelLink')}
				onChange={(e: any) => {
					setForm((prevState: any) => ({...prevState, cancelLink: e.target.value}))
					setTransition(()=> {
						props.setForm((prevState: any) => ({
							...prevState,
							cancelLink: e.target.value,
						}));
					})
				}}
				value={form.cancelLink || ''}
				style={{ marginBottom: '1rem' }}
			/>
*/}

			{
				props.form && !props.form.uuid &&
				<TemplateSelector form={props.form} setForm={props.setForm} />
			}
		</>
	);
};
