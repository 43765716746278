import { Columns } from '../../components/Table';
import { FieldType } from '../../components/editWindow';
import { IPages } from '../../frontend/src/Interfaces/IPages';
import { API_BASE_PATH, DATA, FRONTEND_BASE_PATH } from '../../API/API-routes';
import { IFields } from '../../frontend/src/Interfaces/IGeneral';

export interface CreateFormDto {
	name?: string;
	description: string;
	successLink: string;
	cancelLink: string;
	styles?: Array<{ name: string; value: string }>;
	email_template?: string;
	selected_template_id?: string;
	active?: boolean;
}

export interface StyleInfo {
	name: string;
	value: string;
}

export interface Form {
	id?: string;
	company_id?: string;
	uuid?: string;
	name?: string;
	description: string;
	successLink: string;
	cancelLink: string;
	form_link: string;
	styles?: StyleInfo[];
	pages?: Array<IPages>;
	bg_image: string | null;
	form_template?: string;
	totalCounter?: string[] | number;
	email_template?: string;
	data?: FormAnswer[];
	save_as_template?: boolean;
	selected_template_id?: string;
	active: boolean;
}

export interface FormAnswer {
	id: number;
	form_uuid: string;
	company_id: string;
	field_id: number;
	value: string;
	field_type: string;
	uuid: string;
	user_identifier: string;
	form_identifier: null;
}

export interface InnerFormAnswer {
	created_at: string;
	forms: { name: string };
	user_identifier: string;
}

export interface UpdateFieldPosition {
	formId: string;
	updatedFields: IFields[];
}

export const FormColumns: Columns[] = [
	{ dataName: 'id', name: 'ID' },
	{ dataName: 'uuid', name: 'UUID' },
	{ dataName: 'name', name: 'Name', fieldType: FieldType.TextField },
	{ dataName: 'description', name: "tables.description", fieldType: FieldType.TextField },
	{
		dataName: 'active',
		name: "tables.active",
		fieldType: FieldType.Switch,
	},
	{
		dataName: 'form_link',
		name: "tables.form_link",
		displayed: false,
		fieldType: FieldType.Link,
		prefix: FRONTEND_BASE_PATH,
		isInternalLink: true,
	},
	{
		dataName: 'save_as_template',
		name: '',
		displayed: false,
		fieldType: FieldType.Link,
		prefix: API_BASE_PATH,
		isInternalLink: true,
	},
];

export const AnswerColumns: Columns[] = [
	{ dataName: 'id', fieldType: FieldType.TextField, name: 'ID', displayed: true },
	{ dataName: 'uuid', fieldType: FieldType.TextField, name: 'UUID', displayed: true },
	{ dataName: 'name', name: 'Name', fieldType: FieldType.TextField, displayed: true },
	{
		dataName: 'description',
		name: "tables.description",
		fieldType: FieldType.TextField,
		displayed: true,
	},
	{
		dataName: 'form_link',
		name: "tables.form_link",
		displayed: false,
		fieldType: FieldType.MoveToPage,
		prefix: FRONTEND_BASE_PATH,
		isInternalLink: true,
	},
	{
		dataName: 'uuid',
		name: "tables.export_to" + " CSV",
		displayed: false,
		fieldType: FieldType.Download,
		prefix: DATA + '/export/',
		isInternalLink: true,
	},
];

export const AnswersInnerColumns: Columns[] = [
	{ dataName: 'fieldName', name: 'ID', fieldType: FieldType.TextField, displayed: true },
	{ dataName: 'createdAt', name: "tables.created_at", fieldType: FieldType.TextField, displayed: true },
	{
		dataName: 'link',
		name: "tables.show",
		displayed: false,
		fieldType: FieldType.ShowInModal,
	},
];

export const OnlySingleFieldAnswers: Columns[] = [
	{ dataName: 'user_identifier', name: "tables.user_identifiyer", fieldType: FieldType.TextField },
	{ dataName: 'created_at', name: "tables.created_at", fieldType: FieldType.TextField },
	{ dataName: 'answer', name: "tables.answer", fieldType: FieldType.TextField },
	{
		dataName: 'form_link',
		name: "tables.show",
		displayed: false,
		fieldType: FieldType.ShowInModal,
	},
];

export const AnswersSingleColumns: Columns[] = [
	{ dataName: 'field_name', name: "tables.question", fieldType: FieldType.TextField },
	{ dataName: 'field_value', name: "tables.answer", fieldType: FieldType.TextField },
	{ dataName: 'compare', name: "tables.compare", fieldType: FieldType.TextField },
	{
		dataName: 'link',
		name: "tables.show",
		displayed: false,
		fieldType: FieldType.ShowInModal,
	},
];

export const OnlySingleFieldAnswersEmpty = {
	id: '0',
	name: '',
	createdAt: '',
	answer: '',
};

export const AnswersSingleColumnsEmpty = {
	id: '0',
	fieldName: '',
	fieldType: '',
	fieldValue: '',
	others: '',
};

export const AnswersInnerColumnsEmpty = {
	id: '0',
	fieldName: '',
	form_link: '',
};

export const emptyForm: Form = {
	id: '0',
	company_id: '',
	uuid: '',
	name: '',
	description: '',
	successLink: '',
	cancelLink: '',
	bg_image: '',
	form_link: '',
	pages: [],
	active: true,
};
