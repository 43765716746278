import '../../styles/_nestedNav.scss';
import React, { useEffect, useState } from 'react';
import { duplicateForm, findAllForms } from '../../models/forms/forms.repository';
import {
	OnlySingleFieldAnswers,
	OnlySingleFieldAnswersEmpty,
} from '../../models/forms/forms.module';

import Datatable from '../../components/DataTable';
import {
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	outlinedInputClasses,
	Select,
	selectClasses,
	styled,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import CircleChart from '../common/Charts/CircleChart';
import BarChart from '../common/Charts/BarChart';
import useDarkMode from '../../hooks/useDarkMode';
import { downloadFile, getFormAnswerByField } from '../../models/data/data.repository';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AreaChart from '../common/Charts/AreaChart';
import LineWithLabel from '../common/Charts/LineWithLabel';
import { ITableFunctions } from '../../frontend/src/Interfaces/IGeneral';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';
import { downloadString } from '../../helpers/helpers';
import AppointmentCalendar from '../common/Charts/AppointmentCalendar';

interface FieldAnswerDat {
	user_identifier: string;
	created_at: string;
	answer: string;
	field_type: string;
}

export default function SingleFieldAnswers(props: {
	form_uuid: string;
	selectedAnswer: { id: number; name: string };
	setSelectedAnswer: (data: any) => void;
	fields: any[];
	formName?: string;
	callback?: () => void;
}) {
	const { t } = useTranslation(['translation']);
	const navigate = useNavigate();
	const [selectedFieldData, setSelectedFieldData] = useState<FieldAnswerDat[]>([]);
	const [field, setField] = useState<any>(null);
	const [chartItems, setChartItems] = useState<{ name: string; count: any }[]>([]);
	const { darkModeStatus } = useDarkMode();

	const requestData = async (form_uuid: string, answer_id: number) => {
		const foundItem = await getFormAnswerByField(form_uuid, answer_id);

		if (foundItem) {
			setField(foundItem.page_field);
			setChartItems(foundItem.count ?? []);
			setSelectedFieldData(foundItem.rows ?? []);
		}
	};

	useEffect(() => {
		if (props.form_uuid && props.selectedAnswer) {
			requestData(props.form_uuid, props.selectedAnswer.id);
		}
	}, [props.form_uuid, props.selectedAnswer]);

	const Item = styled(Paper)(({ theme }) => ({
		...theme.typography.body2,
		backgroundColor: darkModeStatus ? '#1A2027' : 'none',
		padding: theme.spacing(3),
		textAlign: 'center',
		color: darkModeStatus ? '#e7eef8' : '#1A2027',
		border: 'none',
		boxShadow: 'none',
	}));

	const tableFunctions: ITableFunctions = {
		downloadFile: {
			action: async (key: string) => {
				const body = {
					path: key,
				};
				downloadFile(body).then((res) => {
					const re = /(?:\.([^.]+))?$/;
					const filename = key.replace(/^.*[\\/]/, '');
					let extension = re.exec(filename);
					let filetype = extension ? extension[1] : '';

					if (res instanceof AxiosError) {
						if (res.response) {
							toast.error(res.response.data.message);
						} else {
							toast.error(t('error'));
						}
					} else {
						downloadString(res, filetype, filename);
						toast.success(t('successfullyCreated'));
					}
				});
			},
			actionName: 'Download File',
			argument: 'answer',
			icon: 'Download',
			applyTo: { attribute: 'field_type', value: 'File' },
		},
	};

	return (
		<div className='SingleFieldAnswer'>
			<Grid container spacing={2} padding={3}>
				<Grid style={{ marginTop: 5, marginBottom: 10 }} container spacing={2}>
					{selectedFieldData.length < 1 ||
						(selectedFieldData[0].field_type !== 'Appointment' && (
							<Grid item xs={5}>
								<Item>
									<CircleChart
										labels={chartItems.map((item) => item.name)}
										title={t('answersPage.answers_answer')}
										values={chartItems.map((item) => item.count)}
									/>
								</Item>
							</Grid>
						))}

					<Grid
						item
						xs={
							selectedFieldData.length < 1 ||
							selectedFieldData[0].field_type !== 'Appointment'
								? 5
								: 10
						}>
						<Item>
							{selectedFieldData.length > 0 && (
								<>
									{selectedFieldData[0].field_type === 'Slider' ? (
										<AreaChart
											series={chartItems
												.sort((a, b) => parseInt(a.name) - parseInt(b.name))
												.map((o) => ({ x: o.name, y: o.count }))}
											field={field}
											title={t('answersPage.answers_answer')}
										/>
									) : selectedFieldData[0].field_type === 'Range' ? (
										<LineWithLabel
											data={chartItems}
											title={t('answersPage.answers_answer')}
										/>
									) : selectedFieldData[0].field_type === 'Appointment' ? (
										<AppointmentCalendar
											field={field}
											selectedFieldData={selectedFieldData}
										/>
									) : (
										<BarChart
											series={[
												{
													name: '',
													data: chartItems.map((item) => item.count),
												},
											]}
											xAxis={chartItems.map((item) => item.name)}
											title={t('answersPage.answers_answer')}
										/>
									)}
								</>
							)}
						</Item>
					</Grid>
					<Grid item xs={2}>
						<Item
							style={{
								minHeight: 125,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}>
							<FormControl fullWidth>
								<InputLabel
									sx={{
										color: 'grey.500',
										'&.Mui-focused': {
											color: 'grey.500',
										},
									}}
									id='fieldSelection'>
									{t('answersPage.select_field')}
								</InputLabel>
								<Select
									disableUnderline
									sx={{
										[`& .${selectClasses.select}`]: {
											minWidth: '200px',
											background: 'gray.600',
											color: darkModeStatus ? '#e7eef8' : '#1A2027',
										},
										[`& .${outlinedInputClasses.notchedOutline}`]: {
											borderColor: 'grey.500',
											borderStyle: 'solid',
											borderWidth: '2px',
										},
										'&:hover': {
											[`& .${outlinedInputClasses.notchedOutline}`]: {
												borderColor: 'grey.500',
											},
										},
									}}
									labelId='fieldSelection'
									id='fieldSelection'
									value={props.selectedAnswer.id}
									label={t('answersPage.select_field')}
									onChange={(event: any) => {
										props.setSelectedAnswer(
											props.fields.find((o) => o.id === event.target.value),
										);
									}}>
									{props.fields.map((item: any) => {
										return (
											<MenuItem key={item.id} value={item.id}>
												{item.name}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Item>
					</Grid>
				</Grid>
				<Datatable<any>
					showActions={true}
					columns={OnlySingleFieldAnswers}
					emptyValue={OnlySingleFieldAnswersEmpty}
					showInModal={(data) => {
						if (props.callback) {
							navigate(data, { state: { key: 'value' } });
							props.callback();
						}
					}}
					getAll={findAllForms}
					post={undefined}
					isFluid={false}
					icon={'QuestionAnswer'}
					name={`${t('answersPage.answers_statistics')} > ${props.formName} - ${
						props.selectedAnswer.name
					}`}
					data={selectedFieldData.map((i: any) => {
						return {
							...i,
							form_link: `/answers/${props.form_uuid}/${i.user_identifier}`,
							created_at: moment(i.created_at).format('DD/MM/YYYY HH:mm'),
						};
					})}
					tableFunctions={tableFunctions}
				/>
			</Grid>
		</div>
	);
}
