import React, { FC, useState, useContext } from 'react';
import classNames from 'classnames';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Button from '../../../components/bootstrap/Button';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Spinner from '../../../components/bootstrap/Spinner';
import Logo from '../../../components/Logo';
import AuthContext from '../../../contexts/authContext';
import useDarkMode from '../../../hooks/useDarkMode';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { login, oauthLogin, registerUser } from '../../../models/auth/auth.repository';
import { AxiosError } from 'axios';
import LanguageChanger from '../../../layout/LanguagueChanger';

interface ILoginHeaderProps {
	isNewUser?: boolean;
	isVerification?: boolean;
}

const LoginHeader: FC<ILoginHeaderProps> = ({ isNewUser, isVerification }) => {
	const { t } = useTranslation(['translation', 'menu']);

	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>{t('createAccount')}</div>
				<div className='text-center h4 text-muted mb-5'>{t('signUpToGetStarted')}</div>
			</>
		);
	}

	if (isVerification) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>{t('verification')}</div>
				<div className='text-center h4 text-muted mb-5'>{t('veryfiyToGetStarted')}</div>
			</>
		);
	}

	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>{t('welcome')}</div>
			<div className='text-center h4 text-muted mb-5'>{t('signInToContinue')}</div>
		</>
	);
};

interface ILoginProps {
	isSignUp?: boolean;
}
const Login: FC<ILoginProps> = ({ isSignUp }) => {
	const { token, setToken } = useContext(AuthContext);
	const [msg, setMsg] = useState<{ type: 'error' | 'success'; msg: string } | null>(null);
	const [searchParams] = useSearchParams();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [signInPassword, setSignInPassword] = useState<boolean>(false);
	const [singUpStatus, setSingUpStatus] = useState<boolean>(!!isSignUp);

	const navigate = useNavigate();
	const { t } = useTranslation(['translation', 'menu']);
	const { darkModeStatus } = useDarkMode();

	const isOAuth =
		searchParams.has('response_type') &&
		searchParams.get('response_type') === 'code' &&
		searchParams.has('client_id') &&
		searchParams.has('redirect_uri');
	const clientId = searchParams.get('client_id');
	const redirectUri = searchParams.get('redirect_uri');
	const state = searchParams.get('state');

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			username: '',
			password: '',
		},
		validate: (values) => {
			const errors: { username?: string; password?: string } = {};

			if (!values.username) {
				errors.username = 'Required';
			}

			if (!values.password) {
				errors.password = 'Required';
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: async (values) => {
			setIsLoading(true);
			if (isOAuth) {
				oauthLogin({
					emailOrPhone: values.username,
					password: values.password,
					clientId: clientId ?? '',
					redirectUri: redirectUri ?? '',
					state: state ?? '',
				}).then((response) => {
					if (response.redirect_uri) {
						window.location.href = response.redirect_uri;
					} else {
						console.error('OAuth login failed', response);
						setIsLoading(false);
					}
				});
			} else {
				login({ emailOrPhone: values.username, password: values.password }).then(
					(loginSuccessFull) => {
						if (loginSuccessFull && !(loginSuccessFull instanceof AxiosError)) {
							setIsLoading(false);
							if (setToken) {
								setToken(loginSuccessFull.access_token);
							}
							navigate('/');
						} else {
							formik.setFieldError('password', t('loginDataWrong'));
							setIsLoading(false);
						}
					},
				);
			}
		},
	});

	const registration = useFormik({
		enableReinitialize: true,
		initialValues: {
			emailOrPhone: '',
			name: '',
			surname: '',
			companyName: '',
			password: '',
			passwordRepeat: '',
		},
		validate: (values) => {
			const errors: {
				emailOrPhone?: string;
				companyName?: string;
				password?: string;
				name?: string;
				surname?: string;
				passwordRepeat?: string;
			} = {};

			if (!values.emailOrPhone) {
				errors.emailOrPhone = 'Required';
			}

			if (!values.password) {
				errors.password = 'Required';
			}

			if (!values.name) {
				errors.name = 'Required';
			}

			if (!values.surname) {
				errors.surname = 'Required';
			}

			if (!values.companyName) {
				errors.companyName = 'Required';
			}

			if (!values.passwordRepeat) {
				errors.passwordRepeat = 'Required';
			}

			return errors;
		},
		onSubmit: async (values) => {
			setIsLoading(true);
			await registerUser({
				password: values.password,
				emailOrPhone: values.emailOrPhone,
				name: values.name,
				surname: values.surname,
				companyName: values.companyName,
			})
				.then((res) => {
					if (res instanceof AxiosError && res.response) {
						setIsLoading(false);
						setMsg({ type: 'error', msg: res.response.data.message });
					} else {
						setIsLoading(false);
						setMsg({ type: 'success', msg: t('checkEmails') });
					}
				})
				.catch((error) => error);
		},
	});

	const handleContinue = () => {
		setIsLoading(true);
		setTimeout(() => {
			if (formik.values.username === '') {
				formik.setFieldError('username', t('fillInput'));
			} else {
				setSignInPassword(true);
			}
			setIsLoading(false);
		}, 1000);
	};

	const LC = LanguageChanger;

	return (
		<PageWrapper
			isProtected={false}
			title={singUpStatus ? t('signUp') : t('login')}
			className={classNames({ 'bg-warning': !singUpStatus, 'bg-info': singUpStatus })}>
			<LC />
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 mt-2 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										<Logo width={200} />
									</Link>
								</div>
								<div
									className={classNames('rounded-3', {
										'bg-l10-dark': !darkModeStatus,
										'bg-dark': darkModeStatus,
									})}>
									<div className='row row-cols-2 g-3 pb-3 px-3 mt-0'>
										<div className='col'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={singUpStatus}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setSignInPassword(false);
													setSingUpStatus(!singUpStatus);
												}}>
												{t('login')}
											</Button>
										</div>
										<div className='col'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={!singUpStatus}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setSignInPassword(false);
													setSingUpStatus(!singUpStatus);
												}}>
												{t('signUp')}
											</Button>
										</div>
									</div>
								</div>

								<LoginHeader isNewUser={singUpStatus} />
								<form className='row g-4 justify-content-center'>
									{singUpStatus ? (
										<>
											<div className='col-12'>
												<FormGroup
													id='emailOrPhone'
													isFloating
													label={t('yourEmail')}>
													<Input
														type='email'
														autoComplete='email'
														value={registration.values.emailOrPhone}
														isTouched={
															registration.touched.emailOrPhone
														}
														invalidFeedback={
															registration.errors.emailOrPhone
														}
														isValid={registration.isValid}
														onChange={registration.handleChange}
														onBlur={registration.handleBlur}
														onFocus={() => {
															registration.setErrors({});
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='name'
													isFloating
													label={t('yourName')}>
													<Input
														autoComplete='given-name'
														value={registration.values.name}
														isTouched={registration.touched.name}
														invalidFeedback={registration.errors.name}
														isValid={registration.isValid}
														onChange={registration.handleChange}
														onBlur={registration.handleBlur}
														onFocus={() => {
															registration.setErrors({});
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='surname'
													isFloating
													label={t('yourSurname')}>
													<Input
														autoComplete='family-name'
														value={registration.values.surname}
														isTouched={registration.touched.surname}
														invalidFeedback={
															registration.errors.surname
														}
														isValid={registration.isValid}
														onChange={registration.handleChange}
														onBlur={registration.handleBlur}
														onFocus={() => {
															registration.setErrors({});
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='companyName'
													isFloating
													label={t('companyName')}>
													<Input
														autoComplete='given-name'
														value={registration.values.companyName}
														isTouched={registration.touched.companyName}
														invalidFeedback={
															registration.errors.companyName
														}
														isValid={registration.isValid}
														onChange={registration.handleChange}
														onBlur={registration.handleBlur}
														onFocus={() => {
															registration.setErrors({});
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='password'
													isFloating
													label={t('password')}>
													<Input
														type='password'
														value={registration.values.password}
														isTouched={registration.touched.password}
														invalidFeedback={
															registration.errors.password
														}
														isValid={registration.isValid}
														onChange={registration.handleChange}
														onBlur={registration.handleBlur}
														onFocus={() => {
															registration.setErrors({});
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='passwordRepeat'
													isFloating
													label={t('repeatPassword')}>
													<Input
														type='password'
														value={registration.values.passwordRepeat}
														isTouched={
															registration.touched.passwordRepeat
														}
														invalidFeedback={
															registration.errors.passwordRepeat
														}
														isValid={registration.isValid}
														onChange={registration.handleChange}
														onBlur={registration.handleBlur}
														onFocus={() => {
															registration.setErrors({});
														}}
													/>
												</FormGroup>
											</div>
											{msg && (
												<Card
													className={'shadow-3d-dark col-11 ' + msg.type}>
													<CardBody>
														<div
															className={
																'row justify-content-center col-12 fw-bold ' +
																msg.type
															}>
															{msg.msg}
														</div>
													</CardBody>
												</Card>
											)}
											<div className='col-12'>
												<Button
													color='info'
													className='w-100 py-3'
													onClick={registration.handleSubmit}>
													{t('signUp')}
												</Button>
											</div>
										</>
									) : (
										<>
											<div className='col-12'>
												<FormGroup
													id='username'
													isFloating
													label={t('yourEmailOrUsername')}
													className={classNames({
														'd-none': signInPassword,
													})}>
													<Input
														autoComplete='username'
														value={formik.values.username}
														isTouched={formik.touched.username}
														invalidFeedback={formik.errors.username}
														isValid={formik.isValid}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														onFocus={() => {
															formik.setErrors({});
														}}
														onKeyDown={(e) => {
															if (e.key === 'Enter') {
																handleContinue();
															}
														}}
													/>
												</FormGroup>
												{signInPassword && (
													<div className='row'>
														<div
															onClick={() =>
																setSignInPassword(false)
															}>
															Back
														</div>
														<div className='text-center h4 mb-3 fw-bold'>
															Hi, {formik.values.username}.
														</div>
													</div>
												)}
												<FormGroup
													id='password'
													isFloating
													label={t('password')}
													className={classNames({
														'd-none': !signInPassword,
													})}>
													<Input
														type='password'
														autoComplete='current-password'
														value={formik.values.password}
														isTouched={formik.touched.password}
														invalidFeedback={formik.errors.password}
														validFeedback='Looks good!'
														isValid={formik.isValid}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														onKeyDown={(e) => {
															if (e.key === 'Enter') {
																formik.handleSubmit();
															}
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												{!signInPassword ? (
													<Button
														color='warning'
														className='w-100 py-3'
														isDisable={!formik.values.username}
														onClick={handleContinue}>
														{isLoading && (
															<Spinner isSmall inButton isGrow />
														)}
														{t('next')}
													</Button>
												) : (
													<Button
														color='warning'
														className='w-100 py-3'
														onClick={formik.handleSubmit}>
														{isLoading && (
															<Spinner isSmall inButton isGrow />
														)}
														{t('login')}
													</Button>
												)}
											</div>
										</>
									)}
								</form>
							</CardBody>
						</Card>
						<div className='text-center'>
							<a
								href='/'
								className={classNames('text-decoration-none me-3', {
									'link-light': singUpStatus,
									'link-dark': !singUpStatus,
								})}>
								{t('privacyPolicy')}
							</a>
							<a
								href='/'
								className={classNames('link-light text-decoration-none', {
									'link-light': singUpStatus,
									'link-dark': !singUpStatus,
								})}>
								{t('termsOfUse')}
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
