import React, {useCallback, useContext, useState} from 'react';
import classNames from "classnames";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import Logo from "../../../components/Logo";
import Card, {CardBody} from "../../../components/bootstrap/Card";
import Page from "../../../layout/Page/Page";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import {useTranslation} from "react-i18next";
import useDarkMode from "../../../hooks/useDarkMode";
import {useFormik} from "formik";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Button from "../../../components/bootstrap/Button";
import LoginHeader from "../../../layout/LoginHeader/LoginHeader";
import {verifyUser} from "../../../models/auth/auth.repository";
import {LoginUser} from "../../../models/auth/Auth.module";
import {AxiosError} from "axios";
import AuthContext from "../../../contexts/authContext";
import LanguageChanger from "../../../layout/LanguagueChanger";

const Verify = () => {

    const { setToken } = useContext(AuthContext);

    const { t } = useTranslation(['translation', 'menu']);
    const { darkModeStatus } = useDarkMode();
    const params = useParams()
    const navigate = useNavigate();

    const [msg, setMsg] = useState<{type: 'error' | 'success', msg: string} | null>(null)

    const formik = useFormik(
        {
            enableReinitialize: true,
            initialValues: {
                emailOrPhone: '',
                password: '',
            },
            validate: (values) => {
                const errors: { emailOrPhone?: string; password?: string } = {};

                if (!values.emailOrPhone) {
                    errors.emailOrPhone = 'Required';
                }

                if (!values.password) {
                    errors.password = 'Required';
                }

                return errors

            },
            onSubmit: async (values) => {
                if(params && params.token) {
                    const body : LoginUser = {
                        emailOrPhone: values.emailOrPhone,
                        password: values.password
                    }
                    verifyUser(body, params.token)
                        .then((res : any) => {
                            if(res && !(res instanceof AxiosError)) {
                                if(setToken) {
                                    setToken(res.access_token)
                                    navigate('/')
                                }
                            } else {
                                setMsg({type: 'error', msg: res.response.data.message})
                            }
                        })
                        .catch(err => err)
                } else {
                    setMsg({type: 'error', msg: t('deleteError')})
                }
            }
        }
    )

    const LC = useCallback(LanguageChanger,[])

    return (
        <PageWrapper
            isProtected={false}
            title={t('verification')}
            className={classNames('bg-info')}>
            <LC/>
            <Page className='p-0'>
                <div className='row h-100 align-items-center justify-content-center'>
                    <div className='col-xl-4 col-lg-6 col-md-8 mt-2 shadow-3d-container'>
                        <Card className='shadow-3d-dark' data-tour='login-page'>
                            <CardBody>
                                <div className='text-center my-5'>
                                    <Link
                                        to='/'
                                        className={classNames(
                                            'text-decoration-none  fw-bold display-2',
                                            {
                                                'text-dark': !darkModeStatus,
                                                'text-light': darkModeStatus,
                                            },
                                        )}>
                                        <Logo width={200} />
                                    </Link>
                                </div>
                                <LoginHeader isVerification={true}/>
                                <form className='row g-4 justify-content-center'>
                                    <div className='col-12'>
                                        <FormGroup
                                            id='emailOrPhone'
                                            isFloating
                                            label={t('yourEmail')}>
                                            <Input type='email'
                                                   autoComplete='email'
                                                   value={formik.values.emailOrPhone}
                                                   isTouched={formik.touched.emailOrPhone}
                                                   invalidFeedback={
                                                       formik.errors.emailOrPhone
                                                   }
                                                   isValid={formik.isValid}
                                                   onChange={formik.handleChange}
                                                   onBlur={formik.handleBlur}
                                                   onFocus={() => {
                                                       formik.setErrors({});
                                                   }}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className='col-12'>
                                        <FormGroup
                                            id='password'
                                            isFloating
                                            label={t('password')}>
                                            <Input
                                                type='password'
                                                value={formik.values.password}
                                                isTouched={formik.touched.password}
                                                invalidFeedback={
                                                    formik.errors.password
                                                }
                                                isValid={formik.isValid}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                onFocus={() => {
                                                    formik.setErrors({});
                                                }}
                                            />
                                        </FormGroup>
                                    </div>
                                    {
                                        msg && (
                                            <Card className={"shadow-3d-dark col-11 " + msg.type}>
                                                <CardBody>
                                                    <div className={'row justify-content-center col-12 fw-bold ' + msg.type}>
                                                        {msg.msg}
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        )
                                    }
                                    <div className='col-12'>
                                        <Button
                                            color='info'
                                            className='w-100 py-3'
                                            onClick={formik.handleSubmit}>
                                            {t('verification')}
                                        </Button>
                                    </div>
                                </form>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </Page>
        </PageWrapper>
    );
};

export default Verify;