import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import DataTable from '../../components/DataTable';
import { emptyPage, Page, PagesColumns } from '../../models/pages/pages.module';
import { findAllPages } from '../../models/pages/pages.repository';
import { emptyForm, Form, FormColumns } from '../../models/forms/forms.module';
import { findAllForms } from '../../models/forms/forms.repository';
import { Company, CompanyColumns, emptyCompany } from '../../models/companies/companies.module';
import { findAllCompanies } from '../../models/companies/companies.repository';

interface BaseDataTableProps {
	isFluid?: boolean;
	name: string;
}
const CompaniesTable: FC<BaseDataTableProps> = ({ isFluid, name }) => {
	const { t } = useTranslation(['translation', 'menu']);

	return (
		<>
			<DataTable<Company>
				columns={CompanyColumns}
				emptyValue={emptyCompany}
				getAll={findAllCompanies}
				isFluid={true}
				name={name}
			/>
		</>
	);
};

export default CompaniesTable;
