import '../../styles/_nestedNav.scss';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { findAllForms } from '../../models/forms/forms.repository';
import { useParams } from 'react-router-dom';
import {
	AnswersInnerColumns,
	AnswersInnerColumnsEmpty,
	Form,
	InnerFormAnswer,
} from '../../models/forms/forms.module';

import Datatable from '../../components/DataTable';
import {
	Container,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	outlinedInputClasses,
	Select,
	selectClasses,
	styled,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import Modal, { ModalBody, ModalHeader } from '../../components/bootstrap/Modal';
import Button from '../../components/bootstrap/Button';
import { CancelRounded } from '@mui/icons-material';
import SingleUserAnswer from './SingleUserAnswer';
import moment from 'moment';
import SingleFieldAnswers from './SingleFieldAnswers';
import useDarkMode from '../../hooks/useDarkMode';
import { getFormAnswers } from '../../models/data/data.repository';
import { useTranslation } from 'react-i18next';
import { Columns } from '../../components/Table';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../layout/SubHeader/SubHeader';
import Dropdown, { DropdownMenu, DropdownToggle } from '../../components/bootstrap/Dropdown';
import Page from '../../layout/Page/Page';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Checks, { ChecksGroup } from '../../components/bootstrap/forms/Checks';
import Popovers from '../../components/bootstrap/Popovers';
import Icon from '../../components/icon/Icon';
import Input from '../../components/bootstrap/forms/Input';
import { FieldType } from '../../components/editWindow';

type ColumnVisibility = {
	[key: string]: boolean;
};

interface AnswerRow {
	[key: string]: { type: string; value: string } | any;
}

export default function AnswersInner() {
	const params = useParams();

	const [answerData, setAnswerData] = useState<Array<InnerFormAnswer>>([]);
	const [filteredForms, setFilteredForms] = useState<Array<InnerFormAnswer>>([]);
	const [totalData, setTotalData] = useState(0);
	const [totalAnswers, setTotalAnswers] = useState(0);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [fields, setFields] = useState([]);
	const [selectedUser, setSelectedUser] = useState<string>();
	const [selectedField, setSelectedField] = useState<{ id: number; name: string }>();
	const [singleFieldModalOpen, setSingleFieldModalOpen] = useState(false);
	const [formName, setFormName] = useState('');
	const [search, setSearch] = useState('');
	const [columns, setColumns] = useState<ColumnVisibility>({
		'Answer Key': true,
		Time: true,
		Actions: true,
	});
	const [dynamicColumns, setDynamicColumns] = useState<Columns[]>([]);

	const { darkModeStatus } = useDarkMode();
	const { t } = useTranslation(['translation']);

	const requestData = useCallback(async () => {
		if (params.form_uuid) {
			const myData = await getFormAnswers(params.form_uuid);

			if (myData && myData.rows.length > 0) {
				const rows = myData.rows.map((row: AnswerRow) => {
					const transformedRow: { [key: string]: any } = {};
					Object.entries(row).forEach(([key, value]) => {
						if (
							value &&
							typeof value === 'object' &&
							'type' in value &&
							'value' in value
						) {
							if (['MultiSelect','SingleSelect', 'Range'].includes(value.type)) {
								try {
									if (
										typeof value.value === 'string' &&
										value.value.trim() !== ''
									) {
										transformedRow[key] = JSON.parse(value.value).join(', ');
									} else {
										transformedRow[key] = value.value;
									}
								} catch (e) {
									console.error(
										`Error parsing value for key ${key}:`,
										value.value,
										e,
									);
									transformedRow[key] = value.value;
								}
							} else {
								transformedRow[key] = value.value;
							}
						} else {
							transformedRow[key] = value;
						}
					});
					return transformedRow;
				});

				setFields(myData.fields);
				setTotalData(myData.totalEntriesCount);
				setTotalAnswers(myData.uniqueUserCount);
				setAnswerData(rows);
				setFormName(myData.form_name);
			}
		}
		if (params.user_identifier) {
			setIsModalOpen(true);
			setSingleFieldModalOpen(false);
			setSelectedUser(params.user_identifier);
		}
	}, [
		params.user_identifier,
		params.form_uuid,
		setIsModalOpen,
		setSingleFieldModalOpen,
		setSelectedUser,
		setFields,
		setTotalData,
		setTotalAnswers,
		setAnswerData,
		setFormName,
	]);

	const Item = styled(Paper)(({ theme }) => ({
		...theme.typography.body2,
		backgroundColor: darkModeStatus ? '#1A2027' : '#0000000a',
		padding: theme.spacing(3),
		textAlign: 'center',
		color: darkModeStatus ? '#e7eef8' : '#1A2027',
	}));

	useEffect(() => {
		const filterForms = () => {
			if (answerData && search.length) {
				const lowerCaseSearch = search.toLowerCase();
				const filtered = answerData.filter((answer) => {
					const uuidMatch = answer.user_identifier
						? answer.user_identifier.toLowerCase().includes(lowerCaseSearch)
						: false;
					const nameMatch = answer.created_at
						? answer.created_at.toLowerCase().includes(lowerCaseSearch)
						: false;

					return uuidMatch || nameMatch;
				});
				setFilteredForms(filtered);
			} else {
				setFilteredForms(answerData ?? []);
			}
		};

		filterForms();
	}, [search, answerData]);

	useEffect(() => {
		if (selectedField) {
			setSingleFieldModalOpen(true);
		}
	}, [selectedField]);

	useEffect(() => {
		requestData();
	}, [params.user_identifier, params.form_uuid]);

	useEffect(() => {
		const initialDynamicColumns = [
			...AnswersInnerColumns,
			...fields.map((field: { name: string; id: number }, index) => ({
				id: field.id,
				dataName: field.name,
				name: field.name,
				fieldType: FieldType.TextField,
				displayed: index < 4,
			})),
		];

		setDynamicColumns(initialDynamicColumns);
	}, [fields]);

	const handleColumnToggle = (columnName: string) => {
		setDynamicColumns((currentColumns) =>
			currentColumns.map((column) => {
				if (`${column.name}-${column.id}` === columnName) {
					return { ...column, displayed: !column.displayed };
				}
				return column;
			}),
		);
	};

	const showActions = columns.Actions;

	return (
		<PageWrapper className={"AnswersInner"}>
			<SubHeader>
				<SubHeaderLeft>
					<label
						className='border-0 bg-transparent cursor-pointer me-0'
						htmlFor='searchInput'>
						<Icon icon='Search' size='2x' color='primary' />
					</label>
					<Input
						id='searchInput'
						type='search'
						className='border-0 shadow-none bg-transparent'
						placeholder={t('answersPage.search_answers_inner_text')}
						onChange={(event: ChangeEvent<HTMLInputElement>) =>
							setSearch(event.target.value)
						}
						value={search}
					/>
				</SubHeaderLeft>
				<SubHeaderRight>
					<Dropdown>
						<DropdownToggle hasIcon={false}>
							<Button
								icon='FilterAlt'
								color='dark'
								isLight
								className='btn-only-icon position-relative'>
								{Object.values(columns).includes(false) && (
									<Popovers desc='Filtering applied' trigger='hover'>
										<span className='position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-2'>
											<span className='visually-hidden'>
												{t('answersPage.filtered_text')}
											</span>
										</span>
									</Popovers>
								)}
							</Button>
						</DropdownToggle>
						<DropdownMenu isAlignmentEnd size='lg'>
							<div className='container py-2'>
								<div className='row g-3'>
									<FormGroup label='Columns' className='col-12'>
										<ChecksGroup>
											{dynamicColumns
												.filter((o) => o.name !== 'Show')
												.map((column, index) => (
													<Checks
														key={index}
														id={`${column.name}-${column.id}`}
														label={column.name}
														name={column.name}
														value={column.name}
														onChange={() =>
															handleColumnToggle(
																`${column.name}-${column.id}`,
															)
														}
														checked={column.displayed}
													/>
												))}
										</ChecksGroup>
									</FormGroup>
								</div>
							</div>
						</DropdownMenu>
					</Dropdown>
				</SubHeaderRight>
			</SubHeader>
			<Page container='fluid'>
				<Grid container spacing={2} padding={3}>
					<Grid
						sx={{
							marginTop: 5,
							marginBottom: 10,
						}}
						container
						spacing={2}>
						<Grid item xs={5}>
							<Item>
								<p style={{ fontSize: 15 }}>
									{t('answersPage.total_answers_title')}
								</p>
								<p style={{ fontSize: 22 }}>{totalAnswers}</p>
							</Item>
						</Grid>
						<Grid item xs={5}>
							<Item>
								<p style={{ fontSize: 15 }}>{t('answersPage.total_data_title')}</p>
								<p style={{ fontSize: 22 }}>{totalData}</p>
							</Item>
						</Grid>
						<Grid item xs={2}>
							<Item
								style={{
									minHeight: 125,
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}>
								<FormControl fullWidth>
									<InputLabel
										sx={{
											color: darkModeStatus ? '#e7eef8' : '#1A2027',
											'&.Mui-focused': {
												color: darkModeStatus ? '#e7eef8' : '#1A2027',
											},
										}}
										id='fieldSelection'>
										{t('answersPage.select_field')}
									</InputLabel>
									<Select
										sx={{
											[`& .${selectClasses.select}`]: {
												minWidth: '200px',
												background: 'gray.600',
												color: 'grey.700',
											},
											[`& .${outlinedInputClasses.notchedOutline}`]: {
												borderColor: 'grey.500',
												borderStyle: 'solid',
												borderWidth: '2px',
											},
											'&:hover': {
												[`& .${outlinedInputClasses.notchedOutline}`]: {
													borderColor: 'grey.500',
												},
											},
										}}
										labelId='fieldSelection'
										id='fieldSelection'
										value={selectedField?.id}
										displayEmpty
										label='Select Field'
										onChange={(event: any) => {
											setSelectedField(
												fields.find(
													(o: { id: number; name: string }) =>
														o.id === event.target.value,
												),
											);
										}}>
										{fields.map((item: { id: number; name: string }) => {
											return (
												<MenuItem key={item.id} value={item.id}>
													{item.name}
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
							</Item>
						</Grid>
					</Grid>
					<Datatable<any>
						showActions={showActions}
						icon='analytics'
						columns={dynamicColumns}
						emptyValue={AnswersInnerColumnsEmpty}
						showInModal={(data) => {
							setIsModalOpen(true);
							setSelectedUser(data);
						}}
						getAll={findAllForms}
						post={undefined}
						isFluid={false}
						name={`${t('answersPage.answers_list_table')} - ${formName}`}
						openWindows={undefined}
						editItem={undefined}
						update={undefined}
						delete={undefined}
						data={filteredForms.map((ansData, index) => {
							return {
								...ansData,
								link: ansData.user_identifier,
								fieldName: index + 1,
								createdAt: moment(ansData.created_at).format('DD.MM.YYYY HH:mm'),
								form_link: `/answers/${params.form_uuid}/${ansData.user_identifier}`,
							};
						})}
					/>
				</Grid>
			</Page>

			<Modal
				size={'xl'}
				isOpen={isModalOpen}
				setIsOpen={(status: boolean) => {
					setIsModalOpen(false);
					setSelectedUser(undefined);
				}}
				isStaticBackdrop={true}
				isCentered={true}>
				<ModalHeader>
					<Button
						color="dark"
						style={{
							alignItems: 'end',
							display: 'flex',
							justifyContent: 'flex-end',
							flexDirection: 'row',
							alignSelf: 'flex-end',
						}}
						onClick={() => {
							setIsModalOpen(false);
							setSelectedUser(undefined);
						}}>
						<CancelRounded />
					</Button>
				</ModalHeader>
				<ModalBody>
					<SingleUserAnswer
						form_uuid={params.form_uuid}
						user_identifier={selectedUser}
						formName={formName}
						fields={fields}
					/>
				</ModalBody>
			</Modal>

			<Modal
				size={'xl'}
				fullScreen={true}
				isOpen={singleFieldModalOpen}
				setIsOpen={(status: boolean) => {
					setSingleFieldModalOpen(false);
					setSelectedField(undefined);
				}}
				isStaticBackdrop={true}
				isCentered={true}>
				<ModalHeader>
					<Button
						style={{
							alignItems: 'end',
							display: 'flex',
							justifyContent: 'flex-end',
							flexDirection: 'row',
							alignSelf: 'flex-end',
						}}
						color={"dark"}
						onClick={() => {
							setSingleFieldModalOpen(false);
							setSelectedField(undefined);
						}}>
						<CancelRounded />
					</Button>
				</ModalHeader>
				<ModalBody>
					{selectedField && params.form_uuid && (
						<SingleFieldAnswers
							formName={formName}
							setSelectedAnswer={setSelectedField}
							selectedAnswer={selectedField}
							form_uuid={params.form_uuid}
							fields={fields}
							callback={() => setSingleFieldModalOpen(false)}
						/>
					)}
				</ModalBody>
			</Modal>
		</PageWrapper>
	);
}
