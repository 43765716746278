import React from 'react';
import { adminData, dashboardMenu, demoPages, layoutMenu } from '../menu';
import Footer from '../layout/Footer/Footer';

function getAdminDataRoutes() {
	let baseDataRoutes = [];
	for (let x in adminData) {
		// @ts-ignore
		baseDataRoutes.push({
			// @ts-ignore
			path: adminData[x].path,
			element: null,
			exact: true,
		});
	}
	return baseDataRoutes;
}

const footers = [
	{ path: layoutMenu.blank.path, element: null, exact: true },
	{ path: demoPages.login.path, element: null, exact: true },
	{ path: demoPages.page404.path, element: null, exact: true },
	{ path: dashboardMenu.dashboard.path, element: null, exact: true },
	{ path: '/answers/:id', element: null, exact: true },
	{ path: '/answers/:form_uuid/:user_identifier', element: null, exact: true },
	{ path: '*', element: <Footer /> },
].concat(getAdminDataRoutes());

export default footers;
