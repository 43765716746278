import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";

export const Privacy = () => {

    //const { i18n } = useTranslation(['translation', 'menu'])

    return (
        <div><h1>Data Privacy Statement</h1><h2>1. Data Privacy at a Glance</h2><h3>General Information</h3><p>The
            following information provides a simple overview of what happens to your personal data when you visit this
            website. Personal data refers to any data that can personally identify you. Detailed information on data
            privacy can be found in the data privacy statement listed below this text.</p><h3>Data Collection on this
            Website</h3><h4>Controller for Data Collection on this Website</h4><p>Data processing on this website is
            carried out by the website operator. You can find the contact details of the controller in the section
            "Information about the Controller" in this data privacy statement.</p><h4>How do we collect your data?</h4>
            <p>Your data is collected when you provide it to us. This can be, for example, data you enter in a contact
                form.</p><p>Other data is automatically collected by our IT systems or with your consent when you visit
                the website. This includes technical data (e.g., internet browser, operating system, or time of page
                access). The collection of this data occurs automatically as soon as you enter this website.</p><h4>What
                do we use your data for?</h4><p>Some of the data is collected to ensure the proper functioning of the
                website. Other data may be used to analyze user behavior.</p><h4>What rights do you have regarding your
                data?</h4><p>You have the right to obtain free information about the origin, recipient, and purpose of
                your stored personal data at any time. You also have the right to request the correction or deletion of
                this data. If you have given your consent to data processing, you can revoke this consent at any time
                for the future. Furthermore, under certain circumstances, you have the right to request the restriction
                of the processing of your personal data. Additionally, you have the right to lodge a complaint with the
                competent supervisory authority.</p><p>For this purpose and for any other questions regarding data
                privacy, you can contact us at any time.</p><h3>Analysis Tools and Third-Party Tools</h3><p>During your
                visit to this website, your surfing behavior may be statistically evaluated. This is mainly done using
                analysis programs.</p><p>Detailed information about these analysis programs can be found in the
                following data privacy statement.</p><h2>2. Hosting</h2><p>We host the contents of our website with the
                following provider:</p><h3>Strato</h3><p>Provider: Strato AG, Otto-Ostrowski-Straße 7, 10249 Berlin
                (hereinafter referred to as "Strato"). When you visit our website, Strato collects various log files,
                including your IP addresses.</p><p>For more information, please refer to Strato's data privacy
                statement: <a href="https://www.strato.de/datenschutz/" target="_blank"
                              rel="noopener noreferrer">https://www.strato.de/datenschutz/</a>.</p><p>The use of Strato
                is based on Art. 6(1)(f) GDPR. We have a legitimate interest in a reliable presentation of our website.
                If a corresponding consent has been requested (e.g., consent to store cookies or access information on
                the user's device), the processing will be carried out exclusively on the basis of Art. 6(1)(a) GDPR and
                § 25(1) TTDSG. Consent can be revoked at any time.</p><h4>Data Processing Agreement</h4><p>We have
                concluded a data processing agreement (DPA) with the above-mentioned service. This is a contract
                required by data protection law, which ensures that this service processes personal data of our website
                visitors only according to our instructions and in compliance with the GDPR.</p><h2>3. General
                Information and Mandatory Information</h2><h3>Data Privacy</h3><p>The operators of these pages take the
                protection of your personal data very seriously. We treat your personal data confidentially and in
                accordance with the statutory data protection regulations and this data privacy statement.</p><p>When
                you use this website, various personal data is collected. Personal data is data that can personally
                identify you. This data privacy statement explains what data we collect and what we use it for. It also
                explains how and for what purpose this is done.</p><p>We would like to point out that data transmission
                over the internet (e.g., communication by email) may have security vulnerabilities. Complete protection
                of data against access by third parties is not possible.</p><h3>Information about the Controller</h3>
            <p>The controller responsible for data processing on this website is:</p><p>KFM Digital Minds UG (limited
                liability)<br/>Mombacherstr. 68<br/>55122 Mainz</p><p>Phone: +49 176 7355 0100<br/>Email: kontakt@kfm-dm.de
            </p><p>The controller is the natural or legal person who alone or jointly with others decides on the
                purposes and means of processing personal data (e.g., names, email addresses, etc.).</p><h3>Storage
                Period</h3><p>Unless a specific storage period is mentioned within this data privacy statement, your
                personal data will remain with us until the purpose for data processing ceases to apply. If you make a
                legitimate request for deletion or revoke your consent to data processing, your data will be deleted
                unless we have other legally permissible reasons for storing your personal data (e.g., retention periods
                under tax or commercial law); in the latter case, the deletion will occur after these reasons cease to
                apply.</p><h3>General Information about the Legal Basis for Data Processing on this Website</h3><p>If
                you have given your consent to data processing, we process your personal data on the basis of Art.
                6(1)(a) GDPR or Art. 9(2)(a) GDPR if special categories of data are processed pursuant to Art. 9(1)
                GDPR. If you have given your consent to the transfer of personal data to third countries, the processing
                will also be based on Art. 49(1)(a) GDPR. If you have given your consent for the storage of cookies or
                the access to information on your terminal device (e.g., via device fingerprinting), the processing is
                additionally based on § 25(1) TTDSG. Consent can be revoked at any time.</p><p>If your data is necessary
                for the fulfillment of a contract or the implementation of pre-contractual measures, we process your
                data on the basis of Art. 6(1)(b) GDPR. Furthermore, we process your data if necessary to fulfill a
                legal obligation on the basis of Art. 6(1)(c) GDPR. The processing may also be based on our legitimate
                interest pursuant to Art. 6(1)(f) GDPR. In the following paragraphs of this data privacy statement, we
                will inform you of the specific legal basis for each processing operation.</p><h3>Note on Data Transfer
                to the USA and Other Third Countries</h3><p>We use tools from companies based in the USA or other
                countries outside the EU that may not have data protection regulations comparable to those in the EU.
                When these tools are active, your personal data may be transferred to and processed in these third
                countries. We would like to inform you that in these countries, a data protection level similar to that
                in the EU cannot be guaranteed. For instance, US companies are obligated to disclose personal data to
                government authorities without the possibility of you, as the data subject, taking legal action against
                it. Therefore, it cannot be excluded that US authorities (e.g., intelligence agencies) process, analyze,
                and permanently store your data located on US servers for surveillance purposes. We have no influence on
                these processing activities.</p><h3>Revocation of Your Consent to Data Processing</h3><p>Many data
                processing operations are only possible with your explicit consent. You can revoke a consent that you
                have already given at any time. The legality of data processing carried out before the revocation
                remains unaffected by the revocation.</p><h3>Right to Object to Data Collection in Special Cases and
                Direct Marketing (Art. 21 GDPR)</h3><p>If data processing is based on Art. 6(1)(e) or (f) GDPR, you have
                the right to object, on grounds relating to your particular situation, to the processing of your
                personal data at any time; this also applies to profiling based on these provisions. The specific legal
                basis for each processing operation is provided in this data privacy statement. If you object, we will
                no longer process your personal data unless we can demonstrate compelling legitimate grounds for the
                processing that override your interests, rights, and freedoms or for the establishment, exercise, or
                defense of legal claims (objection pursuant to Art. 21(1) GDPR).</p><p>If your personal data is
                processed for direct marketing purposes, you have the right to object at any time to the processing of
                your personal data for such marketing, which includes profiling to the extent that it is related to such
                direct marketing. If you object, your personal data will no longer be used for direct marketing purposes
                (objection pursuant to Art. 21(2) GDPR).</p><h3>Right to Lodge a Complaint with the Competent
                Supervisory Authority</h3><p>In the event of violations of the GDPR, data subjects have the right to
                lodge a complaint with a supervisory authority, especially in the EU Member State of their habitual
                residence, place of work, or the place of the alleged infringement. The right to lodge a complaint is
                without prejudice to any other administrative or judicial remedies.</p><h3>Right to Data
                Portability</h3><p>You have the right to have data, which we process automatically based on your consent
                or in fulfillment of a contract, handed over to you or to a third party in a commonly used,
                machine-readable format. If you request the direct transfer of the data to another controller, this will
                only be done to the extent technically feasible.</p><h3>Information, Correction, and Deletion</h3><p>You
                have the right to obtain free information about your stored personal data, their origin and recipients,
                and the purpose of data processing and, if necessary, a right to correct or delete this data. For this
                purpose and for any further questions regarding personal data, you can contact us at any time.</p>
            <h3>Right to Restrict Processing</h3><p>You have the right to request the restriction of the processing of
                your personal data. To do so, you can contact us at any time. The right to restrict processing exists in
                the following cases:</p>
            <ul>
                <li>If you contest the accuracy of your personal data stored by us, we will usually need time to verify
                    this. For the duration of the verification, you have the right to request the restriction of the
                    processing of your personal data.
                </li>
                <li>If the processing of your personal data was/is unlawful, but you do not want us to delete it, you
                    can request the restriction of the processing of your personal data instead.
                </li>
                <li>If we no longer need your personal data, but you need it for the exercise, defense, or assertion of
                    legal claims, you have the right to request the restriction of the processing of your personal data
                    instead.
                </li>
                <li>If you have objected pursuant to Art. 21(1) GDPR, your interests and ours must be balanced. As long
                    as it is not yet clear whose interests outweigh the others, you have the right to request the
                    restriction of the processing of your personal data.
                </li>
            </ul>
            <p>If you have restricted the processing of your personal data, such data—apart from being stored—may only
                be processed with your consent or for the establishment, exercise, or defense of legal claims or for the
                protection of the rights of another natural or legal person or for reasons of important public interest
                of the European Union or a Member State.</p><h3>SSL or TLS Encryption</h3><p>This site uses SSL or TLS
                encryption for security reasons and to protect the transmission of confidential content, such as orders
                or inquiries that you send to us as the site operator. You can recognize an encrypted connection in your
                browser's address line when it changes from "http://" to "https://" and the lock icon is displayed in
                your browser's address bar.</p><p>If SSL or TLS encryption is activated, the data you transfer to us
                cannot be read by third parties.</p><h3>Objection to Promotional Emails</h3><p>We hereby expressly
                prohibit the use of contact data published in the context of website legal notice requirements with
                regard to sending promotional and informational materials not expressly requested. The website operator
                reserves the right to take specific legal action if unsolicited advertising material, such as spam
                emails, is received.</p><h2>4. Plugins and Tools</h2><h3>Google Fonts</h3><p>This site uses so-called
                Google Fonts for the uniform display of fonts, provided by Google. When you visit a page, your browser
                loads the required fonts into your browser cache to display texts and fonts correctly.</p><p>To achieve
                this, your browser must establish a connection to Google's servers. As a result, Google becomes aware
                that our website has been accessed via your IP address. The use of Google Fonts is based on Art. 6(1)(f)
                GDPR. The website operator has a legitimate interest in a consistent presentation of the font on its
                website. If a corresponding consent has been requested (e.g., consent to the storage of cookies or
                device-based access), the processing is carried out exclusively on the basis of Art. 6(1)(a) GDPR and §
                25(1) TTDSG; consent can be revoked at any time.</p><p>If your browser does not support Google Fonts, a
                standard font will be used by your computer.</p><p>For more information about Google Fonts, please
                visit <a href="https://developers.google.com/fonts/faq" target="_blank"
                         rel="noopener noreferrer">https://developers.google.com/fonts/faq</a> and Google's privacy
                policy at <a href="https://policies.google.com/privacy?hl=de" target="_blank"
                             rel="noopener noreferrer">https://policies.google.com/privacy?hl=de</a>.</p><h3>Font
                Awesome</h3><p>This site uses Font Awesome for the unified display of fonts and symbols. The provider is
                Fonticons, Inc., 6 Porter Road Apartment 3R, Cambridge, Massachusetts, USA.</p><p>When you access a
                page, your browser loads the necessary fonts into its browser cache to correctly display texts, fonts,
                and symbols. For this purpose, the browser you are using must establish a connection to Font Awesome's
                servers. As a result, Font Awesome gains knowledge that this website has been accessed via your IP
                address. The use of Font Awesome is based on Art. 6 para. 1 lit. f GDPR. The website operator has a
                legitimate interest in the consistent presentation of the typeface on its website. If a corresponding
                consent has been requested (e.g., consent to the storage of cookies or the access to information in the
                user's device), the processing is carried out exclusively on the basis of Art. 6 para. 1 lit. a GDPR and
                § 25 para. 1 TTDSG (German Telemedia Act). Consent can be revoked at any time.</p><p>If your browser
                does not support Font Awesome, a standard font from your computer will be used.</p><p>For more
                information about Font Awesome, please refer to their privacy policy: <a
                    href="https://fontawesome.com/privacy" target="_blank"
                    rel="noopener noreferrer">https://fontawesome.com/privacy</a>.</p><p>Source: <a
                href="https://www.e-recht24.de">https://www.e-recht24.de</a></p></div>
    );
};

export default Privacy;