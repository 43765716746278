import React, {SetStateAction, useEffect, useState} from 'react';
import {IFields} from "../../../Interfaces/IGeneral";
import {IDataCreate} from "../../../../../models/data/data.module";
import "./EMultiSelectBoxFormField.scss"

interface IMultiselectFormProps {
    field: IFields,
    fieldIndex: number,
    data?: Array<IDataCreate>,
    setData?: React.Dispatch<SetStateAction<Array<IDataCreate[]> | null>>;
    pageNumber: number
}

const EMultiSelectBoxFormField = (props: IMultiselectFormProps) => {

    const [text, setText] = useState<string[]>([])

    useEffect(() => {
        if (props.data && props.data[props.fieldIndex] && props.data[props.fieldIndex].value !== undefined) {
            setText(JSON.parse(props.data[props.fieldIndex].value))
        }
    }, [])

    const textChangeHandler = (e: any) => {
        if (props.data && props.setData) {
            let changedValues = [...props.data]
            let newText : string[] = [...text]
            if(newText.includes(e.target.value)) {
               let elemIndex = newText.indexOf(e.target.value)
                newText.splice(elemIndex, 1)

            } else {
                newText.push(e.target.value)

            }
            setText(newText)
            changedValues[props.fieldIndex].value = JSON.stringify(newText)
            props.setData((prevState: any) => {
                let newState = [...prevState];
                newState[props.pageNumber] = changedValues;
                return newState;
            });
        }
    }

    return (
        <div className="ESelectBox">
            <div className="MPage__drop-target__field-label">
                {props.field.name}{props.field.required ? "*" : ""}
            </div>
            <div className="ESelectBox__inner">
                {props.field.valuesToBeSelected &&
                    JSON.parse(props.field.valuesToBeSelected).map(
                        (value: string, valueIndex: number, valueArr: string[]) => {
                            return (
                                <div key={value + valueIndex}
                                     className={"ESelectBox__inner__option " + (valueArr.length % 2 !== 0 && valueIndex == valueArr.length - 1 ? " span" : "") + (text.includes(value) ? " selected" : "")}
                                     onClick={()=> {
                                        textChangeHandler({target: {value: value}})
                                     }
                                     }
                                >
                                    {value}
                                </div>
                            );
                        },
                    )}
            </div>
        </div>
    )
};

export default EMultiSelectBoxFormField;