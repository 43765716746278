import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Label from './bootstrap/forms/Label';
import Button from './bootstrap/Button';
import { ArrowBackIos, Colorize, Save } from '@mui/icons-material';
import { GlobalStyleAttributes } from '../frontend/src/Utils/objects';
import Input from './bootstrap/forms/Input';
import {Form, StyleInfo} from '../models/forms/forms.module';
import { RGBColor, SketchPicker } from 'react-color';
import { Page } from '../models/pages/pages.module';
import { getComplementaryColor } from "../helpers/Form_helper";
import { useTranslation } from "react-i18next";
import DefaultFormTable from './DefaultFormTable';

interface IStyleFormProps {
	currentForm: Form | Page;
	setCurrentForm?: React.Dispatch<SetStateAction<Form | null>>;
	handleFormUpdate: (styleObj: { [key: string]: string }) => void;
	setFormEditMode: React.Dispatch<
		SetStateAction<'' | 'image' | 'style' | 'page-field' | 'page-style' | 'page-image' | 'page' | 'template'>
	>;
	formEditMode: '' | 'image' | 'style' | 'page-field' | 'page-style' | 'page-image' | 'page' | 'template';
	formStyles?: StyleInfo[]
}

const StyleForm = (props: IStyleFormProps) => {
	const [styleAttributes, setStyleAttributes] = useState<{ [key: string]: string } | null>(null);
	const [colorPickerOpen, setColorPickerOpen] = useState<string>('');

	const { t } = useTranslation(['translation', 'menu']);

	useEffect(() => {
		if (props.currentForm) {
			initValues();
		}
	}, [props.currentForm]);

	const initValues = () => {
		let tempStyleAttributes : {[key: string] : string} = {};
		if (props.currentForm) {
			if ('styles' in props.currentForm && props.currentForm.styles) {
				props.currentForm.styles.forEach((style, index) => {
					tempStyleAttributes = { ...tempStyleAttributes, [style.name]: style.value };
				});
			} else if ('page_styles' in props.currentForm && props.currentForm.page_styles) {
				props.currentForm.page_styles.forEach((style, index) => {
					tempStyleAttributes = { ...tempStyleAttributes, [style.name]: style.value };
				});
				if (props.formStyles && props.formStyles.length > 0) {
					props.formStyles.forEach((style, index) => {
						if(tempStyleAttributes[style.name] === undefined) {
							tempStyleAttributes = { ...tempStyleAttributes, [style.name]: style.value };
						}
					});
				}
			}
			setStyleAttributes(tempStyleAttributes);
		}
	};

	const handleColorChange = (color: RGBColor, attribute: string) => {

		let selectedColor = `rgba(${color.r},${color.g},${color.b},${color.a})`
		let currentStyles = { ...styleAttributes }
		let compColor = getComplementaryColor(color)

		document.documentElement.style.setProperty(`--${attribute}`, selectedColor);
		if (attribute === 'backgroundColor') {

			currentStyles.formTextColorContent = `rgba(${compColor.r},${compColor.g},${compColor.b},${compColor.a})`
			currentStyles.formTextColorHeadline = `rgba(${compColor.r},${compColor.g},${compColor.b},${compColor.a})`
			currentStyles.formInputColor = `rgba(${compColor.r},${compColor.g},${compColor.b},${compColor.a})`
			currentStyles.backgroundColor = selectedColor
			document.documentElement.style.setProperty(".MuiLinearProgress-colorPrimary", selectedColor)
			document.documentElement.style.setProperty('--formTextColorContent', `rgba(${compColor.r},${compColor.g},${compColor.b},${compColor.a})`);
			document.documentElement.style.setProperty('--formTextColorHeadline', `rgba(${compColor.r},${compColor.g},${compColor.b},${compColor.a})`)
			document.documentElement.style.setProperty('--formInputColor', `rgba(${compColor.r},${compColor.g},${compColor.b},${compColor.a})`)

			setStyleAttributes(currentStyles)

		} else if (attribute === 'primaryColor') {

			document.documentElement.style.setProperty(".MuiLinearProgress-barColorPrimary", selectedColor)
			setStyleAttributes((prevState: any) => ({ ...prevState, [attribute]: selectedColor }))

		} else if (attribute === 'buttonBackground') {

			currentStyles.buttonColor = `rgba(${compColor.r},${compColor.g},${compColor.b},${compColor.a})`
			currentStyles.buttonBackground = selectedColor
			document.documentElement.style.setProperty('--buttonColor', `rgba(${compColor.r},${compColor.g},${compColor.b},${compColor.a})`);

			setStyleAttributes(currentStyles)

		} else {
			setStyleAttributes((prevState: any) => ({ ...prevState, [attribute]: selectedColor }))
		}
	}
	const isForm = (formOrPage: Form | Page): formOrPage is Form => {
		return (formOrPage as Form).form_link !== undefined;
	};

	return (
		<>
			<Button
				color='dark'
				onClick={() => {
					props.setFormEditMode(props.formEditMode === 'page-style' ? 'page' : '')
				}}>
				<ArrowBackIos />
			</Button>
			{
				styleAttributes && GlobalStyleAttributes.map((attribute, elIndex) => {
					return (
						<>
							<div key={elIndex} style={{
								display: 'flex',
								flexDirection: 'column'
							}}>
								<div>
									<Label>{t(attribute)}</Label>
									<div style={{
										display: 'flex',
									}}>
										<Input placeholder={attribute}
													 onChange={(e: any) => {
														 setStyleAttributes((prevState: any) => ({ ...prevState, [attribute]: e.target.value }))
													 }}
													 value={styleAttributes[attribute] || ""}
										/>
										<Button
											color="light"
											onClick={() => {
												if (colorPickerOpen === "") {
													setColorPickerOpen(attribute)
												} else {
													setColorPickerOpen("")
												}
											}}
										>
											<Colorize />
										</Button>
									</div>
								</div>
								{
									colorPickerOpen === attribute && (
										<div
											style={{
												display: "flex",
												justifyContent: "center",
												margin: "1rem 0"
											}}
										>
											<SketchPicker
												color={styleAttributes[attribute] || ""}
												onChange={(color) => {
													handleColorChange(color.rgb, attribute);
												}
												}
											/>
										</div>
									)
								}
							</div>
							{
								elIndex === 2 && (
									<hr style={{margin: "2rem 0 0 0"}}/>
								)
							}
						</>
					)
				})
			}
			{props.setCurrentForm && isForm(props.currentForm) && (
				<DefaultFormTable
					setCurrentForm={props.setCurrentForm}
					currrentForm={props.currentForm}
				/>
			)}
			<Button
				color='dark'
				onClick={() => {
					if (styleAttributes && props.currentForm.uuid) {
						props.handleFormUpdate(styleAttributes)
					}
				}}
			>
				<Save />
			</Button>
		</>
	);
};

export default StyleForm;
