import React, { FC } from 'react';
import PropTypes from 'prop-types';

interface ILogoProps {
	width?: number;
	height?: number;
}
const Logo: FC<ILogoProps> = ({ width, height }) => {
	return (
		<svg
			width={height !== 1200 && !!height ? height * (2155 / 1200) : width}
			height={width !== 2155 && !!width ? width * (1200 / 2155) : height}
			fill='none'
			id='Ebene_4_Kopie'
			data-name='Ebene 4 Kopie'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 105.47 144.64'>
			<path
				fill='#363B63'
				d='M183.79,99.15q-1.26,6.18-2.51,12.36c2.49,6,2.37,15,0,25.64C177.6,153.26,170,169.24,162.47,179c-7.73,10-15.43,13.9-23.49,11.85s-15.72-8.64-23.41-15.24c-7.5-6.8-15-13.62-18.61-20.73S95.18,141,102.9,133c7.54-7.75,21-16.71,36.35-23.73a111,111,0,0,1,10.69-4.2l.24-4.14c-3.7,1.39-7.56,3-11.5,4.84-19.24,9.12-36,19.93-44.73,28.59-9,9-10.13,15.76-4.72,23.52s15.21,15.61,24,24.58c9.07,8.68,17.16,18.38,25.44,22.89s16.38,1.46,25.45-10.53c8.8-11.58,18.57-32.12,24-53.29C193.48,120.66,192.48,104.93,183.79,99.15Z'
				transform='translate(-85.95 -62.71)'
			/>
			<path
				fill='#FF7D3F'
				d='M138.67,159.66a10.23,10.23,0,0,1-7.63-2,8.78,8.78,0,0,1-3.83-6.35,9.89,9.89,0,0,1,2.78-7.64,17.38,17.38,0,0,1,8.3-5.27,11.29,11.29,0,0,1,5.49-.28q.42-12.49.85-25c-3.09,1.16-5.48,2.07-7.1,2.74-11.61,4.86-21.66,11.52-28.36,18.64s-8.21,12.84-5.84,19.07c2.45,6.4,7.84,11.88,14.63,17.49s13.4,9.81,20.92,10.67A17.93,17.93,0,0,0,150.36,179a36,36,0,0,0,10.84-10.81c5.67-8.32,10.38-20.09,12.94-33.23q6.95-36.12,13.91-72.22Q171.49,74,154.77,83.51q-2.22,29.3-4.46,58.58C149.18,155.46,143.38,159.13,138.67,159.66Z'
				transform='translate(-85.95 -62.71)'
			/>
		</svg>
	);
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
Logo.defaultProps = {
	width: 2155,
	height: 1200,
};

export default Logo;
