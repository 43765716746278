import {RGBColor} from "react-color";
import {StyleInfo} from "../models/forms/forms.module";
import {GlobalStyleAttributes, initialStyles} from "../frontend/src/Utils/objects";

export function getComplementaryColor(rgbColor : RGBColor) {

    const clamp = (value : any) => Math.min(255, Math.max(0, value));
    const clampedColor = {
        r: clamp(rgbColor.r),
        g: clamp(rgbColor.g),
        b: clamp(rgbColor.b),
        a: rgbColor.a
    };

    const compColor = {
        r: 255 - clampedColor.r,
        g: 255 - clampedColor.g,
        b: 255 - clampedColor.b,
        a: clampedColor.a
    };

    return compColor;
}

export function unifyArrays(arr1 : StyleInfo[], arr2: StyleInfo[]) {
    const combinedArray = arr1.concat(arr2.filter(obj2 => !arr1.find(obj1 => obj1.name === obj2.name)));

    const unifiedObject = combinedArray.reduce((result: StyleInfo[], obj) => {
        const existingObj = result.find((item : StyleInfo) => item.name === obj.name);

        if (!existingObj) {
            result.push(obj);
        } else {
            if (arr1.find(obj1 => obj1.name === obj.name)) {
                existingObj.value = obj.value;
            }
        }

        return result;
    }, []);

    return unifiedObject;
}

export const getDefaultStyleAttributes = () : Array<StyleInfo> => {
    let defaultAttributes: Array<{ name: string; value: string }> = [];
    GlobalStyleAttributes.forEach((attribute) => {
        defaultAttributes.push({
            name: attribute,
            value: getComputedStyle(document.documentElement).getPropertyValue(
                `--${attribute}`,
            ),
        });
    });
    return defaultAttributes;
}

export const resetToInitialStyles = () => {
    initialStyles.map((el) => {
        document.documentElement.style.setProperty(el.name, el.value)
    })
}

export const toRGBColor = (rgb: string) : RGBColor => {

    let lengthOfSubstring = 4
    if(rgb.includes('rgba')) {
        lengthOfSubstring = 5
    }
    let rgbAr : string[]= rgb.substring(lengthOfSubstring, rgb.length-1)
        .replace(/ /g, '')
        .split(',');

    let rgbC : RGBColor = {
        r: Number(rgbAr[0]),
        g: Number(rgbAr[1]),
        b: Number(rgbAr[2]),
        a: rgbAr.length === 4 ? Number(rgbAr[3]) : 1
    }

    return rgbC
}

export const rgbToString = (rgb: RGBColor) : string => {
    return `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`
}
