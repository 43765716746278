import React from 'react';
import { adminData, dashboardMenu, demoPages, layoutMenu } from '../menu';
import DashboardHeader from '../pages/common/Headers/DashboardHeader';
import DefaultHeader from '../pages/common/Headers/DefaultHeader';
import { useParams } from 'react-router-dom';

function getAdminDataRoutes() {
	let adminDataRoutes = [];

	for (let x in adminData as typeof adminData) {
		// @ts-ignore
		if (adminData[x].path) {
			// @ts-ignore
			adminDataRoutes.push({
				// @ts-ignore
				path: adminData[x].path,
				// @ts-ignore
				element: (
					<DashboardHeader
						breadcrumbs={[
							// @ts-ignore
							{ label: adminData[x].text, icon: adminData[x].icon, link: '#' },
						]}
					/>
				),
				exact: true,
			});
		}
	}
	return adminDataRoutes;
}

const DashboardHeaderWrapper = () => {
	const { formUuid } = useParams();

	const breadcrumbs = [
		{ label: 'Answers', icon: 'analytics', link: '/answers' },
		{ label: 'Detail', icon: 'Timeline', link: `/answers/${formUuid}` },
		{ label: 'User Answer', icon: 'person', link: '#' },
	];

	return <DashboardHeader breadcrumbs={breadcrumbs} />;
};
const headers = [
	{ path: layoutMenu.pageLayout.subMenu.onlySubheader.path, element: null, exact: true },
	{ path: layoutMenu.pageLayout.subMenu.onlyContent.path, element: null, exact: true },
	{ path: layoutMenu.blank.path, element: null, exact: true },
	{ path: demoPages.login.path, element: null, exact: true },
	{ path: demoPages.page404.path, element: null, exact: true },
	{ path: dashboardMenu.dashboard.path, element: <DashboardHeader />, exact: true },
	{
		path: `*`,
		element: <DashboardHeader />,
	},
	{
		path: '/answers/:id',
		element: (
			<DashboardHeader
				breadcrumbs={[
					{ label: 'Answers', icon: 'analytics', link: '/answers' },
					{ label: 'Detail', icon: 'Timeline', link: '#' },
				]}
			/>
		),
		exact: true,
	},
	{
		path: '/answers/:formUuid/:userIdentifier',
		element: <DashboardHeaderWrapper />,
	},
].concat(getAdminDataRoutes());

export default headers;
