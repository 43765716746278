import React from 'react';
import { IFields } from '../frontend/src/Interfaces/IGeneral';
import { useDrag } from 'react-dnd';
import {
	CheckBox,
	Checklist, EventAvailable,
	FileUpload,
	Filter3, GridViewRounded,
	HdrStrong,
	Reorder,
	Slideshow,
	TextFields,
	ToggleOn, ViewStream,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import "../styles/components/_draggable-item.scss"

export const DraggableItem = (props: IFields) => {
	const { t } = useTranslation(['translation', 'menu']);
	const [{ isDragging }, drag] = useDrag({
		type: 'DRAGGABLE_ITEM',
		item: props,
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	});

	const FieldIcon = (fieldInfo: IFields) => {
		switch (fieldInfo.dataType) {
			case 'TextField':
				return <TextFields />;
			case 'MultiSelect':
				return <Checklist />;
			case 'SingleSelect':
				return <Reorder />;
			case 'Switch':
				return <ToggleOn />;
			case 'Number':
				return <Filter3 />;
			case 'Slider':
				return <Slideshow />;
			case 'Range':
				return <HdrStrong />;
			case 'Checkbox':
				return <CheckBox />;
			case 'File':
				return <FileUpload />;
			case "Appointment":
				return <EventAvailable />;
			case "MultiSelectBox":
			case "SingleSelectBox":
				return <GridViewRounded />;
			case "Seperator":
				return <ViewStream />;
		}
	};

	return (
		<div
			className="DraggableItem"
			ref={drag}
			style={{
				opacity: isDragging ? 0.5 : 1,
			}}>
			{FieldIcon(props)} {'  '}
			{t(props.name)}
		</div>
	);
};
