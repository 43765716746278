import React, {SetStateAction} from 'react';
import {IFields} from "../../../Interfaces/IGeneral";
import {IDataCreate} from "../../../../../models/data/data.module";
import "./ESeperator.scss"

interface IESeperatorProps {
    field: IFields,
    fieldIndex: number,
    data?: Array<IDataCreate>,
    setData?: React.Dispatch<SetStateAction<Array<IDataCreate[]> | null>>;
    pageNumber: number
}

const ESeperator = (props: IESeperatorProps) => {

    return (
        <hr className="ESeperator" style={{borderTop: `1px ${JSON.parse(props.field.valuesToBeSelected).style} var(--buttonBackground)`}}/>
    );
};

export default ESeperator;