import React, { SetStateAction, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import 'moment/locale/de';
import './AppointmentPicker.scss';
import { IFields } from '../../frontend/src/Interfaces/IGeneral';
import { IDataCreate } from '../../models/data/data.module';
import {
	Dialog,
	DialogContent,
	Grid,
	List,
	ListItem,
	Box,
	Typography,
	TextField,
	Stack,
	IconButton,
} from '@mui/material';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
moment.locale('en');
interface IAppointmentPickerProps {
	field: IFields;
	fieldIndex: number;
	data?: Array<IDataCreate>;
	setData?: React.Dispatch<SetStateAction<Array<IDataCreate[]> | null>>;
	pageNumber: number;
}

interface CalOptions {
	numberOfAppointments?: string;
	schedule?: any;
	steps?: number;
	timespan?: {
		from: string;
		to: string;
	};
}

const AppointmentPicker = (props: IAppointmentPickerProps) => {
	const [calOptions, setCalOptions] = useState<CalOptions>({ schedule: {}, steps: 1 });
	const [open, setOpen] = useState(false);
	const [selectedDate, setSelectedDate] = useState<Moment | null>(null);
	const [selectedTime, setSelectedTime] = useState<string | null>(null);
	const [fullDate, setFullDate] = useState(moment().format());
	const [times, setTimes] = useState<string[]>([])

	useEffect(()=> {
		if (selectedDate) {
			setAvailableTimes(selectedDate)
		}
	},[selectedDate])

	useEffect(() => {
		if (props.field) {
			setCalOptions(JSON.parse(props.field.valuesToBeSelected));
		}
		if (
			props.data &&
			props.data[props.fieldIndex] &&
			props.data[props.fieldIndex].value !== undefined &&
			props.data[props.fieldIndex].value !== ''
		) {
			setSelectedDate(moment(props.data[props.fieldIndex].value));
			setSelectedTime(moment(props.data[props.fieldIndex].value).format('HH:mm'));
		}
	}, [props.field, props.data]);

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const datePickHandler = () => {
		if (selectedDate && selectedTime) {
			if (props.data && props.setData) {
				const hours = parseInt(selectedTime.split(':')[0], 10);
				const minutes = parseInt(selectedTime.split(':')[1], 10);

				const dateToSet = selectedDate
					.clone()
					.utcOffset(0, true)
					.set({
						hour: hours,
						minute: minutes,
						second: 0,
						millisecond: 0,
					});

				let changedValues = [...props.data];
				changedValues[props.fieldIndex].value = dateToSet.toISOString();

				props.setData((prevState) => {
					let newState = prevState ? [...prevState] : [];
					newState[props.pageNumber] = changedValues;
					return newState;
				});

				setSelectedDate(moment(dateToSet))

				setFullDate(dateToSet.utcOffset(2, true).format());
				handleClose();
			}
		}
	};

	const handleDateChange = (date: Moment | null) => {
		if (date) {
			setSelectedDate(date);
			setSelectedTime(null);
		}
	};

	const handleTimeSelect = (time: string) => {
		let tempDate = selectedDate
		const newtime = moment(tempDate).set({
			hour: parseInt(time.split(':')[0]),
			minute:parseInt(time.split(':')[1]),
			second: 0,
			millisecond: 0,
		});
		setSelectedTime(time);
	};

	const disableDate = (date: Moment): boolean => {
		const dayOfWeek = date.format('dddd');
		if (calOptions.schedule && calOptions.schedule[dayOfWeek] === undefined) {
			return true;
		}

		const timesAvailable = getAvailableTimes(date);
		return timesAvailable.length === 0;
	};

	const getAvailableTimes = (date: Moment): string[] => {
		const dayOfWeek = date.format('dddd');

		if (calOptions.schedule && calOptions.schedule[dayOfWeek]) {
			const from = moment(date).set({
				hour: moment(calOptions.schedule[dayOfWeek].from, 'HH:mm').hour(),
				minute: moment(calOptions.schedule[dayOfWeek].from, 'HH:mm').minute(),
				second: 0,
				millisecond: 0,
			});
			const to = moment(date).set({
				hour: moment(calOptions.schedule[dayOfWeek].to, 'HH:mm').hour(),
				minute: moment(calOptions.schedule[dayOfWeek].to, 'HH:mm').minute(),
				second: 0,
				millisecond: 0,
			});

			const now = moment();
			let tempTimes = [];
			let currentTime = from.clone();

			while (currentTime <= to) {
				if (
					currentTime.isAfter(now) &&
					!isTimeBooked(currentTime.format('YYYY-MM-DD'), currentTime.format('HH:mm'))
				) {
					tempTimes.push(currentTime.format('HH:mm'));
				}
				currentTime.add(calOptions.steps || 30, 'minutes');
			}
			return tempTimes;
		}
		return [];
	};

	const isTimeBooked = (dateString: string, timeString: string) => {
		let dateTimeKey = moment
			.utc(dateString + ' ' + timeString, 'YYYY-MM-DD HH:mm')
			.toISOString();

		let values = props.field.values ? JSON.parse(props.field.values) : {};
		return values[dateTimeKey] === 0;
	};

	const setAvailableTimes = (date: Moment) => {
		setTimes(getAvailableTimes(date))
	}

	function setOpacityToColor(colorValue: any, opacity: number) {
		// Regular expressions to match color values in different formats
		const rgbRegex = /rgb\((\d+),\s*(\d+),\s*(\d+)\)/;
		const rgbaRegex = /rgba\((\d+),\s*(\d+),\s*(\d+),\s*(\d+(\.\d+)?)\)/;
		const hexRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;

		let match =
			colorValue.match(rgbRegex) || colorValue.match(rgbaRegex) || colorValue.match(hexRegex);

		if (match) {
			if (match[1] !== undefined && match[2] !== undefined && match[3] !== undefined) {
				const r = parseInt(match[1]);
				const g = parseInt(match[2]);
				const b = parseInt(match[3]);
				const a = match[4] !== undefined ? parseFloat(match[4]) : 1;

				return `rgba(${r}, ${g}, ${b}, ${a * opacity})`;
			} else if (match[1] !== undefined && match[2] !== undefined && match[3] !== undefined) {
				const r = parseInt(match[1], 16);
				const g = parseInt(match[2], 16);
				const b = parseInt(match[3], 16);

				return `rgba(${r}, ${g}, ${b}, ${opacity})`;
			}
		}
		return colorValue;
	}

	const CustomCalendarHeader = (headerProps: any) => {
		const today = moment();
		const { currentMonth, onMonthChange } = headerProps;

		const selectNextMonth = () => {
			let newMonth = currentMonth.clone().add(1, 'month');
			onMonthChange(newMonth, 'left');
		};
		const selectPreviousMonth = () => {
			let newMonth = currentMonth.clone().subtract(1, 'month');
			onMonthChange(newMonth, 'right');
		};

		const isPrevMonthDisabled = currentMonth
			.clone()
			.subtract(1, 'month')
			.isBefore(today, 'month');

		return (
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					padding: '8px 16px',
					alignItems: 'center',
				}}>
				<Stack spacing={1} direction='row'>
					<IconButton
						onClick={selectPreviousMonth}
						title='Previous month'
						disabled={isPrevMonthDisabled}>
						<ArrowBackIosNewIcon />
					</IconButton>
				</Stack>
				<Typography className='MuiPickersCalendarHeader-labelContainer' variant='body2'>
					{currentMonth.format('MMMM YYYY')}
				</Typography>
				<Stack spacing={1} direction='row'>
					<IconButton
						onClick={selectNextMonth}
						title='Next month'
					>
						<ArrowForwardIosIcon />
					</IconButton>
				</Stack>
			</div>
		);
	};

/*	const AppointmentSX : any = {
		backgroundColor: getComputedStyle(
			document.documentElement,
		).getPropertyValue('--backgroundColor'),
		'.MuiPickersCalendarHeader-labelContainer': {
			fontSize: 16,
			fontWeight: 400,
			color: getComputedStyle(
				document.documentElement,
			).getPropertyValue('--buttonColor'),
		},
		'.MuiDayCalendar-weekDayLabel': {
			color: getComputedStyle(
				document.documentElement,
			).getPropertyValue('--buttonColor'),
			fontSize: 12,
		},
		'.MuiPickersDay-root': {
			fontSize: 16,
			color: getComputedStyle(
				document.documentElement,
			).getPropertyValue('--buttonColor'),
			border: 'none',
			fontWeight: 'bold',
		},
		'.MuiPickersDay-root.Mui-selected': {
			backgroundColor: getComputedStyle(
				document.documentElement,
			).getPropertyValue('--primaryColor'),
			color: getComputedStyle(
				document.documentElement,
			).getPropertyValue('--buttonColor'),
			fontWeight: 'bolder',
		},
		'.MuiPickersDay-root.Mui-disabled': {
			backgroundColor: getComputedStyle(
				document.documentElement,
			).getPropertyValue('--backgroundColor'),
			color: getComputedStyle(
				document.documentElement,
			).getPropertyValue('--buttonColor'),
			fontWeight: 300,
		},
		'MuiPickersCalendarHeader-labelContainer': {

		}
	}*/

	return (
		<div className='MyDatePicker'>
			<TextField
				variant='outlined'
				value={moment(fullDate).format('DD.MM.YYYY HH:mm')}
				onClick={handleOpen}
				InputLabelProps={{
					shrink: true,
				}}
			/>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth={'xl'}
				aria-labelledby='form-dialog-title'
				sx={{
					'.MuiDialogContent-root': {
						backgroundColor: getComputedStyle(
							document.documentElement,
						).getPropertyValue('--backgroundColor'),
					},
				}}>
				<DialogContent>
					<Grid container spacing={6}>
						<Grid item xs={12} sm={7}>
								<StaticDatePicker
									className="AppointmentPicker"
									displayStaticWrapperAs='desktop'
									openTo='day'
									value={selectedDate}
									onChange={handleDateChange}
									shouldDisableDate={disableDate}
									slots={{ calendarHeader: CustomCalendarHeader }}
									views={['day']}
									minDate={
										calOptions.timespan
											? moment(calOptions.timespan.from)
											: undefined
									}
									maxDate={
										calOptions.timespan
											? moment(calOptions.timespan.to)
											: undefined
									}
									disablePast
								/>
						</Grid>
						{selectedDate && (
							<Grid item xs={12} sm={5}>
								<Typography
									sx={{
										color: getComputedStyle(
											document.documentElement,
										).getPropertyValue('--formTextColorHeadline'),
									}}>
									{selectedDate?.format('dddd, D MMMM')}
								</Typography>
								<Box sx={{ overflowY: 'auto', maxHeight: 300 }}>
									<List>
										{times.map((time, index) => (
											<ListItem
												key={index}
												sx={{
													paddingY: 0,
													display: 'flex',
													flexDirection: 'row',
													mb: 1,
													overflow: 'hidden',
													gap: 1,
													boxSizing: 'border-box',
													width: selectedTime === time ? '96%' : '100%',
												}}>
												<div
													onClick={() => handleTimeSelect(time)}
													style={{
														borderRadius: 4,
														flex:
															selectedTime === time
																? '1 1 50%'
																: '1 1 100%',
														transition:
															'flex 0.3s ease-out, background-color 0.3s ease-out',
														border: '1px solid',
														borderColor:
															selectedTime === time
																? 'rgba(0,0,0,0.1)'
																: getComputedStyle(
																		document.documentElement,
																  ).getPropertyValue(
																		'--primaryColor',
																  ),
														textAlign: 'center',
														padding: 15,
														color: getComputedStyle(
															document.documentElement,
														).getPropertyValue('--primaryColor'),
														backgroundColor: '',
														cursor: 'pointer',
														userSelect: 'none',
														height: '50px',
														lineHeight: '20px',
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
														fontWeight: '600',
													}}>
													{time}
												</div>
												<div
													onClick={datePickHandler}
													style={{
														cursor: 'pointer',
														flex:
															selectedTime === time
																? '1 1 50%'
																: '0 0 0px',
														transition:
															'flex 0.3s ease-out, opacity 0.3s ease-out, transform 0.3s ease-out',
														transform:
															selectedTime === time
																? 'scaleX(1)'
																: 'scaleX(0.01)',
														borderRadius: 4,
														opacity: selectedTime === time ? 1 : 0,
														backgroundColor: getComputedStyle(
															document.documentElement,
														).getPropertyValue('--buttonBackground'),
														color: getComputedStyle(
															document.documentElement,
														).getPropertyValue('--buttonColor'),
														textAlign: 'center',
														padding: selectedTime === time ? 15 : 0,
														height: '50px',
														overflow: 'hidden',
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
														whiteSpace: 'nowrap',
													}}>
													{selectedTime === time ? 'Weiter' : ''}
												</div>
											</ListItem>
										))}
									</List>
								</Box>
							</Grid>
						)}
					</Grid>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default AppointmentPicker;
