import React, {SetStateAction, useEffect, useState, useTransition} from 'react';
import {IFields} from "../../../Interfaces/IGeneral";
import {IDataCreate} from "../../../../../models/data/data.module";
import {Slider, TextField, Typography} from "@mui/material";

interface ISliderFormProps {
    field: IFields,
    fieldIndex: number,
    data?: Array<IDataCreate>,
    setData?: React.Dispatch<SetStateAction<Array<IDataCreate[]> | null>>;
    pageNumber: number
}

const ESliderForm = (props: ISliderFormProps) => {

    const [text, setText] = useState<number>(0)
    const [isPending, setTransition] = useTransition()

    useEffect(() => {
        if (props.data && props.data[props.fieldIndex] && props.data[props.fieldIndex].value !== undefined) {
            setText(Number(props.data[props.fieldIndex].value))
        }
    }, [])

    const textChangeHandler = (e: any) => {

        if (props.data && props.setData) {
            let changedValues = [...props.data]
            changedValues[props.fieldIndex].value = e.target.value
            setText(Number(e.target.value))
            setTransition(() => {
                if (props.setData)
                    props.setData((prevState: any) => {
                        let newState = [...prevState];
                        newState[props.pageNumber] = changedValues;
                        return newState;
                    });
            })
        }
    }

    const InputLabelPropsStyles: React.CSSProperties = {
        color: getComputedStyle(document.documentElement).getPropertyValue('--formInputColor'),
        fontSize: '18px',
        padding: '12px 0 8px 0',
    };

    return (
        <div className='MPage__form__slider'>
            <Typography
                className='MPage__form__typography'
                style={{ fontSize: InputLabelPropsStyles.fontSize }}>
                {props.field.name + (props.field.required ? ' *' : '')}
            </Typography>
            <div className='MPage__form__slider__wrapper'>
                <Slider
                    min={Number(JSON.parse(props.field.valuesToBeSelected).min)}
                    max={Number(JSON.parse(props.field.valuesToBeSelected).max)}
                    step={Number(JSON.parse(props.field.valuesToBeSelected).steps)}
                    onChange={(e) => {
                        textChangeHandler(e)
                    }}
                    valueLabelDisplay='auto'
                    value={text}
                />
            </div>
        </div>
    );
};

export default ESliderForm;