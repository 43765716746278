import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ThemeProvider } from 'react-jss';
import { useFullscreen } from 'react-use';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import ThemeContext from '../contexts/themeContext';
import Aside from '../layout/Aside/Aside';
import Wrapper from '../layout/Wrapper/Wrapper';
import { demoPages, layoutMenu } from '../menu';
import useDarkMode from '../hooks/useDarkMode';
import COLORS from '../common/data/enumColors';
import { checkBaseURL, getOS } from '../helpers/helpers';
import axios from 'axios';
import Icon from '../components/icon/Icon';
import { useTranslation } from 'react-i18next';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import AuthContext from '../contexts/authContext';
import '../frontend/src/index.scss';
import toast from 'react-hot-toast';

const App = () => {
	const authcontext = useContext(AuthContext);

	getOS();

	/**
	 * Dark Mode
	 */
	const { t } = useTranslation(['translation', 'menu']);
	const l = useLocation();
	const navigate = useNavigate();
	const { themeStatus, darkModeStatus } = useDarkMode();
	const theme = {
		theme: themeStatus,
		primary: COLORS.PRIMARY.code,
		secondary: COLORS.SECONDARY.code,
		success: COLORS.SUCCESS.code,
		info: COLORS.INFO.code,
		warning: COLORS.WARNING.code,
		danger: COLORS.DANGER.code,
		dark: COLORS.DARK.code,
		light: COLORS.LIGHT.code,
	};
	useEffect(() => {
		if (darkModeStatus) {
			document.documentElement.setAttribute('theme', 'dark');
		}
		return () => {
			document.documentElement.removeAttribute('theme');
		};
	}, [darkModeStatus]);

	useEffect(() => {
		if (authcontext.token === '') {
			if (l.pathname.includes('verify') || l.pathname.includes('process') || l.pathname.includes('imprint') || l.pathname.includes('privacy') ) {
				navigate(l.pathname);
			} else {
			 	navigate('/auth-pages/login');
			}
		}
	}, [authcontext]);

	useEffect(() => {
		if (axios.defaults.baseURL === undefined) {
			checkBaseURL();

			axios.interceptors.response.use(
				function (response) {
					return response;
				},
				function (error) {
					try {
						if (window?.location?.pathname !== '/auth-pages/login') {
							if (JSON.parse(JSON.stringify(error))?.status === 403) {
								toast.error(
									<span className='d-flex align-items-center'>
										<Icon icon={'check'} size='lg' className='me-1' />
										<span>{t(`sessionNotValidAnymore`)}</span>
									</span>,
								);
								navigate('/auth-pages/login');
								localStorage.clear();
							}
						}
					} catch (e) {
						return Promise.reject(error);
					}
					return Promise.reject(error);
				},
			);
		}
	}, [navigate, t]);

	/**
	 * Full Screen
	 */
	// @ts-ignore
	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const ref = useRef(null);
	useFullscreen(ref, fullScreenStatus, {
		onClose: () => setFullScreenStatus(false),
	});

	/**
	 * Modern Design
	 */
	useLayoutEffect(() => {
		if (process.env.REACT_APP_MODERN_DESGIN === 'true') {
			document.body.classList.add('modern-design');
		} else {
			document.body.classList.remove('modern-design');
		}
	});

	//	Add paths to the array that you don't want to be "Aside".
	const withOutAsidePages = [
		demoPages.login.path,
		layoutMenu.blank.path,
		'/process/:id',
		'/auth-pages/verify/:token',
		'/imprint',
		'/privacy'
	];

	return (
		<DndProvider backend={HTML5Backend}>
			<ThemeProvider theme={theme}>
				<div
					ref={ref}
					className='app'
					style={{
						backgroundColor: fullScreenStatus ? 'var(--bs-body-bg)' : undefined,
						zIndex: fullScreenStatus ? 1 : undefined,
						overflow: fullScreenStatus ? 'scroll' : undefined,
					}}>
					<Routes>
						{withOutAsidePages.map((path) => {
							return <Route key={path} path={path} element={<></>} />;
						})}
						<Route path='*' element={<Aside />} />
					</Routes>
					<Wrapper />
				</div>
			</ThemeProvider>
		</DndProvider>
	);
};

export default App;
