import React, {SetStateAction, useEffect, useState} from 'react';
import Card, {CardBody, CardHeader, CardTitle} from "../bootstrap/Card";
import {useTranslation} from "react-i18next";
import "./ImageGallery.scss"
import {getImageGallery, getImagePath, updateFormBGImage, updateLogo} from "../../models/forms/forms.repository";
import {AxiosError} from "axios";
import {Form} from "../../models/forms/forms.module";
import Icon from "../icon/Icon";
import {IPages} from "../../frontend/src/Interfaces/IPages";
import toast from "react-hot-toast";
import {updatePageBGImage} from "../../models/pages/pages.repository";
import {API_BASE_PATH} from "../../API/API-routes";

interface IImageGallery {
    formObject: 'Form' | 'Page' | 'Logo';
    getAllForms: () => void;
    setCurrentForm: React.Dispatch<SetStateAction<Form | null>>
    currentBG: string | null | undefined;
    currentForm: Form | IPages;
}

export const ImageGallery = (props: IImageGallery) => {

    const {t} = useTranslation((['translation']))

    const [images, setImages] = useState<{ bg_image: string, id: number }[]>([])
    const [selectedImage, setSelectedImage] = useState<string>("")

    useEffect(()=>{
        getImageGallery()
            .then((res)=> {
                if(res !== null && !(res instanceof AxiosError)) {
                    setImages(res)
                }
            })
    },[])

    useEffect(()=> {
        if (selectedImage === "" && props.currentBG) {
            setSelectedImage(props.currentBG)
        }
    },[selectedImage])

    const updateBGImage = (image_id: number) => {
        if(props.currentForm.uuid) {
            const toastId = toast.loading(t('updatingImage'))
            switch (props.formObject) {
                case 'Form':
                    updateFormBGImage(props.currentForm.uuid, image_id)
                    .then((res: any) => {
                        if (!(res instanceof AxiosError)) {
                            toast.dismiss(toastId)
                            toast.success(t('updatedImage'))
                            props.setCurrentForm(res)
                            props.getAllForms()

                        } else {
                            toast.dismiss(toastId)
                            toast.error(t("failedToUpdate"))
                        }
                    });
                    break;
                case "Page":
                    updatePageBGImage(props.currentForm.uuid, image_id)
                        .then((res: any) => {
                            if (!(res instanceof AxiosError)) {
                                toast.dismiss(toastId)
                                toast.success(t('updatedImage'))
                                props.setCurrentForm(res)
                                props.getAllForms()

                            } else {
                                toast.dismiss(toastId)
                                toast.error(t("failedToUpdate"))
                            }
                        })
                    break;
                case "Logo":
                    updateLogo(props.currentForm.uuid, image_id)
                        .then((res: any) => {
                            if (!(res instanceof AxiosError)) {
                                toast.dismiss(toastId)
                                toast.success(t('updatedImage'))
                                props.setCurrentForm(res)
                                props.getAllForms()
                            } else {
                                toast.dismiss(toastId)
                                toast.error(t("failedToUpdate"))
                            }
                        })
                    break;
            }
        }

    }

    return (
        <div className="ImageGallery">
            <Card>
                <CardHeader>
                    <CardTitle>{t('uploadedImages')}</CardTitle>
                </CardHeader>
                <CardBody>
                    <div className="ImageGallery__gallery">
                        {
                            images.length > 0 && images.map((el, elIndex)=> {
                                const imgpath = el.bg_image
                                return (
                                    <div key={elIndex + '-' + elIndex}
                                         className={"ImageGallery__gallery__img-wrapper " + (el.bg_image === props.currentBG ? " selected" : "")}
                                         onClick={()=>updateBGImage(el.id)}
                                    >
                                        <img key={elIndex + '-' + elIndex} src={API_BASE_PATH + imgpath}/>
                                        {
                                            el.bg_image === props.currentBG && (
                                                <div className="ImageGallery__gallery__img-wrapper__check">
                                                    <Icon icon={"Check"}/>
                                                </div>
                                            )
                                        }
                                    </div>
                                )
                            })
                        }
                        {
                            images.length === 0 && (
                                <div>{t('noImagesAvailable')}</div>
                            )
                        }
                    </div>
                </CardBody>
            </Card>
        </div>

    );
};

export default ImageGallery;