import React, { SetStateAction } from 'react';
import '../styles/components/_DefaultFormTable.scss';
import { emptyForm, Form } from '../models/forms/forms.module';
import Card from './bootstrap/Card';

interface IDefaultFormTableProps {
	currrentForm: Form;
	setCurrentForm: React.Dispatch<SetStateAction<Form | null>>;
}

const DefaultFormTable = (props: IDefaultFormTableProps) => {
	const defaultTempates: Array<Form> = [
		{
			...props.currrentForm,
			styles: [
				{ name: 'primaryColor', value: 'rgba(0,255,255,1)' },
				{ name: 'backgroundColor', value: 'rgba(0,0,0,1)' },
				{ name: 'buttonColor', value: 'rgb(0,0,0)' },
				{ name: 'buttonBackground', value: 'rgba(0,255,255,1)' },
			],
		},
		{
			...props.currrentForm,
			styles: [
				{ name: 'primaryColor', value: 'rgb(235,237,0)' },
				{ name: 'backgroundColor', value: 'rgb(0,0,0)' },
				{ name: 'buttonColor', value: 'rgb(0,0,0)' },
				{ name: 'buttonBackground', value: 'rgb(235,237,0)' },
			],
		},
		{
			...props.currrentForm,
			styles: [
				{ name: 'primaryColor', value: 'rgb(0,101,125)' },
				{ name: 'backgroundColor', value: 'rgb(175,175,175)' },
				{ name: 'buttonColor', value: 'rgb(175,175,175)' },
				{ name: 'buttonBackground', value: 'rgb(0,101,125)' },
			],
		},
	];

	return (
		<div className='DefaultFormTable'>
			{defaultTempates.map((form, formIndex) => {
				return (
					<Card
						className='DefaultFormTable__button'
						key={formIndex}
						onClick={() => {
							props.setCurrentForm((prevstate: any) => form);
						}}>
						{form.styles &&
							form.styles?.map((style, styleindex) => {
								return (
									<div
										className='DefaultFormTable__button__color'
										style={{
											background: style.value,
										}}
										key={styleindex}></div>
								);
							})}
					</Card>
				);
			})}
		</div>
	);
};

export default DefaultFormTable;
