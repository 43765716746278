import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionDetails, AccordionSummary, Radio } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useDarkMode from '../hooks/useDarkMode';
import { getFormTemplatesData } from '../models/forms/forms.repository';
import { API_BASE_PATH, FRONTEND_BASE_PATH } from '../API/API-routes';
import { Form } from '../models/forms/forms.module';
import Button from './bootstrap/Button';
import Label from './bootstrap/forms/Label';

interface ITemplate {
  id: string;
  company_id: string;
  form_id: string;
  name: string;
  preview_url: string;
  created_at: string;
}

interface ITemplateSelectorProps<T> {
  setForm: React.Dispatch<React.SetStateAction<Form | null>>;
  form: T;
}
// For style changes pleas refer to src\styles\components\extra\image_caption.scss
function TemplateSelector<T extends { uuid?: string; selected_template_id?: string }>({ setForm, form }: ITemplateSelectorProps<T>): JSX.Element {
  const { t } = useTranslation(['translation', 'menu']);
  const [templates, setTemplates] = useState<ITemplate[]>([]);
  const { themeStatus } = useDarkMode();

  useEffect(() => {
    getFormTemplatesData().then(setTemplates);
  }, []);

  const handleChange = (selectedTemplateId: string) => {
    setForm((prevState: any) => ({
      ...prevState,
      selected_template_id: selectedTemplateId,
    }));
  };

  const handleClearSelection = () => {
    setForm((prevState: any) => ({
      ...prevState,
      selected_template_id: undefined,
    }));
  };

  return (
		<div>
			<Accordion
				sx={
					themeStatus === 'dark'
						? { backgroundColor: '#343a40', color: 'white' }
						: {
								borderColor: '#343a40',
								border: 1,
								marginLeft: '5px',
								marginRight: '5px',
						  }
				}>
				<AccordionSummary
					id='collapse-header-form-template'
					expandIcon={<ExpandMoreIcon />}>
					<Label
						style={themeStatus === 'dark' ? { color: 'white' } : { color: '#343a40' }}>
						{t('copy-from-template')}
					</Label>
					{form.selected_template_id && (
						<Button
							icon='Trash'
							style={
								themeStatus === 'dark' ? { color: 'white' } : { color: '#343a40' }
							}
							isOutline={true}
							onClick={handleClearSelection}
							type='reset'
						/>
					)}
				</AccordionSummary>
				<AccordionDetails id='template-grid'>
					{templates.map((template) => (
						<div className='template-container' key={template.id}>
							<figure className='form-template figure'>
								<img
									src={`${API_BASE_PATH}/uploads/${template.company_id}/forms/${template.form_id}/templates/${template.preview_url}`}
									alt={template.name}
									className='figure-img'
								/>
								<figcaption className='figure-caption form-template-name'>
									<Button
										icon='Eye'
										tag='a'
										href={`${FRONTEND_BASE_PATH}/process/${template.form_id}`}
										target='_blank'
										key={'visit-1'}>
										{t('visit')}
									</Button>
								</figcaption>
							</figure>
							<div className='template-title'>
								<Radio
									id={`template-${template.form_id}`}
									checked={form.selected_template_id === template.form_id}
									onChange={() => handleChange(template.form_id)}
									value={template.id}
									name='template-selection'
									inputProps={{ 'aria-label': `template-${template.form_id}` }}
								/>
								<label htmlFor={`template-${template.form_id}`}>
									{template.name}
								</label>
							</div>
						</div>
					))}
				</AccordionDetails>
			</Accordion>
		</div>
  );
}

export default TemplateSelector;
