export const API_BASE_PATH =
    process.env.NODE_ENV === 'production'
        ? 'https://backend.processify.eu'
        : 'http://localhost:3001';
export const FRONTEND_BASE_PATH =
    process.env.NODE_ENV === 'production' ? 'https://processify.eu' : 'http://localhost:3000';

export const AUTH = API_BASE_PATH + '/auth';
export const PAGES = API_BASE_PATH + '/pages';
export const FORMS = API_BASE_PATH + '/forms';
export const COMPANIES = API_BASE_PATH + '/companies';
export const USERS = API_BASE_PATH + '/users';
export const ROLES = API_BASE_PATH + '/roles';
export const PERMISSIONS = API_BASE_PATH + '/permissions';
