import React, {SetStateAction, useEffect, useMemo, useState} from 'react';
import {IFields} from "../../../Interfaces/IGeneral";
import {IDataCreate} from "../../../../../models/data/data.module";
import {Checkbox, FormControl, FormControlLabel, Switch} from "@mui/material";

interface ISwitchFormFieldProps {
    field: IFields,
    fieldIndex: number,
    data?: Array<IDataCreate>,
    setData?: React.Dispatch<SetStateAction<Array<IDataCreate[]> | null>>;
    pageNumber: number
}

const ESwitchFormField = (props: ISwitchFormFieldProps) => {

    const [checked, setChecked] = useState<boolean | null>(null)

    useEffect(() => {
        if (props.data && props.data[props.fieldIndex] && props.data[props.fieldIndex].value !== undefined) {
            setChecked(props.data[props.fieldIndex].value === "true")
        } else {
            setChecked(false)
        }
    }, [])

    const stateChangeHanlder = (e: any) => {
        if (props.data && props.setData) {
            let changedValues = [...props.data]
            changedValues[props.fieldIndex].value = e.target.value + ""
            setChecked(e.target.value)
            props.setData((prevState: any) => {
                let newState = [...prevState];
                newState[props.pageNumber] = changedValues;
                return newState;
            });
        }
    }

    return (
        <FormControl>
            {
                checked !== null && (
                    <FormControlLabel
                        className='MPage__form__formcontrol-label'
                        style={{ margin: '1rem 0' }}
                        control={
                            <>
                                {
                                    (props.field.dataType === "Switch") ? (
                                        <Switch
                                            onChange={(e) => {
                                                stateChangeHanlder({ target: { value: e.target.checked } },);
                                            }}
                                            required={props.field.required}
                                            checked={checked}
                                        />
                                    ): (
                                        <Checkbox
                                            onChange={(e) => {
                                                stateChangeHanlder({ target: { value: e.target.checked } },);
                                            }}
                                            required={props.field.required}
                                            checked={checked}
                                        />
                                    )
                                }
                            </>
                        }
                        value={checked}
                        label={props.field.name + (props.field.required ? ' *' : '')}
                    />
                )
            }

        </FormControl>
    );
};

export default ESwitchFormField;