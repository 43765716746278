import {AxiosError} from "axios";

export const isAxiosError = (res: any) : boolean => {
    return res instanceof AxiosError
}

export const getFlowEdges = () : any[] => {
    let edges = localStorage.getItem("edges")
    if (edges) return JSON.parse(edges)
    else return []
}

export const saveFlowNodes = (nodes: any[]) => {
    localStorage.removeItem("nodes")
    localStorage.setItem("nodes", JSON.stringify(nodes))
}


export const saveFlowEdges = (edges: any[]) => {
    localStorage.removeItem("edges")
    localStorage.setItem("edges", JSON.stringify(edges))
}

