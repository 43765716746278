import {IDataCreate} from "../../../models/data/data.module";
import {IFields} from "../Interfaces/IGeneral";

export const renderByDependencyOperator = (field: IFields, dependentData: IDataCreate): boolean => {
    if (field.dep_info !== null && typeof field.dep_info !== 'string') {
        switch (field.dep_info.operator) {
            case 'LT':
                return Number(field.dep_info.expected) > Number(dependentData.value)
            case 'LE':
                return Number(field.dep_info.expected) >= Number(dependentData.value)
            case 'GT':
                return Number(field.dep_info.expected) < Number(dependentData.value)
            case 'GE':
                return Number(field.dep_info.expected) <= Number(dependentData.value)
            case 'EQ':
                return Number(field.dep_info.expected) === Number(dependentData.value)
            case 'R':
                return (
                    Number(field.dep_info.min) <= Number(dependentData.value) &&
                    Number(field.dep_info.max) >= Number(dependentData.value)
                );
            default:
                return false;
        }
    } else return true;
}