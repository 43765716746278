import React, {SetStateAction, useEffect, useState, useTransition} from 'react';
import {IFields} from "../../../Interfaces/IGeneral";
import {IDataCreate} from "../../../../../models/data/data.module";
import {Slider, TextField, Typography} from "@mui/material";

interface IRangeFormFieldProps {
    field: IFields,
    fieldIndex: number,
    data?: Array<IDataCreate>,
    setData?: React.Dispatch<SetStateAction<Array<IDataCreate[]> | null>>;
    pageNumber: number
}

const ERangeFormField = (props: IRangeFormFieldProps) => {

    const [range, setRange] = useState<Array<number>>([])

    useEffect(() => {
        if (props.data && props.data[props.fieldIndex] && props.data[props.fieldIndex].value !== undefined) {
            let value = JSON.parse(props.data[props.fieldIndex].value)
            if(value.length === 0) {
                const min = Number(JSON.parse(props.field.valuesToBeSelected).min)
                const steps = Number(JSON.parse(props.field.valuesToBeSelected).steps)
                setRange([min, min + steps])
            } else {
                setRange(JSON.parse(props.data[props.fieldIndex].value))
            }
        } else {
            const min = Number(JSON.parse(props.field.valuesToBeSelected).min)
            const steps = Number(JSON.parse(props.field.valuesToBeSelected).steps)
            setRange([min, min + steps])
        }
    }, [])

    const stateChangeHandler = (e: any, newValue: number | number[], activeThumb: number) => {

        if (props.data && props.setData) {
            let changedValues = props.data.slice()

            if (props.field && newValue && Array.isArray(newValue) && activeThumb !== undefined) {
                const field: IFields = {...props.field};
                const minDistance = Number(JSON.parse(field.valuesToBeSelected).steps);
                const upperbound = Number(JSON.parse(field.valuesToBeSelected).max);
                let result: number[] = []
                if (newValue[1] - newValue[0] < minDistance) {
                    if (activeThumb === 0) {
                        const clamped = Math.min(newValue[0], upperbound - minDistance);
                        result = [clamped, clamped + minDistance]
                        setRange(result)
                        changedValues[props.fieldIndex].value = JSON.stringify(result)
                    } else {
                        const clamped = Math.max(newValue[1], minDistance);
                        if (
                            clamped - minDistance <
                            Number(JSON.parse(field.valuesToBeSelected).min)
                        ) {
                            result = [
                                Number(
                                    JSON.parse(field.valuesToBeSelected).min,
                                ),
                                Number(JSON.parse(field.valuesToBeSelected).min) +
                                Number(JSON.parse(field.valuesToBeSelected).steps)
                            ]
                            setRange(result)
                            changedValues[props.fieldIndex].value = JSON.stringify(result);
                        } else {
                            result = [clamped - minDistance, clamped]
                            setRange(result)
                            changedValues[props.fieldIndex].value = JSON.stringify(result);
                        }
                    }
                } else {
                    setRange(newValue)
                    changedValues[props.fieldIndex].value = JSON.stringify(newValue);
                }
            }
            props.setData((prevState: any) => {
                let newState = [...prevState];
                newState[props.pageNumber] = changedValues;
                return newState;
            });
        } else {
            setRange(newValue as Array<number>)
        }
    }

    const InputLabelPropsStyles: React.CSSProperties = {
        color: getComputedStyle(document.documentElement).getPropertyValue('--formInputColor'),
        fontSize: '18px',
        padding: '12px 0 8px 0',
    };

    return (
        <div className='MPage__form__slider'>
            <Typography
                className='MPage__form__typography'
                style={{ fontSize: InputLabelPropsStyles.fontSize }}>
                {props.field.name + (props.field.required ? ' *' : '')}
            </Typography>
            <div className='MPage__form__slider__wrapper'>
                <Slider
                    defaultValue={[
                        Number(JSON.parse(props.field.valuesToBeSelected).min),
                        Number(JSON.parse(props.field.valuesToBeSelected).min) +
                        Number(JSON.parse(props.field.valuesToBeSelected).steps)
                    ]}
                    valueLabelDisplay='auto'
                    disableSwap={true}
                    min={Number(JSON.parse(props.field.valuesToBeSelected).min)}
                    max={Number(JSON.parse(props.field.valuesToBeSelected).max)}
                    step={Number(JSON.parse(props.field.valuesToBeSelected).steps)}
                    onChange={(e, newValue, activeThumb) => {
                        stateChangeHandler(e, newValue, activeThumb);
                    }}
                    value={range}
                />
            </div>
        </div>
    );
};

export default ERangeFormField;