import React, {ReactElement} from 'react';
import {ErrorBoundary} from "react-error-boundary";
import './EErrorBoundary.scss'

interface IFallbackElement {
    msg?: string
}

interface IEErrorBoundary {
    children: ReactElement;
    msg?: string;
}

const FallbackElement = (props: IFallbackElement) => {
    return (
        <div className="EErrorBoundary">
            <p className="EErrorBoundary__msg">{props.msg}</p>
        </div>
    )
}

const EErrorBoundary = (props: IEErrorBoundary) => {
    return (
        <ErrorBoundary fallback={<FallbackElement msg={props.msg}/>}>
            {props.children}
        </ErrorBoundary>
    );
};

export default EErrorBoundary;