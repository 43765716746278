import '../../styles/_nestedNav.scss';
import React, { useEffect, useState } from 'react';
import { findAllForms } from '../../models/forms/forms.repository';
import { AnswersSingleColumns, AnswersSingleColumnsEmpty } from '../../models/forms/forms.module';

import DataTable from '../../components/DataTable';
import { Grid } from '@mui/material';
import Modal, { ModalBody, ModalHeader } from '../../components/bootstrap/Modal';
import Button from '../../components/bootstrap/Button';
import { CancelRounded } from '@mui/icons-material';
import SingleFieldAnswers from './SingleFieldAnswers';
import { getFormAnswersByUserIdentifier } from '../../models/data/data.repository';
import { useTranslation } from 'react-i18next';

export default function SingleUserAnswer(props: {
	form_uuid?: string;
	user_identifier?: string;
	formName?: string;
	fields?: any[];
}) {
	const { t } = useTranslation(['translation']);

	const [answerDetails, setAnswerDetails] = useState<any[]>([]);
	const [selectedField, setSelectedField] = useState<{ id: number; name: string }>();
	const [singleFieldModalOpen, setSingleFieldModalOpen] = useState(false);

	const requestData = async (form: string, user_uuid: string) => {
		const fetchedData = await getFormAnswersByUserIdentifier(form, user_uuid);
		setAnswerDetails(fetchedData);
	};


	useEffect(() => {
		if (props.form_uuid && props.user_identifier) {
			requestData(props.form_uuid, props.user_identifier);
		}
	}, [props.form_uuid]);

	return (
		<div className="SingleUserAnswer">
			<Modal
				size={'xl'}
				fullScreen={true}
				isOpen={singleFieldModalOpen}
				setIsOpen={(status: boolean) => {
					setSingleFieldModalOpen(false);
					setSelectedField(undefined);
				}}
				isStaticBackdrop={true}
				isCentered={true}>
				<ModalHeader>
					<Button
						style={{
							alignItems: 'end',
							display: 'flex',
							justifyContent: 'flex-end',
							flexDirection: 'row',
							alignSelf: 'flex-end',
						}}
						onClick={() => {
							setSingleFieldModalOpen(false);
							setSelectedField(undefined);
						}}>
						<CancelRounded />
					</Button>
				</ModalHeader>
				<ModalBody>
					{selectedField && props.form_uuid && (
						<SingleFieldAnswers
							setSelectedAnswer={setSelectedField}
							selectedAnswer={selectedField}
							form_uuid={props.form_uuid}
							fields={props.fields ?? []}
							formName={props.formName}
							callback={() => {
								setSingleFieldModalOpen(false);
							}}
						/>
					)}
				</ModalBody>
			</Modal>

			<Grid container padding={3} spacing={2}>
				<DataTable<any>
					columns={AnswersSingleColumns}
					emptyValue={AnswersSingleColumnsEmpty}
					getAll={findAllForms}
					icon={'QuestionAnswer'}
					isFluid={false}
					name={`${t('answersPage.answers_answer')} > ${props.formName} - ${
						props.user_identifier
					}`}
					showInModal={(data) => {
						setSelectedField(data);
						setSingleFieldModalOpen(true);
					}}
					data={answerDetails.map((ansData) => {
						return {
							...ansData,
							link: { id: ansData.field_id, name: ansData.field_name },
						};
					})}
				/>
			</Grid>
		</div>
	);
}
