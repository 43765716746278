import React, { FC, useEffect, useState } from 'react';
import Card, { CardBody, CardHeader, CardTitle } from '../../../components/bootstrap/Card';
import useDarkMode from '../../../hooks/useDarkMode';
import Chart, { IChartOptions } from '../../../components/extras/Chart';

interface ISeriesItem {
	x: number | string; // Assuming x is numeric for simplicity
	y: number;
}

interface IAreaChartProps {
	series: ISeriesItem[];
	title: string;
	field?: {
		valuesToBeSelected: string;
	};
}

interface ISelectedRange {
	min: number;
	max: number;
}

const AreaChart: FC<IAreaChartProps> = ({ series, title, field }) => {
	const darkMode = useDarkMode();
	const [selectedRange, setSelectedRange] = useState<ISelectedRange>({ min: 0, max: 10 });

	useEffect(() => {
		if (field?.valuesToBeSelected) {
			const { min, max } = JSON.parse(field.valuesToBeSelected);
			setSelectedRange({ min: parseInt(min, 10), max: parseInt(max, 10) });
		}
	}, [field?.valuesToBeSelected]);

	const [github] = useState<IChartOptions>({
		series: [
			{
				name: 'Answers',
				data: series,
			},
		],
		options: {
			chart: {
				id: 'chartyear',
				type: 'area',
				height: 200,
				toolbar: {
					show: false,
					autoSelected: 'pan',
				},
			},
			stroke: {
				width: 0,
				curve: 'smooth',
			},
			dataLabels: {
				enabled: false,
			},
			fill: {
				opacity: 1,
				type: 'solid',
			},
			yaxis: {
				show: false,
				tickAmount: 3,
			},
			xaxis: {
				type: 'numeric',
				tickAmount: 5,
			},
			tooltip: {
				theme: darkMode ? 'dark' : 'light',
			},
		},
		bottomChartOptions: {
			chart: {
				type: 'area',
				height: 100,
				toolbar: {
					autoSelected: 'selection',
				},
				brush: {
					enabled: true,
					target: 'chartyear',
				},
				selection: {
					enabled: true,
					type: 'x',
					fill: {
						color: '#24292e',
						opacity: 0.4,
					},
					stroke: {
						width: 1,
						dashArray: 3,
						color: '#FF0000',
						opacity: 0.9,
					},
					xaxis: {
						min: selectedRange.min,
						max: selectedRange.max,
					},
				},
			},
			dataLabels: {
				enabled: false,
			},
			stroke: {
				width: 0,
				curve: 'smooth',
			},
			fill: {
				opacity: 1,
				type: 'solid',
			},
			legend: {
				position: 'top',
				horizontalAlign: 'left',
			},
			xaxis: {
				type: 'numeric',
			},
		},
	});

	const filteredSeries = series?.slice(selectedRange.min, selectedRange.max + 1) || [];

	return (
		<Card>
			<CardHeader>
				<CardTitle>{title}</CardTitle>
			</CardHeader>
			<CardBody>
				{github && series && series.length > 0 ? (
					<>
						<Chart
							options={github.options}
							series={[{ name: 'Selected Data', data: filteredSeries }]}
							type='area'
							height={200}
						/>
						<Chart
							options={github.bottomChartOptions}
							series={github.series}
							type='area'
							height={100}
						/>
					</>
				) : (
					<div>No data available</div>
				)}
			</CardBody>
		</Card>
	);
};

export default AreaChart;
