import React, { ReactElement, SetStateAction } from 'react';
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from './bootstrap/OffCanvas';
import { useTranslation } from 'react-i18next';

interface IDragWindowProps {
	setOpen: React.Dispatch<SetStateAction<{ open: boolean; type: string }>>;
	isOpen: { open: boolean; type: string };
	placement: 'start' | 'end';
	name: string;
	children: ReactElement;
	subTitle?: ReactElement;
}

const DragWindow = (props: IDragWindowProps) => {
	const { t } = useTranslation(['translation', 'menu']);

	return (
		<OffCanvas
			setOpen={props.setOpen}
			isOpen={props.isOpen.open}
			titleId='upcomingEdit'
			isBodyScroll
			placement={props.placement}
			isBackdrop={false}>
			<OffCanvasHeader
				setOpen={(e: boolean) => props.setOpen({ open: e, type: 'edit' })}
				style={{ paddingBottom: 0, fontSize: 16 }}>
				<h4 id='title'>{props.name}</h4>
			</OffCanvasHeader>
			<OffCanvasHeader id='subTitle' style={{ paddingTop: 3 }}>
				{props.subTitle}
			</OffCanvasHeader>
			<OffCanvasBody>{props.children}</OffCanvasBody>
		</OffCanvas>
	);
};

export default DragWindow;
