import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import React, {SetStateAction, useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {IExpectedValue, IFields} from '../frontend/src/Interfaces/IGeneral';
import Input from './bootstrap/forms/Input';
import {Checkbox, FormControlLabel} from '@mui/material';
import Button from './bootstrap/Button';
import Label from './bootstrap/forms/Label';
import Select from './bootstrap/forms/Select';
import { useTranslation } from 'react-i18next';
import Option from "./bootstrap/Option";
import AppointmentForm from "./AppointmentForm/AppointmentForm";
import { getRememberedFields } from '../models/pages/pages.repository';
import {AxiosError} from "axios";
import "../styles/components/_fieldForm.scss";

interface IFieldFormProps {
	setField: React.Dispatch<SetStateAction<IFields | null>>;
	field: IFields | null;
	saveField: () => void;
	removeLastField: () => void;
	allFields: IFields[] | undefined;
	companyId?: string;
}

export const FieldForm: (props: IFieldFormProps) => ReactJSXElement = (props: IFieldFormProps) => {
	const [valuesToBeSelected, setValuesToBeSelected] = useState<any>(null);
	const [allFields, setAllFields] = useState<IFields[] | null>(null)
	const [dependency, setDependency] = useState<IFields | null>(null)
	const [expectedValue, setExpectedValue] = useState<IExpectedValue>({expected: ''})
	const [rememberedFields, setRememberedFields] = useState<IFields[]>([])
	const { t } = useTranslation(['translation', 'menu']);
	const [cursor, setCursor] = useState<number | null>(null);
	const ref = useRef<HTMLInputElement>(null);

	useLayoutEffect(() => {
		ref.current?.setSelectionRange(cursor, cursor);
	}, [ref, cursor, props.field]);

	useEffect(()=> {
			if(props.allFields) {
				initFilteredFields()
				if(props.field) {
					initDeps()
				}
			}
	},[ props.field])

	useEffect(()=>{
		getRememberedFields()
			.then((res)=> {
				if(res !== null && !(res instanceof AxiosError)) {
					setRememberedFields(res)
				}
			})
	},[])

	const setFieldExpectedValue = (exp: IExpectedValue) => {
		setExpectedValue((prevState: any)=>({...prevState, ...exp }))
		props.setField(
			(prevState: any) => ({...prevState, dep_info: exp})
		)
	}

	const initFilteredFields = useCallback(()=> {
			if (props.allFields) {
				const filtered = props.allFields.filter((field, fieldIndex)=> props.field && props.field.id && props.field.id !== field.id)
				setAllFields(filtered)
			}
	},[props.allFields])

	const initDeps = () => {
		if(props.allFields && props.field && props.field.dep_id && props.field.dep_info) {
			let dependencyFind : IFields | undefined = props.allFields.find((field) => field.id === props.field?.dep_id)
			if(dependencyFind) setDependency(dependencyFind)
				if(typeof props.field.dep_info === 'string') {
					setExpectedValue(JSON.parse(props.field.dep_info))
				} else {
					setExpectedValue(props.field.dep_info)
				}
		}
	}

	const handleMultipleInputChange = (value: string) => {
		let ar: string[] = expectedValue.expected === "" ? [] : JSON.parse(expectedValue.expected)
		let elIndex = ar.indexOf(value)
		if (elIndex >= 0) {
			ar = ar.filter((el, index) => index !== elIndex)
		} else {
			ar.push(value)
		}
		setFieldExpectedValue({...expectedValue, expected: JSON.stringify(ar.sort())})
	}

	useEffect(() => {
		if (props.field) {
			if (props.field.valuesToBeSelected === '') {
				if (props.field.dataType === 'Slider') {
					setValuesToBeSelected({
						min: 0,
						max: 100,
						steps: 1,
					});
				} else if (props.field.dataType === 'Range') {
					setValuesToBeSelected({
						min: 0,
						max: 100,
						dist: 1,
						steps: 1,
					});
				} else if (
					props.field.dataType === 'SingleSelect' ||
					props.field.dataType === 'MultiSelect'
				) {
					setValuesToBeSelected([]);
				} else if (props.field.dataType === 'File') {
					setValuesToBeSelected({
						fileType: '',
					});
				}
			} else {
				setValuesToBeSelected(JSON.parse(props.field.valuesToBeSelected));
			}
		}
	}, [props.field]);

	const addNewValueToBeSelected = () => {
		let newVTBS = valuesToBeSelected.slice();
		newVTBS.push('');
		setValuesToBeSelected(newVTBS);
	};

	const handleObjectChange = (e: any) => {
		let value = '';
		if (e.target.name !== 'fileType' && e.target.name !== 'style') {
			value = JSON.stringify({
				...valuesToBeSelected,
				[e.target.name]: e.target.value === '' ? '0' : parseInt(e.target.value).toString(),
			});
		} else {
			value = JSON.stringify({ [e.target.name]: e.target.value });
		}
		props.setField((prevState: any) => ({ ...prevState, valuesToBeSelected: value }));
	};

	const handleValueChangeArray = (e: any, elIndex: number) => {
		setCursor(e.target.selectionStart);
		if (valuesToBeSelected && valuesToBeSelected instanceof Array) {
			let newVTBS = valuesToBeSelected.slice();
			newVTBS[elIndex] = e.target.value;
			setValuesToBeSelected(newVTBS);
			props.setField((prevState: any) => ({
				...prevState,
				valuesToBeSelected: JSON.stringify(newVTBS),
			}));
		}
	};

	return (
		<div className="FieldForm" style={{ display: 'flex', flexDirection: 'column' }}>
			{props.field && (
				<>
					<div>
						<Label>{t('rememberedFields')}</Label>
						<Select
							ariaLabel='Variant'
							placeholder={t('rememberedFields')}
							list={rememberedFields.map((field, rFieldIndex) => {
								return {
									value: rFieldIndex,
									name: field.name + `[${field.dataType}]`,
									label: field.name + `[${field.dataType}]`,
								};
							})}
							onChange={(e: any) => {
								let selected = rememberedFields[e.target.value];
								props.setField(selected);
							}}
							value={props.field.field_settings || ''}
						/>
					</div>
					<Label>{t('name')}</Label>
					<Input
						placeholder={t('name')}
						onChange={(e: any) => {
							props.setField((prevState: any) => ({
								...prevState,
								name: e.target.value,
							}));
						}}
						value={props.field.name || ''}
					/>
					{['TextField', 'Number', 'Email'].includes(props.field.dataType) && (
						<>
							<div>
								<Label>{t('datatype')}</Label>
								<Select
									ariaLabel={t('datatype')}
									placeholder={t('datatype')}
									list={['TextField', 'Number', 'Email'].map((field) => {
										return {
											value: field,
											text: t(field),
											label: t(field),
										};
									})}
									onChange={(e: any) => {
										props.setField((prevState: any) => ({
											...prevState,
											dataType: e.target.value,
										}));
									}}
									value={props.field.dataType || ''}
								/>
							</div>
							<div>
								<Label>{t('variant')}</Label>
								<Select
									ariaLabel='Variant'
									placeholder={t('variant')}
									list={[
										{ value: 'standard', text: 'Standard', label: 'Standard' },
										{ value: 'outlined', text: 'Outlined', label: 'Outlined' },
										{ value: 'filled', text: 'Filled', label: 'Filled' },
									]}
									onChange={(e: any) => {
										props.setField((prevState: any) => ({
											...prevState,
											field_settings: e.target.value,
										}));
									}}
									value={props.field.field_settings || ''}
								/>
							</div>
						</>
					)}
					{(props.field.dataType === 'SingleSelect' || props.field.dataType === 'SingleSelectBox' || props.field.dataType === 'MultiSelect' || props.field.dataType === 'MultiSelectBox') &&
						valuesToBeSelected instanceof Array &&
						valuesToBeSelected.map((el, elIndex) => {
							return (
								<div
									key={elIndex}
									style={{
										margin: '1rem 0',
									}}>
									<Label>{`${t('option')} ${elIndex}`}</Label>
									<Input
										placeholder={`${t('option')} ${elIndex}`}
										onChange={(e) => {
											handleValueChangeArray(e, elIndex);
										}}
										value={
											(valuesToBeSelected &&
												valuesToBeSelected[elIndex] + '') ||
											''
										}
										ref={ref}
									/>
								</div>
							);
						})}
					{(props.field.dataType === 'SingleSelect' || props.field.dataType === 'SingleSelectBox' ||
						props.field.dataType === 'MultiSelect' || props.field.dataType === 'MultiSelectBox' ) && (
						<div
							style={{
								margin: '1rem 0',
							}}>
							<Button
								color='dark'
								onClick={() => {
									addNewValueToBeSelected();
								}}>
								{t('addNewValue')}
							</Button>
						</div>
					)}
					{props.field.dataType === 'Range' && typeof valuesToBeSelected === 'object' && (
						<div
							style={{
								margin: '1rem 0',
							}}>
							<Label>{t('minimum')}</Label>
							<Input
								placeholder={t('minimum')}
								name={'min'}
								onChange={(e) => {
									handleObjectChange(e);
								}}
								value={valuesToBeSelected ? Number(valuesToBeSelected.min) : 0}
							/>
							<Label>{t('maximum')}</Label>
							<Input
								placeholder={t('maximum')}
								name={'max'}
								onChange={(e) => {
									handleObjectChange(e);
								}}
								value={valuesToBeSelected ? Number(valuesToBeSelected.max) : 0}
							/>
							<Label>{t('steps')}</Label>
							<Input
								placeholder={t('steps')}
								name={'steps'}
								onChange={(e) => {
									handleObjectChange(e);
								}}
								value={valuesToBeSelected ? Number(valuesToBeSelected.steps) : 0}
							/>
							<Label>Minimal Distance</Label>
							<Input
								placeholder={'Minimal Distance'}
								name={'dist'}
								onChange={(e) => {
									handleObjectChange(e);
								}}
								value={valuesToBeSelected ? Number(valuesToBeSelected.dist) : 0}
							/>
						</div>
					)}
					{props.field.dataType === 'Slider' && typeof valuesToBeSelected === 'object' && (
						<div
							style={{
								margin: '1rem 0',
							}}>
							<Label>{t('minimum')}</Label>
							<Input
								placeholder={t('minimum')}
								name={'min'}
								onChange={(e) => {
									handleObjectChange(e);
								}}
								value={valuesToBeSelected ? Number(valuesToBeSelected.min) : 0}
							/>
							<Label>{t('maximum')}</Label>
							<Input
								placeholder={t('maximum')}
								name={'max'}
								onChange={(e) => {
									handleObjectChange(e);
								}}
								value={valuesToBeSelected ? Number(valuesToBeSelected.max) : 0}
							/>
							<Label>{t('steps')}</Label>
							<Input
								placeholder={t('steps')}
								name={'steps'}
								onChange={(e) => {
									handleObjectChange(e);
								}}
								value={valuesToBeSelected ? Number(valuesToBeSelected.steps) : 0}
							/>
						</div>
					)}
					{props.field.dataType === 'File' && typeof valuesToBeSelected === 'object' && (
						<div>
							<Label>{t('fileType')}</Label>
							<Select
								ariaLabel='FileType'
								placeholder={t('fileType')}
								list={['.pdf', '.png, .jpg, .jpeg', '.csv'].map((value) => ({
									value: value,
									text: value,
									label: value,
								}))}
								onChange={(e: any) => {
									handleObjectChange(e);
								}}
								name={'fileType'}
								value={valuesToBeSelected ? valuesToBeSelected.fileType : ''}
							/>
						</div>
					)}
					{
						props.field.dataType === 'Seperator' && (
							<div>
								<Label>Seperator Style</Label>
								<Select
									ariaLabel='Seperator Style'
									placeholder='Seperator Style'
									list={['solid', 'dotted', 'dashed'].map((value) => ({
										value: value,
										text: value,
										label: value,
									}))}
									onChange={(e: any) => {
										handleObjectChange(e);
									}}
									name={'style'}
									value={valuesToBeSelected ? valuesToBeSelected.style : ''}
								/>
							</div>
						)
					}
					{props.field.dataType === 'Appointment' &&
						typeof valuesToBeSelected === 'object' && (
							<AppointmentForm field={props.field} setField={props.setField} />
						)}
					<FormControlLabel
						value='top'
						control={
							<Checkbox
								checked={(props.field && props.field.required) || false}
							/>
						}
						label={t('requiredField')}
						labelPlacement='end'
						onChange={(e, checked) => {
							props.setField((prevState: any) => ({
								...prevState,
								required: checked,
							}));
						}}
					/>
					<FormControlLabel
						value='top'
						control={
							<Checkbox
								checked={
									props.field.saved_by !== undefined &&
									props.field.saved_by !== ''
								}
								color={'secondary'}
							/>
						}
						label={t('rememberField')}
						labelPlacement='end'
						onChange={(e, checked) => {
							if (props.companyId)
								props.setField((prevState: any) => ({
									...prevState,
									saved_by: props.companyId,
								}));
						}}
					/>
					{props.field.id !== undefined && (
						<>
							<div> Add dependency </div>
							{allFields && (
								<>
									<Select
										ariaLabel='dependency'
										placeholder={dependency?.name || 'Dependency'}
										onChange={(e: any) => {
											setDependency(allFields[e.target.value]);
											props.setField((prevState: any) => ({
												...prevState,
												dep_id: allFields[e.target.value].id,
											}));
										}}
										value={dependency?.name || ''}>
										{allFields.map((field, fieldIndex) => {
											return (
												<Option key={fieldIndex} value={fieldIndex}>
													{field.name}
												</Option>
											);
										})}
									</Select>
									{dependency && (
										<>
											{dependency.dataType === 'TextField' && (
												<>
													<Label>Expected input:</Label>
													<Input
														value={expectedValue.expected}
														onChange={(e: any) => {
															setFieldExpectedValue({
																expected: e.target.value,
															});
														}}
													/>
												</>
											)}
											{(dependency.dataType === 'Number' ||
												dependency.dataType === 'Slider') && (
												<>
													<Label>Expected input:</Label>
													<div>
														<FormControlLabel
															className='MPage__form__formcontrol-label'
															control={
																<Checkbox
																	onChange={(e) => {
																		setFieldExpectedValue({
																			...expectedValue,
																			operator: 'LT',
																		});
																	}}
																	value={
																		expectedValue.operator ===
																		'LT'
																	}
																	checked={
																		expectedValue.operator ===
																		'LT'
																	}
																/>
															}
															label='Less than'
														/>
														<FormControlLabel
															className='MPage__form__formcontrol-label'
															control={
																<Checkbox
																	onChange={(e) => {
																		setFieldExpectedValue({
																			...expectedValue,
																			operator: 'LE',
																		});
																	}}
																	value={
																		expectedValue.operator ===
																		'LE'
																	}
																	checked={
																		expectedValue.operator ===
																		'LE'
																	}
																/>
															}
															label='Less than or Equal'
														/>
														<FormControlLabel
															className='MPage__form__formcontrol-label'
															control={
																<Checkbox
																	onChange={(e) => {
																		setFieldExpectedValue({
																			...expectedValue,
																			operator: 'GT',
																		});
																	}}
																	value={
																		expectedValue.operator ===
																		'GT'
																	}
																	checked={
																		expectedValue.operator ===
																		'GT'
																	}
																/>
															}
															label='Greater than'
														/>
														<FormControlLabel
															className='MPage__form__formcontrol-label'
															control={
																<Checkbox
																	onChange={(e) => {
																		setFieldExpectedValue({
																			...expectedValue,
																			operator: 'GE',
																		});
																	}}
																	value={
																		expectedValue.operator ===
																		'GE'
																	}
																	checked={
																		expectedValue.operator ===
																		'GE'
																	}
																/>
															}
															label='Greater than or Equal'
														/>
														<FormControlLabel
															className='MPage__form__formcontrol-label'
															control={
																<Checkbox
																	onChange={(e) => {
																		setFieldExpectedValue({
																			...expectedValue,
																			operator: 'EQ',
																		});
																	}}
																	value={
																		expectedValue.operator ===
																		'EQ'
																	}
																	checked={
																		expectedValue.operator ===
																		'EQ'
																	}
																/>
															}
															label='Equal'
														/>
														<FormControlLabel
															className='MPage__form__formcontrol-label'
															control={
																<Checkbox
																	onChange={(e) => {
																		setFieldExpectedValue({
																			...expectedValue,
																			operator: 'R',
																		});
																	}}
																	value={
																		expectedValue.operator ===
																		'R'
																	}
																	checked={
																		expectedValue.operator ===
																		'R'
																	}
																/>
															}
															label='In Range'
														/>
													</div>
													{expectedValue.operator &&
														['LT', 'GT', 'LE', 'GE', 'EQ'].includes(
															expectedValue.operator,
														) && (
															<Input
																type='number'
																onChange={(e: any) => {
																	setFieldExpectedValue({
																		...expectedValue,
																		expected: e.target.value,
																	});
																}}
																value={expectedValue.expected || ''}
															/>
														)}
													{expectedValue.operator &&
														expectedValue.operator === 'R' && (
															<>
																<Input
																	type='number'
																	onChange={(e: any) => {
																		setFieldExpectedValue({
																			...expectedValue,
																			min: e.target.value,
																		});
																	}}
																	value={expectedValue.min || ''}
																/>
																<Input
																	type='number'
																	onChange={(e: any) => {
																		setFieldExpectedValue({
																			...expectedValue,
																			max: e.target.value,
																		});
																	}}
																	value={expectedValue.max || ''}
																/>
															</>
														)}
												</>
											)}
											{dependency.dataType === 'Range' && (
												<>
													<Label>Expected minimum:</Label>
													<Input
														type='number'
														onChange={(e: any) => {
															setFieldExpectedValue({
																...expectedValue,
																min: e.target.value,
															});
														}}
														value={expectedValue.min || ''}
													/>
													<Label>Expeted maximum:</Label>
													<Input
														type='number'
														onChange={(e: any) => {
															setFieldExpectedValue({
																...expectedValue,
																max: e.target.value,
															});
														}}
														value={expectedValue.max || ''}
													/>
												</>
											)}
											{(dependency.dataType === 'SingleSelect' || dependency.dataType === 'SingleSelectBox') && (
												<>
													<Label>{t('expectedValue')}</Label>
													<Select
														ariaLabel='expected'
														placeholder={t('expectedValue')}
														onChange={(e: any) => {
															setFieldExpectedValue({
																...expectedValue,
																expected: e.target.value,
															});
														}}
														value={expectedValue.expected}>
														{JSON.parse(
															dependency.valuesToBeSelected,
														).map(
															(value: string, valueIndex: number) => {
																return (
																	<Option
																		key={valueIndex}
																		value={value}>
																		{value}
																	</Option>
																);
															},
														)}
													</Select>
												</>
											)}
											{(dependency.dataType === 'MultiSelect' || dependency.dataType === 'MultiSelectBox') && (
												<div>
													<Label>{t('expectedValue')}</Label>
													<Select
														ariaLabel={'expected multi'}
														multiple
														onChange={(e: any) => {
															handleMultipleInputChange(
																e.target.value,
															);
														}}
														value={
															expectedValue.expected === ''
																? []
																: JSON.parse(expectedValue.expected)
														}>
														{JSON.parse(
															dependency.valuesToBeSelected,
														).map(
															(value: string, valueIndex: number) => {
																return (
																	<Option
																		key={valueIndex}
																		value={value}>
																		{value}
																	</Option>
																);
															},
														)}
													</Select>
												</div>
											)}
											{(dependency.dataType === 'Checkbox' ||
												dependency.dataType === 'Switch') && (
												<>
													<Label>{t('expectedValue')}</Label>
													<Select
														ariaLabel='expected'
														placeholder={
															expectedValue.expected ||
															'ExpectedValue'
														}
														onChange={(e: any) => {
															setFieldExpectedValue({
																...expectedValue,
																expected: e.target.value,
															});
														}}
														value={expectedValue.expected || ''}>
														<Option value={'true'}>{t('checked')}</Option>
														<Option value={'false'}>{t('unchecked')}</Option>
													</Select>
												</>
											)}
										</>
									)}
								</>
							)}
						</>
					)}
				</>
			)}
		</div>
	);
};
