import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { getUserDataWithUsername, IUserProps } from '../common/data/userDummyData';


export interface IAuthContextProps {
	token: string;
	setToken?(token: string): unknown;
	userData: Partial<IUserProps>;
}
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
	children: ReactNode;
}
export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
	const [token, setToken] = useState<string>(localStorage.getItem('token') || '');
	const [userData, setUserData] = useState<Partial<IUserProps>>({});

	useEffect(() => {
		localStorage.setItem('token', token);
	}, [token]);

	useEffect(() => {
		let timeout: NodeJS.Timeout;
		if (token !== '') {
				setUserData(getUserDataWithUsername(token));
				const resetTimeout = () => {
					clearTimeout(timeout);
					timeout = setTimeout(() => {
						localStorage.removeItem('token');
						setToken('');
					}, 1800000);
				};

				resetTimeout();
				window.addEventListener('mousemove', resetTimeout);
				window.addEventListener('keypress', resetTimeout);

				return () => {
					window.removeEventListener('mousemove', resetTimeout);
					window.removeEventListener('keypress', resetTimeout);
					clearTimeout(timeout);
				};
		} else {
			setUserData({});
		}
	}, [token]);

	const value = useMemo(
		() => ({
			token: token,
			setToken: setToken,
			userData,
		}),
		[token, userData],
	);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;
