import React from 'react';
import './EMessage.scss';

interface IProps {
	message: String;
	type: 'success' | 'error';
}

const EMessage = (props: IProps) => {
	return (
		<div className={'EMessage' + (props.type === 'success' ? ' success' : ' error')}>
			{props.message}
		</div>
	);
};

export default EMessage;
