import React, {CSSProperties, ChangeEvent, SetStateAction, useEffect, useState, useCallback} from 'react';
import {
	Button as MButton,
	DialogActions,
	IconButton,
	styled,
} from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import MPage from '../../Modules/MPage/MPage';
import EButton from '../../Elements/EButton/EButton';
import './VForm.scss';
import {
	AddPhotoAlternate, CancelRounded,
	Close, Delete,
	Done,
	KeyboardArrowLeft,
	KeyboardArrowRight, RemoveCircleOutline, RemoveOutlined,
} from '@mui/icons-material';
import {IExpectedValue, IFields} from '../../../Interfaces/IGeneral';
import { IPages } from '../../../Interfaces/IPages';
import { GlobalStyleAttributes } from '../../../Utils/objects';
import { Form, StyleInfo } from '../../../../../models/forms/forms.module';
import { useParams } from 'react-router-dom';
import { IDataCreate } from '../../../../../models/data/data.module';
import { createData } from '../../../../../models/data/data.repository';
import {
	findOneForm,
	deleteFormLogoImage,
	sendEmail,
	uploadFormLogoImage, getFlow,
} from '../../../../../models/forms/forms.repository';
import toast, {Toaster} from 'react-hot-toast';
import {
	getComplementaryColor,
	getDefaultStyleAttributes,
	rgbToString,
	toRGBColor,
	unifyArrays,
} from '../../../../../helpers/Form_helper';
import { RGBColor } from 'react-color';
import EErrorBoundary from "../../EErrorBoundary/EErrorBoundary";
import {useTranslation} from "react-i18next";
import {capitalize} from "../../../../../helpers/helpers";
import {useEdgesState, useNodesState} from "reactflow";
import {AxiosError} from "axios";
import Modal, {ModalTitle, ModalBody, ModalHeader} from "../../../../../components/bootstrap/Modal";
import Button from "../../../../../components/bootstrap/Button";
import Input from "../../../../../components/bootstrap/forms/Input";
import ImageGallery from "../../../../../components/ImageGallery/ImageGallery";
import {API_BASE_PATH} from "../../../API/API-routes";

interface DropProps {
	onDrop?: (item: IFields, pageIndex: number) => void;
	accepts?: any;
	isCreator: boolean;
	addNewItem?: (item: IFields, pageIndex: number) => void;
	removeItem?: (arrayIndex: number, pageIndex: number) => void;
	currentPage?: number;
	setCurrentForm?: React.Dispatch<SetStateAction<Form | null>>;
	setCurrentPage?: React.Dispatch<SetStateAction<number>>;
	setFieldFormOpen?: React.Dispatch<SetStateAction<boolean>>;
	currentForm?: Form;
	setNewField?: React.Dispatch<SetStateAction<IFields | null>>;
	getAllForms?: () => void;
}

const VForm = (props: DropProps) => {
	const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
		height: 5,
		width: '100%',
		[`&.${linearProgressClasses.colorPrimary}`]: {
			borderTopLeftRadius: 20,
		},
	}));

	const { formId } = useParams();
	const { t } = useTranslation(['translation']);

	const [currentPage, setCurrentPage] = useState<number>(0);
	const [pagesValid, setPagesValid] = useState<Array<boolean>>([]);
	const [message, setMessage] = useState<{ message: string; type: 'success' | 'error' } | null>(
		null,
	);
	const [form, setForm] = useState<Form | null>(null);
	const [progress, setProgress] = useState<number>(0);
	const [pages, setPages] = useState<Array<IPages> | null>(null);
	const [defaultStyleAttributes, setDefaultStyleAttributes] = useState<Array<StyleInfo>>([]);
	const [backgroundImage, setBackgroundImage] = useState<string>('');
	const [data, setData] = useState<Array<IDataCreate[]> | null>(null);
	const [openDialog, setOpenDialog] = useState<boolean>(false);
	const [position, setPosition] = useState('top-center');
	const [displayImage, setDisplayImage] = useState('');
	const [uploadImage, setUploadImage] = useState<File | null>(null);
	const [imageList, setImageList] = useState<Array<string>>([]);
	const [history, setHistory] = useState<number[]>([0])
	const [nodes, setNodes, onNodesChange] = useNodesState([]);
	const [edges, setEdges, onEdgesChange] = useEdgesState([]);
	const [uploadedFilePaths, setUploadedFilePaths] = useState<Array<{
		[key: string]: string;
	}> | null>(null);

	useEffect(() => {
		getDefaultStyles();
		initStyles();
		if (formId) {
			findOneForm(formId)
				.then((res: Form) => {
					if (res) setForm(res);
					if (res.pages) {
						let validArray = res.pages.map((el: IPages) => {
							if (el.page_fields && !props.isCreator) {
								return false;
							} else {
								return true;
							}
						});
						setPagesValid(validArray);
					}
				})
				.catch((error) => console.log(error));

			getFlow(formId).then((res)=> {
				if(!(res instanceof AxiosError)) {
					if (res.nodes.length > 0 && res.edges.length > 0) {
						setNodes(res.nodes)
						setEdges(res.edges)
					//	initTotalPages()
					}
				}
			})
		}

	}, []);

	useEffect(() => {
		if (props.currentPage !== undefined) {
			setCurrentPage(props.currentPage);
			initProgress();
		}
	}, [props.currentPage]);

	useEffect(() => {
		initStyles();
		initBackground();
		if (props.currentForm && props.currentForm.pages) {
			setPages(props.currentForm.pages);
		}
		if (form && data === null) {
			initData();
			initFilePaths();
		}
		initProgress();

	}, [currentPage, props.currentForm, form]);

	const initProgress = () => {
		const selectedForm = props.currentForm ? props.currentForm : form;
		if (selectedForm && selectedForm.pages) {
			setProgress(((currentPage + 1) / selectedForm.pages.length) * 100);
		}
	};

	const initFilePaths = () => {
		if (form && form.pages) {
			let filePathArray: Array<{ [key: string]: string }> = [];
			form.pages.map((page, pageIndex) => {
				if (page.page_fields) {
					let filePathObj: { [key: string]: string } = {};
					page.page_fields.map((pageField, fieldIndex) => {
						if (pageField.dataType === 'File') {
							filePathObj[fieldIndex.toString()] = '';
						}
					});
					filePathArray.push(filePathObj);
				}
			});
			setUploadedFilePaths(filePathArray);
		}
	};

	const checkPageDependency = (depValue: IExpectedValue | string, dependentData: IDataCreate): boolean => {
		if (typeof depValue === 'string') {
			let pageValue = JSON.parse(depValue)
			switch (dependentData.field_type) {
				case "Checkbox":
				case "Switch":
				case "TextField":
				case "SingleSelect":
				case "SingleSelectBox":
				case "File":
					return dependentData.value === pageValue.expected;
				case "MultiSelect":
				case "MultiSelectBox":
					let depArray = JSON.parse(dependentData.value)
					let valArray = JSON.parse(pageValue.expected)
					let res = depArray.filter((arr1Item: any) => !valArray.includes(arr1Item))
					return res.length === 0;
				case "Appointment":
					let booked = false;
					if(pageValue.expected === 'booked') {
						booked = dependentData.value !== ""
					} else {
						booked = dependentData.value === ""
					}
					return booked;
				case "Number":
				case "Slider":
					switch (pageValue.operator) {
						case 'LT':
							return Number(pageValue.expected) > Number(dependentData.value)
						case 'LE':
							return Number(pageValue.expected) >= Number(dependentData.value)
						case 'GT':
							return Number(pageValue.expected) < Number(dependentData.value)
						case 'GE':
							return Number(pageValue.expected) <= Number(dependentData.value)
						case 'EQ':
							return Number(pageValue.expected) === Number(dependentData.value)
						case 'R':
							return (
								Number(pageValue.expected.min) <= Number(dependentData.value) &&
								Number(pageValue.expected.max) >= Number(dependentData.value)
							);
						default:
							return false;
					}
				default:
					return true
			}
		} else {
			return true;
		}
	}

	const nextPage = () => {

		let newPage = undefined
		let targetPages: IPages[] = []
		let targetIndices: number[] = []

		if (form && form.pages !== undefined) {

			let cP = currentPage;
			if (pagesValid[cP]) {

				for (let edge of edges) {

					// get pages where the current page is source
					if (form && form.pages && edge.source === form.pages[currentPage].uuid) {

						for (let i = 0; i < form.pages.length; i++) {
							let page = form.pages[i]

							//check if next page is potentially the next
							if (page.uuid === edge.target && page.page_fields) {
								targetPages.push(page)
								targetIndices.push(i)
							}
						}
					}
				}

				let i = 0
				for (let page of targetPages) {

					if (form.pages[currentPage].page_fields) {
						if (page.dep_page_id !== null && page.dep_value !== null && page.dep_value !== undefined) {
							let k = i
							let pageVal = typeof page.dep_value === "string" ? JSON.parse(page.dep_value) : page.dep_value
							let depIndex = form!.pages[currentPage]!.page_fields!.findIndex((field: IFields) => field.id === pageVal.field_id)
							let dependentData = data ? data[currentPage][depIndex] : undefined
							if (dependentData !== undefined && page.dep_value !== null && page.dep_value !== undefined) {

								if (checkPageDependency(page.dep_value, dependentData)) {
									newPage = targetIndices[k]
									setCurrentPage(targetIndices[k])
									setHistory((prevState) => {
										let hist = prevState.slice()
										hist.push(targetIndices[k])
										return hist
									})
									break;
								}
							}
						}
					}
					i++;
				}

				// if no dependency requirement is fullfilled, set the defaultpage as currentpage
				if (newPage === undefined) {

					let defaultIndex = targetPages.findIndex((page) => page.dep_page_id === null)
					let nextPageNumber = 0
					if (defaultIndex >= 0) {
						nextPageNumber = targetIndices[defaultIndex]
					} else {
						nextPageNumber = currentPage + 1
					}

					nextPageNumber = nextPageNumber > form.pages.length ? nextPageNumber : nextPageNumber--

					setCurrentPage(nextPageNumber)
					setHistory((prevState) => {
						let hist = prevState.slice()
						hist.push(nextPageNumber)
						return hist
					})
					setProgress(((nextPageNumber + 1) / form.pages.length) * 100);
				} else {
					setProgress(((newPage + 1) / form.pages.length) * 100);
				}

			}
		}
	}

	const prevPage = () => {

		if (history.length > 1) {
			setHistory((prevState) => {
				let hist = prevState.slice()
				hist.pop()
				setCurrentPage(hist[hist.length - 1])
				if (form && form.pages) {
					setProgress(((hist[hist.length - 1] + 1) / form!.pages!.length) * 100);
				}
				return hist
			})
		} else {
			setCurrentPage(0)
			setProgress(((1) / form!.pages!.length) * 100);
		}

	};

	const initBackground = () => {
		const selectedForm: Form | null = props.currentForm ? props.currentForm : form;
		if (selectedForm && selectedForm.uuid && selectedForm.company_id) {
			if (
				selectedForm &&
				selectedForm.pages &&
				selectedForm.pages.length > 0 &&
				selectedForm.pages[currentPage] &&
				selectedForm.pages[currentPage].bg_image
			) {
				setBackgroundImage(selectedForm!.pages![currentPage]!.bg_image!);
			} else if (selectedForm.bg_image) {
				setBackgroundImage(selectedForm.bg_image);
			} else {
				setBackgroundImage('');
			}
		}
	};

	const getCurrentStyles = (selectedForm: Form | undefined): StyleInfo[] => {
		if (selectedForm) {
			if (
				selectedForm.pages &&
				selectedForm.pages[currentPage]?.page_styles &&
				selectedForm.pages[currentPage]?.page_styles!.length > 0 &&
				selectedForm.styles &&
				selectedForm.styles.length > 0
			) {
				return unifyArrays(
					selectedForm.pages[currentPage].page_styles!,
					selectedForm.styles,
				);
			} else if (
				selectedForm.pages &&
				selectedForm.pages[currentPage] &&
				selectedForm.pages[currentPage]?.page_styles &&
				selectedForm.pages[currentPage]?.page_styles!.length > 0
			) {
				return selectedForm.pages[currentPage].page_styles!;
			} else if (selectedForm.styles && selectedForm.styles.length > 0) {
				return selectedForm.styles;
			} else {
				return [...defaultStyleAttributes];
			}
		} else {
			return [];
		}
	};

	const initStyles = useCallback(() => {
		let formLogo = '';
		let formLogoPosition = 'center';
		let selectedForm = form ? form : props.currentForm;
		let attributes = getCurrentStyles(selectedForm);

		if (selectedForm?.styles) {
			selectedForm.styles.forEach((style) => {
				if (style.name === 'formLogo') {
					formLogo = style.value;
				} else if (style.name === 'formLogoPosition') {
					formLogoPosition = style.value;
				} else {
					document.documentElement.style.setProperty(`--${style.name}`, style.value);
				}
			});

			// If there is a formLogo, set the displayImage and position states
			if (formLogo) {
				//const logoUrl = `${API_BASE_PATH}/uploads/${selectedForm.company_id}/forms/${selectedForm.uuid}/images/${formLogo}`;
				setDisplayImage(API_BASE_PATH + formLogo);
				setPosition(formLogoPosition);
			}
		}

		GlobalStyleAttributes.forEach((attributeToRemove) => {
			document.documentElement.style.removeProperty(`--${attributeToRemove}`);
		});

		attributes.forEach((style, index) => {
			document.documentElement.style.removeProperty(`--${style.name}`);
			document.documentElement.style.setProperty(`--${style.name}`, style.value);

			if (style.name === 'formInputColor') {
				document.documentElement.style.removeProperty(`--formInputColorComp`);
				let rgbC: RGBColor = toRGBColor(style.value);
				rgbC = getComplementaryColor(rgbC);
				let rgbString = rgbToString(rgbC);
				document.documentElement.style.setProperty(`--formInputColorComp`, rgbString);
			}
		});
	},[form, props.currentForm]);

	const getDefaultStyles = () => {
		setDefaultStyleAttributes(getDefaultStyleAttributes());
	};

	const initData = () => {
		if (form && form.pages && data === null) {
			let dataArrays: Array<Array<IDataCreate>> = [];
			form.pages.forEach((page, pageIndex) => {
				let values: Array<IDataCreate> = [];
				if (page.page_fields) {
					page.page_fields.forEach((pageField, pageFieldIndex) => {
						let value;
						switch (pageField.dataType) {
							case 'Number':
							case 'Slider':
								value = '';
								break;
							case 'Range':
								value = "[]";
								break;
							case 'Checkbox':
							case 'Switch':
								value = 'false';
								break;
							case 'MultiSelect':
							case 'MultiSelectBox':
								value = "[]";
								break;
							default:
								value = '';
						}
						let dataVal: IDataCreate = {
							field_type: pageField.dataType,
							field_id: pageField.id!,
							value: value,
							field_name: pageField.name
						};
						values.push(dataVal);
					});
				}

				dataArrays.push(values);
				setData(dataArrays);
			});
		}
	};

	const postData = () => {
		if (data && formId) {
			let dataToPost: Array<IDataCreate> = [];

			data.forEach((dataArray, dAIndex) => {
				dataArray.forEach((dataItem, dataIndex) => {
					dataToPost.push(dataItem);
				});
			});

			toast
				.promise(
					createData(formId, dataToPost),
					{
						success: 'Form Submitted!',
						error: 'Please fill all the fields or refresh the Page',
						loading: 'Submitting..',
					},
					{
						position: 'top-right',
						success: {
							duration: 5000,
							icon: '🔥',
						},
					},
				)
		}
	};

	const handleImageUpload = (e: ChangeEvent<HTMLInputElement>) => {

		const file = e.target.files?.[0];
		if (file) {
			setUploadImage(file);
			const reader = new FileReader();
			reader.onload = () => {
				const result = reader.result;
				if (typeof result === 'string') {
					setDisplayImage(result);
					setImageList((prev) => [...prev, result]);
				}
			};
			reader.readAsDataURL(file);
		}
	};

	const handleImageSelection = (imageUrl: string) => {
		setDisplayImage(imageUrl);
	};

	const handleOpenDialog = () => {
		setOpenDialog(true);
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
	};

	const handleClearImage = (event: any) => {
		event.preventDefault();
		event.stopPropagation();

		if (props.currentForm?.company_id && props.currentForm?.uuid) {
			toast
				.promise(
					deleteFormLogoImage(
						props.currentForm?.company_id,
						props.currentForm?.uuid,
						displayImage,
					).then((res) => {
						if (res && props.setCurrentForm && props.getAllForms) {
							props.setCurrentForm(res);
							props.getAllForms();
						}
					}),
					{
						success: t('successfullyDeleted'),
						loading: t('loading'),
						error: t('deleteError'),
					},
				)
				.finally(() => {
					setDisplayImage('');
					setUploadImage(null);
				});
		}
	};

	const handleSaveLogo = () => {
		if (props.currentForm?.company_id && props.currentForm?.uuid && uploadImage) {
			const formData = new FormData();
			formData.append('image', uploadImage);
			formData.append('formLogoPosition', position);

			toast.promise(
				uploadFormLogoImage(props.currentForm.company_id, props.currentForm.uuid, formData),
				{
					loading: 'Loading',
					success: (succ) => {
						if (props.setCurrentForm && props.currentForm) {
							props.setCurrentForm((prevForm) => {
								if (!prevForm) return null;
								const existingStyles = prevForm.styles?.slice() || [];
								const updatedStyles = existingStyles.map((style) => {
									if (style.name === 'formLogo') {
										return { ...style, value: succ };
									} else if (style.name === 'formLogoPosition') {
										return { ...style, value: position };
									}
									return style;
								});

								const hasFormLogo = existingStyles.some(
									(style) => style.name === 'formLogo',
								);
								const hasFormLogoPosition = existingStyles.some(
									(style) => style.name === 'formLogoPosition',
								);

								if (!hasFormLogo) {
									updatedStyles.push({ name: 'formLogo', value: succ });
								}
								if (!hasFormLogoPosition) {
									updatedStyles.push({
										name: 'formLogoPosition',
										value: position,
									});
								}
								setUploadImage(null)
								return {
									...prevForm,
									styles: updatedStyles,
								};
							});
						}
						handleCloseDialog();
						return 'Successfully set logo';
					},
					error: (err) => {
						if (err.response && err.response.data && err.response.data.message) {
							return err.response.data.message;
						}
						return 'Error: Action failed!';
					},
				},
			);
		}
	};

	const getLogoStyle = () => {
		const baseStyle: CSSProperties = {
			maxWidth: '200px',
			marginTop: 20,
			zIndex: 1
			//transform: 'translateX(-50%)',
		};

		switch (position) {
			case 'top-center':
				return { ...baseStyle, marginTop: 20 /*, transform: 'translateX(-50%)' */};
			default:
				return baseStyle;
		}
	};

	const getSubmitContent = () : string => {
		if (form !== null &&
			form.pages !== undefined &&
			form.pages[currentPage].page_fields !== undefined &&
			form!.pages![currentPage]!.page_fields!.length > 0) {
			const pageFields = form.pages[currentPage].page_fields!.slice()
			if (currentPage === 0) {
				if (pageFields && pageFields.length === 0) {
					return "start"
				} else {
					return "next"
				}
			} else {
				if (currentPage === form.pages.length - 2 ) {
					return "submit"
				} else {
					return "next"
				}
			}
		} else {
			return "next"
		}
	}

	return (
		<>
			{((form && form.active) || props.isCreator) && (
				<div className='VForm'>
					<Toaster position="top-right"
									 toastOptions={{
										 style: {
											 border: '2px solid var(--primaryColor)',
											 padding: '16px',
											 color: 'var(--primaryColor)',
											 background: 'var(--backgroundColor)',
										 },
									 }}
					/>
					<div
						className='VForm__inner'
						style={{
							position: 'relative',
						}}>
						{backgroundImage !== ''  && (
							<img
								className='VForm__bg-image'
								src={API_BASE_PATH + backgroundImage}
								onError={(e) => {
									setBackgroundImage('');
								}}
							/>
						)}
						<MButton
							className='VForm__Close-Button'
							onClick={() => {
								setCurrentPage(0);
								if (form && form.pages) setProgress((1 / form.pages.length) * 100);
							}}
							disabled={props.isCreator}>
							<Close />
						</MButton>

						<BorderLinearProgress variant='determinate' value={progress} />
						{!props.isCreator && displayImage && (
							<img src={displayImage} alt='Form Logo' style={getLogoStyle()} />
						)}
						<div
							className={
								'VForm__Form-Wrapper' + (props.isCreator ? ' isCreator' : '')
							}>
							{props.isCreator && props.currentForm && (
								<div
									onClick={handleOpenDialog}
									style={{
										border: '2px dashed #aaa',
										borderRadius: '8px',
										padding: displayImage ? '0px' : '16px',
										cursor: 'pointer',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										maxWidth: 150,
										position: 'relative',
									}}>
									{displayImage ? (
										<>
											<img
												src={displayImage}
												alt='Uploaded Logo'
												style={{
													maxWidth: '100%',
													maxHeight: '100%',
													objectFit: 'contain',
												}}
											/>
											<IconButton
												color='primary'
												style={{
													position: 'absolute',
													top: 0,
													right: 0,
												}}
												onClick={(event) => handleClearImage(event)}>
												<Delete />
											</IconButton>
										</>
									) : (
										<IconButton color='primary' component='span'>
											<AddPhotoAlternate />
										</IconButton>
									)}
								</div>
							)}
							{pages && (
								<EErrorBoundary msg={"Something went wrong while showing this Page..."}>
									<MPage
										pageInfo={pages[currentPage]}
										pageNumber={currentPage}
										currentPage={currentPage}
										totalPages={pages.length || 1}
										setPagesValid={setPagesValid}
										pagesValid={pagesValid}
										message={message}
										setMessage={setMessage}
										onDrop={props.onDrop}
										accepts={props.accepts}
										isCreator={props.isCreator}
										addNewItem={props.addNewItem}
										removeItem={props.removeItem}
										setNewField={props.setNewField}
										setFieldFormOpen={props.setFieldFormOpen}
										form_uuid={
											props.currentForm && props.currentForm.uuid
												? props.currentForm.uuid
												: ''
										}
										history={history}
									/>
								</EErrorBoundary>
							)}
							{pages === null && form && form.pages && data && (
								<EErrorBoundary msg={"Something went wrong while showing this Page..."}>
									<MPage
										pageInfo={form.pages[currentPage]}
										pageNumber={currentPage}
										currentPage={currentPage}
										totalPages={form.pages!.length}
										setPagesValid={setPagesValid}
										pagesValid={pagesValid}
										message={message}
										setMessage={setMessage}
										onDrop={props.onDrop}
										accepts={props.accepts}
										isCreator={props.isCreator}
										addNewItem={props.addNewItem}
										removeItem={props.removeItem}
										data={data[currentPage]}
										setData={setData}
										form_uuid={form.uuid || ''}
										uploadedFilePaths={uploadedFilePaths}
										setUploadedFilePaths={setUploadedFilePaths}
										allData={data}
										history={history}
									/>
								</EErrorBoundary>
							)}
						</div>
						{!props.isCreator && form && form.pages && currentPage !== form.pages.length - 1 && (
							<div className='VForm__Action-Buttons'>
								<div className='VForm__Action-Buttons__left'>
									<EButton
										theme={'primary'}
										onClick={() => {
											if (
												form &&
												form.pages &&
												currentPage === form.pages.length - 2 &&
												data
											) {
												if (form && form.email_template) {
													sendEmail(form, data);
													postData();
													nextPage()
												} else {
													postData();
													nextPage()
												}
											} else {
												nextPage();
											}
										}}
										disabled={!pagesValid[currentPage]}>
										<>
											{capitalize(t(getSubmitContent() ? getSubmitContent() : "" ))} <Done/>
										</>
									</EButton>
								</div>
								<div className='VForm__Action-Buttons__right'>
									<EButton
										theme={'primary'}
										onClick={() => {
											prevPage();
										}}
										disabled={currentPage === 0}>
										<KeyboardArrowLeft />
									</EButton>
									<EButton
										theme={'primary'}
										disabled={!pagesValid[currentPage] || currentPage === form.pages.length - 2}
										onClick={() => {
											if (pagesValid[currentPage]) {
												nextPage();
												setMessage(null);
											} else {
												setMessage({
													message:
														'Füllen Sie bitte das Formular vollständig aus',
													type: 'error',
												});
											}
										}}>
										<KeyboardArrowRight />
									</EButton>
								</div>
							</div>
						)}
						{props.isCreator && props.currentForm && (
							<Modal isOpen={openDialog} setIsOpen={handleCloseDialog} maxWidth='sm'>
								<ModalHeader style={{display: 'flex'}}>
									<ModalTitle id={"title"}>
										Logo
									</ModalTitle>
									<Button
										style={{
											alignItems: 'end',
											display: 'flex',
											justifyContent: 'flex-end',
											flexDirection: 'row',
											alignSelf: 'flex-end',
										}}
										onClick={handleCloseDialog}
									>
										<CancelRounded/>
									</Button>
								</ModalHeader>
								<ModalBody
									style={{
										display: 'flex',
										justifyContent: 'center',
										flexDirection: 'column',
										alignItems: 'center',
										minWidth: '300px',
									}}>
									{
										props.getAllForms && props.setCurrentForm && (
											<ImageGallery
												getAllForms={props.getAllForms}
												formObject={'Logo'}
												setCurrentForm={props.setCurrentForm}
												currentBG={props.currentForm.styles?.find(style => style.name === 'formLogo')?.value}
												currentForm={props.currentForm}
											/>
										)
									}

									<label htmlFor='file-upload'>
										{t('chooseFile')}
										<Input
											id='file-upload'
											type='file'
											name='Image'
											onChange={(e: any) => {
											 handleImageUpload(e)
											}}
										/>
									</label>
									{ /*
										imageList.length > 0 && (
										<div style={{ flex: 1, padding: 10 }}>
											<ImageList cols={4} rowHeight={100}>
												{imageList.map((imageUrl: string, index: number) => (
													<ImageListItem
														key={index}
														sx={{ border: '1px solid black' }}>
														<img
															srcSet={imageUrl}
															src={imageUrl}
															loading='lazy'
														/>
														<ImageListItemBar
															actionIcon={
																<IconButton
																	onClick={() =>
																		handleImageSelection(imageUrl)
																	}
																	sx={{
																		color: 'rgba(255, 255, 255, 0.54)',
																	}}>
																	<Check />
																</IconButton>
															}
														/>
													</ImageListItem>
												))}
											</ImageList>
										</div>
										)
									*/ }
								</ModalBody>
								<DialogActions>
									<Button
										color="dark"
										onClick={handleCloseDialog}
									>
										{t('cancel')}
									</Button>
									<Button
										color="dark"
										onClick={handleSaveLogo}
									>
										{t('save')}
									</Button>
								</DialogActions>
							</Modal>
						)}
					</div>
				</div>
			)}
			{form && !form.active && (
				<div className='VForm'>
					<div className='VForm__inner inactive'>
						<div className='VForm__inner__headline'>This Form is inactive!</div>
						<div className='VForm__inner__content'>
							Contact the owner of this Form for more information.
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default VForm;
