import { requests } from '../../API/API-service';
import { AUTH } from '../../API/API-routes';
import { LoginUser, OAuthLoginUser, RefreshToken, RegisterUser } from './Auth.module';

export const LOGIN = AUTH + '/login';
export const OAUTH_LOGIN = AUTH + '/oauth/login';
export const REFRESH = AUTH + '/refresh';
export const REGISTER = AUTH + '/register';
export const VERIFY = AUTH + '/verify';
export const GETINFO = AUTH + '/getUserInfo'

export const login = async (body: LoginUser) => {
	return requests
		.post(LOGIN, body)
		.then((data) => data)
		.catch((error) => error);
};

export const oauthLogin = async (body: OAuthLoginUser) => {
	return requests
		.post(OAUTH_LOGIN, body)
		.then((data) => data)
		.catch((error) => error);
};

export const refresh = async (body: RefreshToken) => {
	return requests
		.post(REFRESH, body)
		.then((data) => data)
		.catch((error) => error);
};

export const registerUser = async (body: RegisterUser ) => {
	return requests
		.post(REGISTER, body)
		.then(data => data)
		.catch(err => err)
}

export const verifyUser = async (body: LoginUser, token: string) => {
	return requests
		.post(VERIFY + '/' + token, body)
		.then(data => data)
		.catch(err => err)
}

export const getUserInfo = async () => {
	return requests
		.get(GETINFO)
		.then(data => data)
		.catch(err => err)
}
