import { Columns } from '../../components/Table';
import { FieldType } from '../../components/editWindow';

export interface CreateCompany {
	name: string;
	logo?: string;
}

export interface Company {
	id: number;
	uuid: string;
	name: string;
	logo: null | string;
	isTrial: number;
	created_at: string;
	updated_at: string;
}

export const CompanyColumns: Columns[] = [
	{ dataName: 'id', name: 'ID' },
	{ dataName: 'name', name: 'Name', fieldType: FieldType.TextField },
	{ dataName: 'is_trial', name: 'Trial' },
	{ dataName: 'created_at', name: 'Created' },
];

export const emptyCompany: Company = {
	id: 0,
	uuid: '',
	name: '',
	logo: null,
	isTrial: 0,
	created_at: '',
	updated_at: '',
};
