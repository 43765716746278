import React from 'react';

export const Imprint = () => {
    return (
        <div>
            <h1>Imprint</h1>
            <h2>Information according to § 5 TMG</h2>
            <p>KFM Digital Minds UG (haftungsbeschränkt)
                <br/>Mombacher
                Str. 68<br/>55122 Mainz</p><p>Commercial Register: HRB 5179<br/>Registration Court: Amtsgericht Mainz
            </p><p>
            <strong>Represented by:</strong><br/>Kemal Fathulla Mustapha</p><h2>Contact</h2><p>Phone:
            +4917673550100<br/>E-mail:
            kontakt@kfm-dm.de</p><h2>VAT identification number</h2><p>VAT identification number according to § 27 a
            Umsatzsteuergesetz (German Value Added Tax Act):<br/>DE358618231</p><h2>EU Dispute Resolution</h2><p>The
            European Commission provides a platform for online dispute resolution (OS): <a
                href="https://ec.europa.eu/consumers/odr/" target="_blank"
                rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br/>Our email address can be found in
            the imprint above.</p><h2>Consumer Dispute Resolution/Universal Mediation Body</h2><p>We are neither
            willing nor obligated to participate in dispute resolution proceedings before a consumer arbitration
            board.</p><p>Source: <a href="https://www.e-recht24.de">eRecht24</a></p>
        </div>
    );
};

export default Imprint;