import React, { useCallback, useEffect, useState } from 'react';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Chart, { IChartOptions } from '../../../components/extras/Chart';
import { ApexOptions } from 'apexcharts';

interface LineWithLabelProps {
	data: { name: string; count: number }[];
	title?: string;
}

interface RangeCount {
	x: number;
	y: number;
}

interface ApexAxisChartSeries {
	name: string;
	data: RangeCount[];
}

interface Bounds {
	lower: { [key: string]: number };
	upper: { [key: string]: number };
}

const LineWithLabel = ({ data, title }: LineWithLabelProps) => {
	const [series, setSeries] = useState<ApexAxisChartSeries[]>([]);
	const [options, setOptions] = useState<ApexOptions>({
		chart: {
			height: 310,
			type: 'line',
			toolbar: {
				show: false,
			},
		},
		tooltip: {
			theme: 'dark',
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			curve: 'smooth',
		},
		markers: {
			size: 5,
		},
		xaxis: {
			title: {
				text: 'Bound Value',
			},
		},
		yaxis: {
			title: {
				text: 'Count',
			},
		},
		legend: {
			position: 'top',
			horizontalAlign: 'right',
		},
	});

	useEffect(() => {
		const bounds = data.reduce((acc: Bounds, item) => {
			const [lower, upper] = JSON.parse(item.name);
			acc.lower[lower] = (acc.lower[lower] || 0) + item.count;
			acc.upper[upper] = (acc.upper[upper] || 0) + item.count;
			return acc;
		}, { lower: {}, upper: {} });

		const lowerSeriesData: RangeCount[] = Object.entries(bounds.lower).map(([x, y]) => ({ x: +Number(x), y }));
		const upperSeriesData: RangeCount[] = Object.entries(bounds.upper).map(([x, y]) => ({ x: +Number(x), y }));

		setSeries([
			{ name: 'Lower Bounds', data: lowerSeriesData },
			{ name: 'Upper Bounds', data: upperSeriesData },
		]);
	}, [data]);


	return (
		<Card stretch>
			{title && (
				<CardHeader>
					<CardLabel>
						<CardTitle>{title}</CardTitle>
					</CardLabel>
				</CardHeader>
			)}
			<CardBody>
				<Chart options={options} series={series} type='line' height={310} />
			</CardBody>
		</Card>
	);
};

export default LineWithLabel;
