import React, { ReactNode, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from './bootstrap/Button';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from './bootstrap/Card';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from './bootstrap/Modal';
import EditWindow from './editWindow';
import Icon from './icon/Icon';
import Table, { Columns } from './Table';
import { Form } from '../models/forms/forms.module';
import toast from 'react-hot-toast';
import {ITableFunctions} from "../frontend/src/Interfaces/IGeneral";

interface BaseDataTableProp<T> {
	isFluid: boolean;
	update?: (data: T) => Promise<T>;
	getAll: () => Promise<T[]>;
	delete?: (data: T) => void;
	post?: (data: T) => Promise<T>;
	emptyValue?: T;
	columns: Columns[];
	showActions?: boolean;
	name: string;
	customRightHeader?: any;
	editItem?: React.Dispatch<SetStateAction<any>>;
	openWindows?: React.Dispatch<SetStateAction<{ open: boolean; type: string }>>;
	changeOrder?: (data: T) => Promise<T>;
	data?: Array<T>;
	setData?: React.Dispatch<SetStateAction<Array<T> | null>>;
	chill?: ReactNode;
	tableFunctions?: ITableFunctions;
	toggleActive?: (data: T) => void;
	showInModal?: (data: any) => void;
	icon?: any;
	linkIcon?: any;
}

function DataTable<T>(props: BaseDataTableProp<T>) {
	const [itemToEdit, setItemToEdit] = useState<T | undefined>(undefined);
	const [itemToAdd, setItemToAdd] = useState<T | undefined>(undefined);
	const [itemToDelete, setItemToDelete] = useState<T | undefined>();
	const [data, setData] = useState<T[]>([]);
	const showActions = props.showActions !== undefined ? props.showActions : true;
	const { t } = useTranslation(['translation', 'menu']);

	useEffect(() => {
		if (props.data !== undefined) {
			setData(props.data);
		} else {
			props.getAll().then((res) => {
				setData(res);
			});
		}
	}, [props.data]);

	function editItem() {
		if (itemToEdit && props.update) {
			props.update(itemToEdit).then((res) => {
				props.getAll().then((_res) => {
					toast.success(
						<span className='d-flex align-items-center'>
							<Icon icon={'check'} size='lg' className='me-1' />
							<span>{t(`successfullyEdited`)}</span>
						</span>,
					);
					setData(_res);
					setItemToEdit(undefined);
				});
			});
		}
	}

	function addItem() {
		if (itemToAdd && props.post) {
			props.post(itemToAdd).then((res) => {
				props.getAll().then((_res) => {
					toast.success(
						<span className='d-flex align-items-center'>
							<Icon icon={'check'} size='lg' className='me-1' />
							<span>{t(`successfullyAdded`)}</span>
						</span>,
					);
					setData(_res);
					setItemToAdd(undefined);
				});
			});
		}
	}

	function deleteItem(item: T) {
		if (item && props.delete) {
			props.delete(item);
		}
	}

	const changeOrder = (item: T) => {
		if (props.changeOrder) {
			props.changeOrder(item).then((res: any) => setData(res));
		}
	};

	return (
		<>
			<Card style={{ width: '100%' }} stretch={props.isFluid}>
				<CardHeader borderSize={1}>
					<CardLabel icon={props.icon ? props.icon : 'Alarm'} iconColor='info'>
						<CardTitle>{t(props.name ? props.name : '')}</CardTitle>
					</CardLabel>
					<CardActions>
						{props.post !== undefined && (
							<Button
								color='info'
								icon='plus'
								isLight
								onClick={() => {
									setItemToAdd(props.emptyValue);
									if ((props.emptyValue as Form).form_link !== undefined) {
										if (props.editItem !== undefined)
											props.editItem(props.emptyValue);
										if (props.openWindows)
											props.openWindows({ open: true, type: 'add' });
									}
								}}>
								{t('createNew')}
							</Button>
						)}
						{props.customRightHeader !== undefined && props.customRightHeader}
					</CardActions>
				</CardHeader>
				<CardBody className='table-responsive' isScrollable={props.isFluid}>
					<Table
						showInModal={props.showInModal}
						hideEdit={props.update === undefined && props.openWindows === undefined}
						hideDelete={props.delete === undefined}
						hideOrder={props.changeOrder === undefined}
						changeOrder={changeOrder}
						showActions={showActions}
						columns={props.columns}
						toggleActive={props.toggleActive}
						edit={(id) => {
							if (props.openWindows === undefined) {
								setItemToEdit(id);
							} else {
								if (props.editItem !== undefined) props.editItem(id);
								props.openWindows({ open: true, type: 'edit' });
							}
						}}
						delete={(item: T) => setItemToDelete(item)}
						data={data}
						linkIcon={props.linkIcon}
						tableFunctions={props.tableFunctions}
					/>
				</CardBody>
			</Card>
			{itemToEdit !== undefined && (
				<EditWindow
					name={`${t(props.name ? props.name : '')} ${t('edit')}`}
					cancel={() => setItemToEdit(undefined)}
					save={() => editItem()}
					isOpen={itemToEdit !== undefined}
					setFormGroup={setItemToEdit}
					formGroup={itemToEdit}
					fields={props.columns}
					placement={'end'}
				/>
			)}
			{itemToAdd !== undefined && (props.emptyValue as Form).form_link === undefined && (
				<EditWindow
					name={`${t(props.name ? props.name : '')} ${t('add')}`}
					cancel={() => setItemToAdd(undefined)}
					save={() => addItem()}
					isOpen={itemToAdd !== undefined}
					setFormGroup={setItemToAdd}
					formGroup={itemToAdd}
					fields={props.columns}
					placement={'end'}
				/>
			)}

			<Modal
				titleId={`${t(props.name ? props.name : '')}` + ' ' + t('delete')}
				isOpen={itemToDelete !== undefined}
				setIsOpen={() => setItemToDelete(undefined)}
				isStaticBackdrop={true}
				isCentered={true}
				size={'sm'}
				fullScreen={'sm'}
				isAnimation={true}>
				<ModalHeader setIsOpen={() => setItemToDelete(undefined)}>
					<ModalTitle id={''}>
						{`${t(props.name ? props.name : '')}` + ' ' + t('delete')}
					</ModalTitle>
				</ModalHeader>
				<ModalBody>Dieser Vorgang kann nicht mehr rückgängig gemacht werden</ModalBody>
				<ModalFooter>
					<Button
						color='info'
						isOutline
						className='border-0'
						onClick={() => setItemToDelete(undefined)}>
						{t('cancel')}
					</Button>
					<Button
						color='info'
						icon='trash'
						onClick={() => {
							if (itemToDelete) {
								deleteItem(itemToDelete);
							}
							setItemToDelete(undefined);
						}}>
						Delete
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
}

export default React.memo(DataTable) as typeof DataTable;
