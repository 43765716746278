import React, { ChangeEvent, SetStateAction, useEffect, useRef, useState } from 'react';
import Button from './bootstrap/Button';
import { ArrowBackIos, Save } from '@mui/icons-material';
import Input from './bootstrap/forms/Input';
import { Form } from '../models/forms/forms.module';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ImageResize } from 'quill-image-resize-module-ts';
import Label from './bootstrap/forms/Label';
import { IEmailTemplate } from '../frontend/src/Interfaces/IEmailTemplate';
import { Chip, Fade, Paper, Popper } from '@mui/material';
import { uploadEmailTemplateImage } from '../models/forms/forms.repository';
import { API_BASE_PATH, FORMS } from '../API/API-routes';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import useDarkMode from '../hooks/useDarkMode';

interface ITemplateFormProps {
	setFormEditMode: React.Dispatch<
		SetStateAction<
			| ''
			| 'image'
			| 'style'
			| 'page-field'
			| 'page-style'
			| 'page-image'
			| 'page'
			| 'template'
		>
	>;
	currentForm: Form | null;
	setCurrentForm: React.Dispatch<SetStateAction<Form | null>>;
	formObject: 'Form' | 'Page';
	saveForm: (formData?: Form) => void;
}

let Align = Quill.import('attributors/style/align');
Quill.register(Align, true);
Quill.register('modules/imageResize', ImageResize);

const modules = {
	toolbar: [
		[{ header: [1, 2, false] }],
		['bold', 'italic', 'underline', 'strike', 'blockquote'],
		[{ list: 'ordered' }, { list: 'bullet' }],
		[{ align: [] }],
		[{ color: [] }, { background: [] }],
		['uploadImage'],
		['clean'],
	],
	imageResize: {
		displaySize: true,
		modules: ['Resize', 'DisplaySize', 'Toolbar'],
	},
};

export const TemplateForm = (props: ITemplateFormProps) => {
	const { t } = useTranslation(['translation', 'menu']);
	const [fields, setFields] = useState<any[]>([]);
	const { darkModeStatus } = useDarkMode();
	const [formState, setFormState] = useState<IEmailTemplate>({
		to: '',
		subject: '',
		backgroundColor: '#ffffff',
		textColor: '#000000',
		body: '',
	});
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [open, setOpen] = useState(false);
	const inputRefs = useRef<Map<string, React.RefObject<HTMLInputElement>>>(new Map());
	const quillRef = useRef<ReactQuill>(null);
	const fileInputRef = useRef<HTMLInputElement>(null);
	const [focusedInputId, setFocusedInputId] = useState<string | null>(null);

	useEffect(() => {
		const quill = quillRef.current?.getEditor();
		if (quill) {
			const toolbar = quill.getModule('toolbar');

			let customButtonExists = toolbar.container.querySelector('.ql-customUploadImage');
			if (!customButtonExists) {
				let customButton = document.createElement('button');
				customButton.className = 'ql-customUploadImage';
				customButton.innerHTML =
					'<svg viewBox="0 0 18 18"> <rect class="ql-stroke" height="10" width="12" x="3" y="4"></rect> <circle class="ql-fill" cx="6" cy="7" r="1"></circle> <polyline class="ql-even ql-fill" points="5 12 5 11 7 9 8 10 11 7 13 9 13 12 5 12"></polyline> </svg>';
				customButton.onclick = () => fileInputRef.current?.click();
				toolbar.container.appendChild(customButton);
			}
		}
	}, [quillRef.current]);

	const uploadImage = async (file: any): Promise<string | null> => {
		return toast.promise(
			new Promise<string | null>(async (resolve, reject) => {
				try {
					const formData = new FormData();
					formData.append('image', file);

					if (props.currentForm?.company_id && props.currentForm.uuid) {
						const imageUrl = await uploadEmailTemplateImage(
							props.currentForm.company_id,
							props.currentForm.uuid,
							formData,
						);

						if (!imageUrl) {
							throw new Error('Image upload failed');
						}

						resolve(imageUrl);
					} else {
						resolve(null);
					}
				} catch (error) {
					console.error('Error in uploadImage:', error);
					reject(error);
				}
			}),
			{
				loading: 'Uploading image...',
				success: 'Image uploaded successfully!',
				error: 'Image upload failed!',
			}
		);
	};

	const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			uploadImage(file)
				.then((imageUrl) => {
					if (imageUrl) {
						const fullPath = `${API_BASE_PATH}/uploads/${props.currentForm?.company_id}/forms/${props.currentForm?.uuid}/images/${imageUrl}`;
						insertImage(fullPath);
					}
				})
				.catch((error) => {
					console.error('Error uploading image: ', error);
				})
				.finally(() => {
					event.target.value = '';
				});
		} else {
			toast.error('No file selected');
		}
	};

	const insertImage = (imageUrl: string) => {
		const quill = quillRef.current?.getEditor();
		const range = quill?.getSelection();
		if (range) {
			let position = range.index;
			quill?.insertEmbed(position, 'image', imageUrl);
		}
	};

	const getInputRef = (inputId: string): React.RefObject<HTMLInputElement> => {
		if (!inputRefs.current.has(inputId)) {
			inputRefs.current.set(inputId, React.createRef());
		}
		return inputRefs.current.get(inputId)!;
	};

	const handleChipClick = (fieldId: string) => {
		if (focusedInputId) {
			if (focusedInputId === 'body' && quillRef.current) {
				const quill = quillRef.current.getEditor();
				const range = quill.getSelection(true);

				if (range) {
					const insertText = `{{${fieldId}}}`;
					quill.insertText(range.index, insertText, 'user');

					setTimeout(() => {
						const updatedContent = quill.root.innerHTML;
						setFormState((prev) => ({ ...prev, body: updatedContent }));
					}, 0);
				}
			} else {
				const focusedInputRef = inputRefs.current.get(focusedInputId);
				if (focusedInputRef?.current) {
					const inputElement = focusedInputRef.current;
					const start = inputElement.selectionStart ?? 0;
					const end = inputElement.selectionEnd ?? 0;
					const existingValue = (formState as any)[focusedInputId] || '';
					const newValue =
						existingValue.substring(0, start) +
						`{{${fieldId}}}` +
						existingValue.substring(end);
					setFormState((prev) => ({ ...prev, [focusedInputId]: newValue }));

					inputElement.focus();
					const cursorPosition = start + `{{${fieldId}}}`.length;
					inputElement.setSelectionRange(cursorPosition, cursorPosition);
				}
			}
		}
	};

	const handleFocus = (
		event: React.FocusEvent<HTMLInputElement> | null,
		isQuillEditor?: boolean,
	) => {
		let element: HTMLElement | null = event?.currentTarget || null;

		if (isQuillEditor && quillRef.current) {
			element = quillRef.current.getEditor().root;
			setAnchorEl(element);
			setOpen(true);
			setFocusedInputId('body');
		} else if (event) {
			setAnchorEl(event.currentTarget);
			setOpen(true);
			setFocusedInputId(event.currentTarget.id);
		}
	};

	const handleBlur = () => {
		setOpen(false);
	};

	const handleAddTemplate = () => {
		let htmlContent = formState.body;

		if (htmlContent) {
			// Use a DOM parser to parse the HTML content
			const parser = new DOMParser();
			const doc = parser.parseFromString(htmlContent, 'text/html');

			// Find all image tags
			const images = doc.querySelectorAll('img');
			images.forEach((img) => {
				// Extract image details
				const width = img.width > 0 ? img.width : 120;
				const alignment = img.style.textAlign || 'default';
				const name = img.src.split('/').pop();

				// Create a template variable
				const templateVar = `{{image:{width:${width}, alignment:'${alignment}', name:'${name}'}}}`;

				// Replace the image tag with the template variable
				img.outerHTML = templateVar;
			});

			// Get the processed HTML content
			htmlContent = doc.body.innerHTML;

			// Update formState with the new HTML content
			const updatedFormState = {
				...formState,
				body: htmlContent,
			};

			const formJson = JSON.stringify(updatedFormState);

			if (props.currentForm) {
				props.setCurrentForm({ ...props.currentForm, email_template: formJson });
				props.saveForm({ ...props.currentForm, email_template: formJson })
			} else {
				toast.error('Error in saving form')
			}
		}
	};

	const handleToChange = (e: ChangeEvent<HTMLInputElement>) => {
		setFormState((prev) => ({ ...prev, to: e.target.value }));
	};

	const handleSubjectChange = (e: ChangeEvent<HTMLInputElement>) => {
		setFormState((prev) => ({ ...prev, subject: e.target.value }));
	};

	useEffect(() => {
		if (props.currentForm?.email_template) {
			const parsedFormState = JSON.parse(props.currentForm.email_template) as IEmailTemplate;

			let htmlContent = parsedFormState.body;
			if (htmlContent) {
				const replacedHtmlContent = htmlContent.replace(
					/\{\{image:\{width:(\d+), alignment:'(.*?)', name:'(.*?)'\}\}\}/g,
					(match, width, alignment, name) => {
						const imageUrl = `${API_BASE_PATH}/uploads/${props.currentForm?.company_id}/forms/${props.currentForm?.uuid}/images/${name}`;
						return `<img src="${imageUrl}" style="text-align: ${alignment};" width="${width > 0 ? width : 120
							}">`;
					},
				);

				setFormState({
					...parsedFormState,
					body: replacedHtmlContent,
				});
			}
		}

		if (props.currentForm?.pages) {
			const newFields = props.currentForm.pages.flatMap((page) =>
				page.page_fields
					? page.page_fields.map((field) => ({ value: field.id, label: field.name }))
					: [],
			);

			const uniqueFields = newFields.filter(
				(field, index, self) =>
					index ===
					self.findIndex((o) => o.value === field.value && o.label === field.label),
			);

			setFields(uniqueFields);
		}
	}, [props.currentForm]);

	return (
		<>
			<Button
				title={t('back')}
				color='dark'
				onClick={() => {
					props.setFormEditMode('');
				}}>
				<ArrowBackIos />
			</Button>
			<div>
				<Label>{t('to')}:</Label>
				<Input
					id='to'
					placeholder='Field ID'
					onChange={handleToChange}
					value={formState?.to}
					onFocus={handleFocus}
					onBlur={handleBlur}
					ref={getInputRef('to')}
					autoComplete='off'
				/>
			</div>

			<div>
				<Label>{t('subject')}:</Label>
				<Input
					id='subject'
					placeholder='Field ID'
					onChange={handleSubjectChange}
					value={formState?.subject}
					onFocus={handleFocus}
					onBlur={handleBlur}
					ref={getInputRef('subject')}
					autoComplete='off'
				/>
			</div>
			<div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
				<div>
					<Label>{t('backgroundColor')}:</Label>
					<Input
						type='color'
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							setFormState((prev) => ({ ...prev, backgroundColor: e.target.value }))
						}
						value={formState.backgroundColor}
					/>
				</div>

				<div>
					<Label>{t('textColor')}:</Label>
					<Input
						type='color'
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							setFormState((prev) => ({ ...prev, textColor: e.target.value }))
						}
						value={formState.textColor}
					/>
				</div>
			</div>

			<div>
				<Label>{t('body')}:</Label>
				<ReactQuill
					id='body'
					ref={quillRef}
					theme='snow'
					modules={modules}
					value={formState.body || ''}
					onChange={(content) => setFormState((prev) => ({ ...prev, body: content }))}
					onFocus={() => handleFocus(null, true)}
					onBlur={handleBlur}
				/>
				<input
					type='file'
					ref={fileInputRef}
					onChange={handleFileSelect}
					style={{ display: 'none' }}
				/>
			</div>

			<Button
				color='success'
				onClick={() => {
					handleAddTemplate();
				}}>
				<Save />
			</Button>

			<div style={{ marginTop: '20px', border: '1px solid #ccc', padding: '10px' }}>
				<h3>{t('preview')}</h3>
				<div
					style={{
						backgroundColor: formState.backgroundColor,
						color: formState.textColor,
					}}>
					<p>
						{t('to')}: {formState.to}
					</p>
					<p>
						{t('subject')}: {formState.subject}
					</p>
					<div dangerouslySetInnerHTML={{ __html: formState.body || '' }}></div>
				</div>
			</div>

			<Popper
				open={open}
				anchorEl={anchorEl}
				transition
				sx={{ zIndex: 99999, paddingTop: 2 }}>
				{({ TransitionProps }) => (
					<Fade {...TransitionProps} timeout={350}>
						<Paper
							sx={{
								maxHeight: '300px',
								maxWidth: '300px',
								overflowX: 'scroll',
								display: 'flex',
								justifyContent: 'center',
								flexWrap: 'wrap',
								flexDirection: 'row',
								gap: 1,
								p: 2,
								borderRadius: 2,
								backgroundColor: darkModeStatus ? '#1F1B24' : '#121212'
							}}
						>
							{fields.map((field) => (
								<Chip
									key={field.value}
									label={field.label}
									sx={{
										mr: 1,
										mb: 1,
										backgroundColor: 'white',
										'&:hover, &:focus': {
											backgroundColor: 'white',
										},
										'&:active': {
											backgroundColor: 'white',
										},
										color: 'black',
									}}
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										handleChipClick(field.value);
									}}
								/>
							))}
						</Paper>
					</Fade>
				)}
			</Popper>
		</>
	);
};

export default TemplateForm;
