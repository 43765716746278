import React, { lazy } from 'react';
import { adminData, dashboardMenu, demoPages, layoutMenu } from '../menu';
import Login from '../pages/presentation/auth/Login';
import FormsTable from '../pages/adminData/Forms';
import CompaniesTable from '../pages/adminData/Companies';
import VForm from '../frontend/src/Components/Views/VForm/VForm';
import AnswersForm from '../pages/answers/Answers';
import AnswersInner from '../pages/answers/AnswersInner';
import SingleUserAnswer from '../pages/answers/SingleUserAnswer';
import Verify from '../pages/presentation/auth/Verify';
import Imprint from "../pages/Imprint/Imprint";
import Privacy from "../pages/Privacy/Privacy";

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/dashboard/DashboardPage')),
};
const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};
const PAGE_LAYOUTS = {
	HEADER_SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/HeaderAndSubheader')),
	HEADER: lazy(() => import('../pages/presentation/page-layouts/OnlyHeader')),
	SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/OnlySubheader')),
	CONTENT: lazy(() => import('../pages/presentation/page-layouts/OnlyContent')),
	BLANK: lazy(() => import('../pages/presentation/page-layouts/Blank')),
	ASIDE: lazy(() => import('../pages/presentation/aside-types/DefaultAsidePage')),
	MINIMIZE_ASIDE: lazy(() => import('../pages/presentation/aside-types/MinimizeAsidePage')),
};

const presentation = [
	/**
	 * Landing
	 */
	{
		path: dashboardMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
		exact: true,
	},
	{
		path: demoPages.page404.path,
		element: <AUTH.PAGE_404 />,
		exact: true,
	},
	{
		path: demoPages.login.path,
		element: <Login />,
		exact: true,
	},
	{
		path: demoPages.verify.path,
		element: <Verify />,
		exact: true,
	},

	{
		path: '/process/:formId',
		element: <VForm isCreator={false} accepts={'DRAGGABLE_ITEM'} onDrop={() => {}} />,
		exact: true,
	},
	{
		path: '/imprint',
		element: <Imprint/>,
		exact: true,
	},
	{
		path: '/privacy',
		element: <Privacy/>,
		exact: true,
	},

	/**
	 * Admin Info
	 */
	{
		path: adminData.forms.path,
		element: <FormsTable name={adminData.forms.text} />,
		exact: true,
	},
	{
		path: adminData.answers.path,
		element: <AnswersForm name={adminData.answers.text} />,
		exact: true,
	},
	{
		path: adminData.answers.path + '/:form_uuid/:user_identifier',
		element: <AnswersInner />,
		exact: true,
	},
	{
		path: adminData.answers.path + '/:form_uuid',
		element: <AnswersInner />,
		exact: true,
	},
	{
		path: adminData.companies.path,
		element: <CompaniesTable name={adminData.companies.text} />,
		exact: true,
	},

	/** ************************************************** */

	/**
	 * Page Layout Types
	 */
	{
		path: layoutMenu.blank.path,
		element: <PAGE_LAYOUTS.BLANK />,
		exact: true,
	},
	{
		path: layoutMenu.pageLayout.subMenu.headerAndSubheader.path,
		element: <PAGE_LAYOUTS.HEADER_SUBHEADER />,
		exact: true,
	},
	{
		path: layoutMenu.pageLayout.subMenu.onlyHeader.path,
		element: <PAGE_LAYOUTS.HEADER />,
		exact: true,
	},
	{
		path: layoutMenu.pageLayout.subMenu.onlySubheader.path,
		element: <PAGE_LAYOUTS.SUBHEADER />,
		exact: true,
	},
	{
		path: layoutMenu.pageLayout.subMenu.onlyContent.path,
		element: <PAGE_LAYOUTS.CONTENT />,
		exact: true,
	},
	{
		path: layoutMenu.asideTypes.subMenu.defaultAside.path,
		element: <PAGE_LAYOUTS.ASIDE />,
		exact: true,
	},
	{
		path: layoutMenu.asideTypes.subMenu.minimizeAside.path,
		element: <PAGE_LAYOUTS.MINIMIZE_ASIDE />,
		exact: true,
	},
];
const contents = [...presentation];

export default contents;
