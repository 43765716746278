import React, { FC, useState } from 'react';
import { ApexOptions } from 'apexcharts';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Chart from '../../../components/extras/Chart';

interface ICircleChartProps {
	labels: string[];
	values: number[];
	title: string;
}

const CircleChart: FC<ICircleChartProps> = ({ labels, values, title }) => {
	const colors = [
		'#6c5dd3',
		'#ffa2c0',
		'#46bcaa',
		'#f35421',
		'#ffcf52',
		'#4d69fa',
		'#1f77b4',
		'#b6aee9',
		'#ffd1e0',
		'#a3ded5',
		'#f9aa90',
		'#ffe7a9',
		'#a6b4fd',
		'#ff7f0e',
		'#2ca02c',
		'#d62728',
		'#9467bd',
	];

	const chartOptions: ApexOptions = {
		chart: {
			type: 'donut',
			height: 350,
		},
		stroke: {
			width: 0,
		},
		labels: labels,
		series: values,
		dataLabels: {
			enabled: false,
		},
		colors: colors,
		plotOptions: {
			pie: {
				expandOnClick: true,
				donut: {
					labels: {
						show: true,
						name: {
							show: true,
							fontSize: '24px',
							fontFamily: 'Poppins',
							fontWeight: 900,
							offsetY: 0,
							formatter(val) {
								return val.length > 15 ? `${val.slice(0, 15)}...` : val;
							},
						},
						value: {
							show: true,
							fontSize: '16px',
							fontFamily: 'Poppins',
							fontWeight: 900,
							offsetY: 16,
							formatter(val) {
								return val;
							},
						},
					},
				},
			},
		},
		legend: {
			show: true,
			position: 'bottom',
			height: 40,
		},
	};

	return (
		<Card stretch>
			{title && (
				<CardHeader>
					<CardLabel>
						<CardTitle>{title}</CardTitle>
					</CardLabel>
				</CardHeader>
			)}

			<CardBody>
				<Chart
					series={chartOptions.series}
					options={chartOptions}
					type={chartOptions.chart?.type}
					height={chartOptions.chart?.height}
				/>
			</CardBody>
		</Card>
	);
};

export default CircleChart;
