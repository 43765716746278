import { requests } from '../../API/API-service';
import {API_BASE_PATH, DATA} from '../../API/API-routes';
import { IDataCreate } from './data.module';

export const createData = async (form_uuid: string, body: Array<IDataCreate>) => {
	return requests
		.post(DATA + '/create/' + form_uuid, body)
		.then((data) => data)
		.catch((error) => error);
};

export const getOverallStats = async () => {
	return requests
		.get(DATA + '/stats')
		.then((data) => data)
		.catch((error) => error);
};

export const getStats = async (type: string) => {
	return requests
		.get(DATA + `/stats/period/${type}`)
		.then((data) => data)
		.catch((error) => error);
};

export const getBestTenStats = async () => {
	return requests
		.get(DATA + '/stats/high/5')
		.then((data) => data)
		.catch((error) => error);
};

export const getFormAndParticipantCount = async () => {
	return requests
		.get(DATA + '/stats/overall')
		.then((data) => data)
		.catch((error) => error);
};

export const getLastAccessedCount = async () => {
	return requests
		.get(DATA + '/stats/last-accessed')
		.then((data) => data)
		.catch((error) => error);
};

export const downloadCSV = (link: string) => {
	return requests
		.post(link)
		.then((data) => data)
		.catch((error) => error);
};

export const downloadFile = (body: {path: string}) => {
	return requests
		.post(DATA + '/download', body, {headers: {
			Accept: "image/png"
			},
		responseType: "arraybuffer"} )
		.then((data) => data)
		.catch((error) => error);
};


export const getDataForms = async () => {
	return requests
		.get(DATA + '/forms')
		.then((data) => data)
		.catch((error) => error);
};

export const getDataFormFields = async (form_uuid: string) => {
	return requests
		.get(DATA + `/form/${form_uuid}/fields`)
		.then((data) => data)
		.catch((error) => error);
};

export const getFormAnswers = async (form_uuid: string) => {
	return requests
		.get(DATA + `/forms/${form_uuid}/answers`)
		.then((data) => data)
		.catch((error) => error);
};

export const getFormAnswerByField = async (form_uuid: string, field_id: number) => {
	return requests
		.get(DATA + `/forms/${form_uuid}/answers/field/${field_id}`)
		.then((data) => data)
		.catch((error) => error);
};

export const getFormAnswersByUserIdentifier = async (
	form_uuid: string,
	user_identifier: string,
) => {
	return requests
		.get(DATA + `/forms/${form_uuid}/answers/user/${user_identifier}`)
		.then((data) => data)
		.catch((error) => error);
};