import { requests } from '../../API/API-service';
import { COMPANIES } from '../../API/API-routes';
import { CreateCompany } from './companies.module';

export const createCompany = async (body: CreateCompany) => {
	return requests
		.post(COMPANIES, body)
		.then((data) => data)
		.catch((error) => error);
};

export const findAllCompanies = async () => {
	return requests
		.get(COMPANIES)
		.then((data) => data)
		.catch((error) => error);
};

export const findOneCompany = async (id: string) => {
	return requests
		.get(COMPANIES + '/' + id)
		.then((data) => data)
		.catch((error) => error);
};

export const updateCompany = async (id: string, body: Partial<CreateCompany>) => {
	return requests
		.patch(COMPANIES + '/' + id, body)
		.then((data) => data)
		.catch((error) => error);
};

export const deleteCompany = async (id: string) => {
	return requests
		.delete(COMPANIES + '/' + id)
		.then((data) => data)
		.catch((error) => error);
};
