import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Collapse from '../../components/bootstrap/Collapse';
import Icon from '../../components/icon/Icon';
import AuthContext from '../../contexts/authContext';
import useDarkMode from '../../hooks/useDarkMode';
import useNavigationItemHandle from '../../hooks/useNavigationItemHandle';
import { demoPages } from '../../menu';
import { NavigationLine } from '../Navigation/Navigation';

const User = () => {
	const { userData, setToken } = useContext(AuthContext);

	const navigate = useNavigate();
	const handleItem = useNavigationItemHandle();
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();

	const [collapseStatus, setCollapseStatus] = useState<boolean>(true);

	const { t } = useTranslation(['translation', 'menu']);

	return (
		<>
			<Collapse isOpen={collapseStatus} className='user-menu'>
				<NavigationLine />
				<nav aria-label='aside-bottom-user-menu-2'>
					<div className='navigation'>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() => {
								if (setToken) {
									setToken('');
								}
								localStorage.clear();
								navigate(`../${demoPages.login.path}`);
							}}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='Logout' className='navigation-icon' />
									<span className='navigation-text'>{t('menu:Logout')}</span>
								</span>
							</span>
						</div>
					</div>
				</nav>
			</Collapse>
		</>
	);
};

export default User;
